import React from 'react';
import { FaEnvelope, FaSms, FaBell, FaWhatsapp, FaRobot } from 'react-icons/fa';

const channelIcons = {
    email: <FaEnvelope className="text-blue-500" />,
    sms: <FaSms className="text-green-500" />,
    push: <FaBell className="text-yellow-500" />,
    whatsapp: <FaWhatsapp className="text-green-700" />,
    chatbot: <FaRobot className="text-purple-500" />
};

const InboxTable = ({ messages, onViewMessage }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border border-gray-200 rounded-lg shadow-md">
                <thead>
                    <tr className="bg-gray-100 text-gray-700">
                        <th className="px-4 py-2 text-left">Canal</th>
                        <th className="px-4 py-2 text-left">Remitente</th>
                        <th className="px-4 py-2 text-left">Mensaje</th>
                        <th className="px-4 py-2 text-left">Fecha</th>
                        <th className="px-4 py-2 text-left">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {messages.length > 0 ? (
                        messages.map((message) => (
                            <tr key={message._id} className="border-t border-gray-200">
                                <td className="px-4 py-2 flex items-center space-x-2">
                                    {channelIcons[message.channel]}
                                    <span className="capitalize">{message.channel}</span>
                                </td>
                                <td className="px-4 py-2">{message.senderName || 'Desconocido'}</td>
                                <td className="px-4 py-2 truncate max-w-xs">{message.content}</td>
                                <td className="px-4 py-2">{new Date(message.createdAt).toLocaleString()}</td>
                                <td className="px-4 py-2">
                                    <button
                                        onClick={() => onViewMessage(message)}
                                        className="text-blue-600 hover:underline"
                                    >
                                        Ver Detalle
                                    </button>
                                </td>
                            </tr>
                        ))
                    ) : (
                        <tr>
                            <td colSpan="5" className="text-center text-gray-500 py-4">No hay mensajes</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default InboxTable;
