// src/pages/CampaignManagementPage.jsx
import React, { useState, useEffect } from 'react';
import { get, del } from '../../helpers/apiHelper';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import Swal from 'sweetalert2';
import CreateCampaignModal from '../../components/gameLoyalty/CreateCampaignModal';

function CampaignManagementPage() {
    const [campaigns, setCampaigns] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuthStore();
    const userId = user.userId;

    // Obtención de marcas del usuario
    useEffect(() => {
        if (userId) {
            fetchBrands();
        }
    }, [userId]);

    // Una vez cargadas las marcas, obtener campañas filtrando por sus IDs
    useEffect(() => {
        if (brands.length > 0) {
            fetchCampaigns();
        }
    }, [brands]);

    const fetchBrands = async () => {
        try {
            const response = await get(`marcas?usuario=${userId}`);
            setBrands(response);
        } catch (error) {
            console.error("Error al obtener marcas:", error);
            Swal.fire("Error", error.message, "error");
        }
    };

    const fetchCampaigns = async () => {
        try {
            // Construir la cadena de IDs de marcas separada por comas
            const brandIds = brands.map((brand) => brand._id).join(",");
            const data = await get(`game-loyalty?marcaId=${brandIds}`);
            setCampaigns(data);
        } catch (error) {
            console.error("Error al obtener campañas:", error);
            Swal.fire("Error", error.message, "error");
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await del(`game-loyalty/${id}`);
                    Swal.fire('Eliminado', 'La campaña ha sido eliminada correctamente', 'success');
                    fetchCampaigns();
                } catch (error) {
                    console.error('Error al eliminar campaña:', error);
                    Swal.fire('Error', error.message, 'error');
                }
            }
        });
    };

    return (
        <div className="min-h-screen bg-gray-100 overflow-x-hidden">
            <div className="max-w-full px-4 sm:px-6 py-6 mx-auto">
                <h1 className="text-2xl sm:text-3xl font-bold mb-4 text-gray-800 text-center">
                    Administrar campañas basadas en juego
                </h1>

                <div className="mb-6 flex justify-end">
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-600 text-sm sm:text-base"
                    >
                        Crear nueva campaña
                    </button>
                </div>

                {/* Modal para crear campaña */}
                <CreateCampaignModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onCampaignCreated={fetchCampaigns}
                />

                <hr className="my-6" />
                <h2 className="text-xl sm:text-2xl font-bold mb-4 text-gray-800">
                    Campañas existentes
                </h2>

                {campaigns.length === 0 ? (
                    <p className="text-gray-600">No se encontraron campañas para tus marcas.</p>
                ) : (
                    <div className="w-full overflow-x-auto">
                        <table className="table-auto w-full bg-white border rounded text-sm sm:text-base">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="py-2 px-4 border-b text-left">Nombre</th>
                                    <th className="py-2 px-4 border-b text-left">Alcance</th>
                                    <th className="py-2 px-4 border-b text-left">Particip.</th>
                                    <th className="py-2 px-4 border-b text-left">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaigns.map((campaign) => (
                                    <tr key={campaign._id} className="border-b hover:bg-gray-50">
                                        <td className="py-2 px-4">{campaign.nombre}</td>
                                        <td className="py-2 px-4">{campaign.scope}</td>
                                        <td className="py-2 px-4">
                                            {campaign.participations ? campaign.participations.length : 0} / {campaign.totalParticipations}
                                        </td>
                                        <td className="py-2 px-4">
                                            <button
                                                onClick={() => navigate(`/game-loyalty/detail/${campaign._id}`)}
                                                className="text-blue-600 hover:underline mr-3"
                                            >
                                                Detalles
                                            </button>
                                            <button
                                                onClick={() => handleDelete(campaign._id)}
                                                className="text-red-600 hover:underline"
                                            >
                                                Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
}

export default CampaignManagementPage;
