// src/pages/statistics/StatisticsPage.jsx
import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import { FaUsers, FaStore, FaChartLine, FaMoneyBillWave, FaRetweet } from 'react-icons/fa';
import DashboardKPISection from '../../components/statistic/DashboardKPISection';
import ComparativeDifferenceChart from '../../components/statistic/ComparativeDifferenceChart';

const StatisticsPage = () => {
  const { marcaId } = useParams();
  const [selectedMarcaId, setSelectedMarcaId] = useState(marcaId || '');
  const [marcas, setMarcas] = useState([]);
  const [stats, setStats] = useState(null);
  const [ticketKpis, setTicketKpis] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMarcas = async () => {
      try {
        setLoading(true);
        const response = await get('marcas');
        console.log('[StatisticsPage] Marcas response:', response);
        setMarcas(response);
        if (!marcaId && response.length > 0) {
          setSelectedMarcaId(response[0]._id);
          navigate(`/statistics/${response[0]._id}`);
        }
      } catch (error) {
        console.error('Error fetching marcas:', error);
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      } finally {
        setLoading(false);
      }
    };

    fetchMarcas();
  }, [marcaId, navigate]);

  useEffect(() => {
    if (selectedMarcaId) {
      const fetchStats = async () => {
        try {
          setLoading(true);
          const response = await get(`statistics/marca/${selectedMarcaId}/detailed`);
          console.log('[StatisticsPage] Stats response:', response);
          setStats(response);
          const ticketResponse = await get(`tickets/analytics/kpis?days=90`);
          console.log('[StatisticsPage] Ticket KPIs response:', ticketResponse);
          setTicketKpis(ticketResponse);
        } catch (error) {
          setError('No hay suficientes datos para mostrar estadísticas.');
          console.error('Error fetching statistics:', error);
          Swal.fire({
            title: 'Error',
            text: error.message || 'No hay suficientes datos para mostrar estadísticas.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        } finally {
          setLoading(false);
        }
      };
      fetchStats();
    }
  }, [selectedMarcaId]);

  const handleMarcaChange = (e) => {
    const newMarcaId = e.target.value;
    setSelectedMarcaId(newMarcaId);
    navigate(`/statistics/${newMarcaId}`);
  };

  if (loading) {
    return <div className="p-6 text-center">Cargando dashboard...</div>;
  }
  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }
  if (!stats || !marcas.length) {
    return <div className="p-6">No hay datos disponibles.</div>;
  }

  const generalKpis = [
    {
      title: 'Total de Clientes',
      value: stats.totalClientes,
      tooltip: 'Número total de clientes registrados para la marca.',
      icon: <FaUsers />,
    },
    {
      title: 'Total de Tiendas',
      value: stats.totalTiendas,
      tooltip: 'Número total de tiendas asociadas con la marca.',
      icon: <FaStore />,
    },
    {
      title: 'Total de Puntos',
      value: stats.totalPuntos,
      tooltip: 'Puntos acumulados por todos los clientes.',
      icon: <FaChartLine />,
    },
    {
      title: 'Canjes Totales',
      value: stats.totalCanjes,
      tooltip: 'Número total de puntos canjeados.',
      icon: <FaRetweet />,
    },
    {
      title: 'Valor Promedio de Transacción',
      value: stats.averageTransactionValue ? stats.averageTransactionValue.toFixed(2) : 'N/D',
      tooltip: 'Valor promedio de cada transacción.',
      icon: <FaMoneyBillWave />,
    },
  ];

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="container mx-auto">
        <header className="mb-10 text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-800">
            Dashboard de la Marca
          </h1>
          <p className="text-xl text-gray-600 mt-2">
            Revisa de forma ordenada los KPIs de tu negocio.
          </p>
        </header>

        <div className="mb-8 flex justify-center">
          <select
            value={selectedMarcaId}
            onChange={handleMarcaChange}
            className="w-full md:w-1/3 p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            {marcas.map((marca) => (
              <option key={marca._id} value={marca._id}>
                {marca.nombre}
              </option>
            ))}
          </select>
        </div>

        <DashboardKPISection kpis={generalKpis} />

        <section className="mt-16">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-4">
            KPIs de Tickets
          </h2>
          <p className="text-center text-lg text-gray-600 mb-8">
            Estos indicadores muestran el desempeño de las transacciones: LTV, Churn Rate, Retention Rate, Avg Basket Size y Redemption Rate.
          </p>
          <DashboardKPISection
            kpis={[
              {
                title: 'LTV',
                value: ticketKpis?.ltv ? ticketKpis.ltv.toFixed(2) : 'N/D',
                tooltip: 'Valor de por vida del cliente.',
                icon: <FaChartLine />,
              },
              {
                title: 'Churn Rate',
                value: ticketKpis?.churn ? `${ticketKpis.churn.toFixed(2)}%` : 'N/D',
                tooltip: 'Porcentaje de clientes que abandonan en 90 días.',
                icon: <FaRetweet />,
              },
              {
                title: 'Retention Rate',
                value: ticketKpis?.retention ? `${ticketKpis.retention.toFixed(2)}%` : 'N/D',
                tooltip: 'Porcentaje de clientes retenidos.',
                icon: <FaRetweet />,
              },
              {
                title: 'Avg Basket Size',
                value: ticketKpis?.averageBasketSize ? ticketKpis.averageBasketSize.toFixed(2) : 'N/D',
                tooltip: 'Número promedio de productos por transacción.',
                icon: <FaMoneyBillWave />,
              },
              {
                title: 'Redemption Rate',
                value: ticketKpis?.redemptionRate ? `${ticketKpis.redemptionRate.toFixed(2)}%` : 'N/D',
                tooltip: 'Porcentaje de puntos redimidos.',
                icon: <FaChartLine />,
              },
            ]}
          />

          <div className="mt-12">
            <ComparativeDifferenceChart
              clienteStats={ticketKpis || {}}
              promedioStats={stats.promedioGlobal || stats}
            />
            <p className="text-center text-gray-600 mt-4">
              El gráfico muestra la diferencia porcentual entre los KPIs de tickets y el promedio global.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default StatisticsPage;
