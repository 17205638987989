import { useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';

const BackToCommunication = () => {
    const navigate = useNavigate();

    return (
        <button
            onClick={() => navigate('/communication/dashboard')}
            className="mb-4 flex items-center text-blue-500 hover:text-blue-700 font-semibold"
        >
            <FaArrowLeft className="mr-2" /> Volver a Comunicación
        </button>
    );
};

export default BackToCommunication;
