// src/pages/UnderConstruction.jsx
import React from 'react';

const UnderConstruction = () => {
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-gray-100 p-4">
            <h1 className="text-4xl font-bold text-gray-800 mb-4">Módulo en proceso</h1>
            <p className="text-xl text-gray-600 text-center">
                Este módulo está en proceso de creación y estará disponible en los próximos días. ¡Gracias por tu paciencia!
            </p>
        </div>
    );
};

export default UnderConstruction;
