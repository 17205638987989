import React from 'react';
import { Helmet } from 'react-helmet';

const modules = [
    {
        title: "Gestión de Marca",
        description:
            "Permite a los usuarios gestionar marcas, asignar tiendas y clientes, y establecer condiciones para la acumulación de puntos. Funcionalidades clave: creación, actualización y gestión de premios.",
        seoKeywords: "gestión de marcas, sistema de puntos, premios por puntos, fidelización de clientes",
    },
    {
        title: "Premios",
        description:
            "Administra los premios disponibles para canjear puntos, definiendo requerimientos y controlando su disponibilidad.",
        seoKeywords: "premios, canje de puntos, fidelización de clientes",
    },
    {
        title: "Gestión de Tiendas",
        description:
            "Permite registrar y gestionar tiendas, administrar ubicación, contacto y redes sociales, vinculándolas a una marca.",
        seoKeywords: "gestión de tiendas, administración de ubicaciones, software para tiendas",
    },
    {
        title: "Gestión de Clientes",
        description:
            "Facilita la administración integral de la información de clientes, segmentación dinámica y seguimiento de interacciones.",
        seoKeywords: "gestión de clientes, CRM, fidelización de clientes",
    },
    {
        title: "Gestión de Tickets y Puntos",
        description:
            "Registra compras como tickets, acumula puntos y permite el canje de premios, ofreciendo análisis detallado de transacciones.",
        seoKeywords: "sistema de puntos, gestión de tickets, programa de recompensas",
    },
    {
        title: "Encuestas de Satisfacción",
        description:
            "Crea y distribuye encuestas para recoger feedback, analizar resultados en tiempo real y obtener insights valiosos.",
        seoKeywords: "encuestas de satisfacción, feedback del cliente, análisis de cliente",
    },
    {
        title: "Fidelización Basada en Juego (GameLoyalty)",
        description:
            "Incorpora elementos de gamificación para mejorar la interacción y fidelización mediante campañas lúdicas con premios.",
        seoKeywords: "gamificación de clientes, fidelización por juego, campañas de gamificación",
    },
    {
        title: "Gestión de Productos",
        description:
            "Administra el inventario de productos, control de stock y asociación de productos a tiendas para una gestión eficiente.",
        seoKeywords: "gestión de inventario, control de stock, administración de productos",
    },
    {
        title: "Regalos y Descuentos",
        description:
            "Gestiona recompensas y descuentos, genera códigos de recompensa y facilita promociones integradas con el sistema de tickets.",
        seoKeywords: "gestión de recompensas, sistema de descuentos, promociones personalizadas",
    },
    {
        title: "Gestión de Trabajadores",
        description:
            "Permite el registro, autenticación y administración de trabajadores, definiendo roles y accesos para proteger la plataforma.",
        seoKeywords: "gestión de trabajadores, autenticación segura, control de acceso",
    },
];

const FeaturesPage = () => {
    return (
        <div className="min-h-screen bg-gray-50 text-gray-800">
            <Helmet>
                <title>
                    Características de Software de Fidelización – Funcionalidades de Programa de Lealtad Digital
                </title>
                <meta
                    name="description"
                    content="Descubre las funcionalidades que diferencian a nuestro ecosistema de fidelización digital. Gestión de marca, premios, tiendas, clientes y más, diseñados para maximizar la lealtad y las ventas."
                />
                <meta
                    name="keywords"
                    content="características de software de fidelización, funcionalidades de programa de lealtad digital, gestión de marcas, premios, fidelización de clientes"
                />
            </Helmet>
            <header className="bg-blue-600 text-white py-8 text-center">
                <h1 className="text-4xl font-bold">Características y Funcionalidades</h1>
                <p className="mt-2 text-lg">Descubre lo que hace única a nuestra plataforma</p>
            </header>
            <main className="container mx-auto px-4 py-8">
                {modules.map((module, index) => (
                    <section key={index} className="mb-12">
                        <h2 className="text-2xl font-semibold mb-4">{module.title}</h2>
                        <p className="mb-2">{module.description}</p>
                        <p className="text-sm text-gray-600">
                            <strong>Keywords:</strong> {module.seoKeywords}
                        </p>
                    </section>
                ))}
            </main>
            <footer className="bg-gray-200 text-center py-4">
                <p>
                    ¿Interesado en transformar la fidelización de tus clientes?{" "}
                    <a href="/register" className="text-blue-600 font-bold">
                        Regístrate ahora
                    </a>{" "}
                    o{" "}
                    <a href="/demo" className="text-blue-600 font-bold">
                        solicita una demo
                    </a>.
                </p>
            </footer>
        </div>
    );
};

export default FeaturesPage;
