import React, { useState } from "react";
import { get, patch } from "../../helpers/apiHelper";
import Swal from "sweetalert2";

const RedeemRewardButton = ({ clienteId }) => {
    const [loading, setLoading] = useState(false);
    const [rewards, setRewards] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);

    const handleOpenModal = async () => {
        setLoading(true);

        try {
            const response = await get(`rewards/available/${clienteId}`);

            if (!response.length) {
                Swal.fire({
                    title: "Sin premios disponibles",
                    text: "Este cliente no tiene premios disponibles para canjear.",
                    icon: "warning",
                    confirmButtonText: "Entendido",
                });
                return;
            }

            setRewards(response);
            setShowModal(true);
        } catch (error) {
            Swal.fire({
                title: "Error",
                text: "No se pudo obtener la lista de premios disponibles.",
                icon: "error",
                confirmButtonText: "Cerrar",
            });
        } finally {
            setLoading(false);
        }
    };

    const handleRedeem = async () => {
        if (!selectedReward) {
            Swal.fire({
                title: "Selecciona un premio",
                text: "Debes elegir un premio antes de canjearlo.",
                icon: "warning",
                confirmButtonText: "Entendido",
            });
            return;
        }

        setLoading(true);

        try {
            const response = await patch(`rewards/redeem/${clienteId}/${selectedReward._id}`);

            Swal.fire({
                title: "¡Premio canjeado con éxito!",
                text: response.message || "El cliente ha canjeado su premio correctamente.",
                icon: "success",
                confirmButtonText: "Aceptar",
            });

            setShowModal(false);
        } catch (error) {
            Swal.fire({
                title: "Error al canjear premio",
                text: error.message || "Ocurrió un problema al intentar canjear el premio.",
                icon: "error",
                confirmButtonText: "Cerrar",
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <button
                onClick={handleOpenModal}
                className={`px-4 py-2 rounded transition ${loading ? 'bg-pink-400 cursor-not-allowed' : 'bg-pink-500 hover:bg-pink-600 text-white'}`}
                disabled={loading}
            >
                {loading ? "Cargando..." : "Canjear Premio"}
            </button>

            {/* MODAL DE SELECCIÓN DE PREMIOS */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                        <h2 className="text-2xl font-bold mb-4 text-center">Selecciona un Premio</h2>

                        {rewards.length > 0 ? (
                            <div className="space-y-2">
                                {rewards.map((reward) => (
                                    <div
                                        key={reward._id}
                                        className={`p-3 border rounded-lg cursor-pointer ${selectedReward?._id === reward._id ? "bg-blue-100 border-blue-500" : "border-gray-300"
                                            }`}
                                        onClick={() => setSelectedReward(reward)}
                                    >
                                        <p className="font-semibold">{reward.recompensa.nombre}</p>
                                        <p className="text-sm text-gray-600">{reward.recompensa.descripcion}</p>
                                        <p className="text-sm font-bold text-gray-700">Código: {reward.codigo}</p>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <p className="text-gray-600 text-center">No hay premios disponibles.</p>
                        )}

                        <div className="flex justify-end mt-4">
                            <button
                                onClick={() => setShowModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600 transition"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleRedeem}
                                className={`px-4 py-2 rounded-lg transition ${selectedReward ? "bg-blue-500 text-white hover:bg-blue-600" : "bg-gray-300 cursor-not-allowed"
                                    }`}
                                disabled={!selectedReward}
                            >
                                Canjear
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default RedeemRewardButton;
