// src/pages/ReferralCampaignManagementPage.jsx
import React, { useState, useEffect } from 'react';
import { get, del } from '../../helpers/apiHelper';
import { useNavigate } from 'react-router-dom';
import useAuthStore from '../../store/authStore';
import Swal from 'sweetalert2';
import CreateReferralCampaignModal from '../../components/referralCampaign/CreateReferralCampaignModal';

const ReferralCampaignManagementPage = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [brands, setBrands] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const navigate = useNavigate();
    const { user } = useAuthStore();
    const userId = user.userId;

    // Cargar marcas del usuario
    useEffect(() => {
        if (userId) {
            fetchBrands();
        }
    }, [userId]);

    // Una vez que las marcas están cargadas, cargar campañas
    useEffect(() => {
        if (brands.length > 0) {
            fetchCampaigns();
        }
    }, [brands]);

    const fetchBrands = async () => {
        try {
            const response = await get(`marcas?usuario=${userId}`);
            console.log("Marcas obtenidas:", response);
            setBrands(response);
        } catch (error) {
            console.error("Error al obtener marcas:", error);
            Swal.fire("Error", error.message, "error");
        }
    };

    const fetchCampaigns = async () => {
        try {
            // Si hay varias marcas, se unen sus IDs en una cadena separada por comas
            const brandIds = brands.map(brand => brand._id).join(",");
            console.log("Brand IDs:", brandIds);
            const data = await get(`referral-campaign?marcaId=${brandIds}`);
            console.log("Campañas obtenidas:", data);
            setCampaigns(data);
        } catch (error) {
            console.error("Error al obtener campañas:", error);
            Swal.fire("Error", error.message, "error");
        }
    };

    const handleDelete = async (id) => {
        Swal.fire({
            title: '¿Estás seguro?',
            text: 'Esta acción no se puede deshacer.',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#d33',
            confirmButtonText: 'Sí, eliminar',
            cancelButtonText: 'Cancelar',
        }).then(async (result) => {
            if (result.isConfirmed) {
                try {
                    await del(`referral-campaign/${id}`);
                    Swal.fire('Eliminado', 'La campaña ha sido eliminada correctamente', 'success');
                    fetchCampaigns();
                } catch (error) {
                    console.error("Error al eliminar campaña:", error);
                    Swal.fire("Error", error.message, "error");
                }
            }
        });
    };

    return (
        <div className="min-h-screen bg-gray-100 py-6 px-4">
            <div className="max-w-7xl mx-auto">
                <h1 className="text-3xl font-bold text-gray-800 mb-6 text-center">
                    Administrar Campañas de Referidos
                </h1>
                <div className="flex justify-end mb-4">
                    <button
                        onClick={() => setIsModalOpen(true)}
                        className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700"
                    >
                        Crear Nueva Campaña
                    </button>
                </div>
                <CreateReferralCampaignModal
                    isOpen={isModalOpen}
                    onClose={() => setIsModalOpen(false)}
                    onCampaignCreated={fetchCampaigns}
                    brands={brands}
                />
                {campaigns.length === 0 ? (
                    <p className="text-center text-gray-600">
                        No se encontraron campañas para tus marcas.
                    </p>
                ) : (
                    <div className="overflow-x-auto">
                        <table className="min-w-full bg-white border">
                            <thead className="bg-gray-200">
                                <tr>
                                    <th className="px-4 py-2 text-left">Nombre</th>
                                    <th className="px-4 py-2 text-left">Tipo</th>
                                    <th className="px-4 py-2 text-left">Inicio</th>
                                    <th className="px-4 py-2 text-left">Fin</th>
                                    <th className="px-4 py-2 text-left">Acciones</th>
                                </tr>
                            </thead>
                            <tbody>
                                {campaigns.map((campaign) => (
                                    <tr key={campaign._id} className="border-b hover:bg-gray-50">
                                        <td className="px-4 py-2">{campaign.nombre}</td>
                                        <td className="px-4 py-2">{campaign.tipo}</td>
                                        <td className="px-4 py-2">
                                            {campaign.fechaInicio ? new Date(campaign.fechaInicio).toLocaleDateString() : '-'}
                                        </td>
                                        <td className="px-4 py-2">
                                            {campaign.fechaFin ? new Date(campaign.fechaFin).toLocaleDateString() : '-'}
                                        </td>
                                        <td className="px-4 py-2">
                                            {/* <button
                                                onClick={() => navigate(`/referral-campaign/detail/${campaign._id}`)}
                                                className="text-blue-600 hover:underline mr-2"
                                            >
                                                Detalles
                                            </button> */}
                                            <button
                                                onClick={() => handleDelete(campaign._id)}
                                                className="text-red-600 hover:underline"
                                            >
                                                Eliminar
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReferralCampaignManagementPage;
