import React, { useState } from "react";
import BotonAccion from "./BotonAccion";

const rewardOptions = [
    "Acumular puntos extra en la tarjeta",
    "Regalo", // Usamos "Regalo" para que al normalizar se convierta en "REGALO"
    "Descuento próxima compra"
];

const ModificarEstrategia = ({ estrategia, setEstrategia, onCancel, onSave }) => {
    const [localEstrategia, setLocalEstrategia] = useState({ ...estrategia });

    const handleChange = (campo, valor) => {
        setLocalEstrategia((prev) => ({ ...prev, [campo]: valor }));
    };

    const handlePremioChange = (index, campo, valor) => {
        const nuevosPremios = localEstrategia.premios.map((premio, i) =>
            i === index ? { ...premio, [campo]: campo === "puntosNecesarios" ? Number(valor) : valor } : premio
        );
        setLocalEstrategia((prev) => ({ ...prev, premios: nuevosPremios }));
    };

    const addPremio = () => {
        const nuevoPremio = { nombre: "", descripcion: "", puntosNecesarios: 0 };
        setLocalEstrategia((prev) => ({
            ...prev,
            premios: [...(prev.premios || []), nuevoPremio],
        }));
    };

    const removePremio = (index) => {
        const nuevosPremios = localEstrategia.premios.filter((_, i) => i !== index);
        setLocalEstrategia((prev) => ({ ...prev, premios: nuevosPremios }));
    };

    const handleGuardar = () => {
        setEstrategia(localEstrategia);
        onSave();
    };

    return (
        <div className="w-full p-4 border rounded-lg shadow-md">
            <h3 className="text-xl font-bold text-gray-800 mb-4">✏️ Edita tu Estrategia</h3>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Condición de Acumulación (pointscondition):
                </label>
                <input
                    type="text"
                    value={localEstrategia.pointscondition || ""}
                    onChange={(e) => handleChange("pointscondition", e.target.value)}
                    className="border rounded w-full px-3 py-2 mt-1"
                />
            </div>
            {localEstrategia.metodoAcumulacion === "PUNTOS" && (
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">Puntos Ticket (mínimo 6):</label>
                    <input
                        type="number"
                        value={localEstrategia.puntosTicket}
                        onChange={(e) => handleChange("puntosTicket", Number(e.target.value))}
                        className="border rounded w-full px-3 py-2 mt-1"
                    />
                </div>
            )}
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Incentivo (Regalo/Descuento):
                </label>
                <select
                    value={localEstrategia.incentivo}
                    onChange={(e) => handleChange("incentivo", e.target.value)}
                    className="border rounded w-full px-3 py-2 mt-1"
                >
                    <option value="Regalo">Regalo</option>
                    <option value="Descuento">Descuento</option>
                </select>
            </div>
            <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                    Programa de Referidos (SI/NO):
                </label>
                <select
                    value={localEstrategia.referidos}
                    onChange={(e) => handleChange("referidos", e.target.value)}
                    className="border rounded w-full px-3 py-2 mt-1"
                >
                    <option value="SI">SI</option>
                    <option value="NO">NO</option>
                </select>
            </div>
            {localEstrategia.referidos === "SI" && (
                <>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Recompensa para Referidor:
                        </label>
                        <select
                            value={localEstrategia.rewardReferidor || ""}
                            onChange={(e) => handleChange("rewardReferidor", e.target.value)}
                            className="border rounded w-full px-3 py-2 mt-1"
                        >
                            {rewardOptions.map((opcion, index) => (
                                <option key={index} value={opcion}>
                                    {opcion}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">
                            Recompensa para Referido:
                        </label>
                        <select
                            value={localEstrategia.rewardReferido || ""}
                            onChange={(e) => handleChange("rewardReferido", e.target.value)}
                            className="border rounded w-full px-3 py-2 mt-1"
                        >
                            {rewardOptions.map((opcion, index) => (
                                <option key={index} value={opcion}>
                                    {opcion}
                                </option>
                            ))}
                        </select>
                    </div>
                </>
            )}
            {localEstrategia.metodoAcumulacion === "ACUMULACION" && (
                <>
                    <div className="mb-4">
                        <label className="block text-sm font-medium text-gray-700">Premios:</label>
                        {localEstrategia.premios && localEstrategia.premios.length > 0 ? (
                            localEstrategia.premios.map((premio, index) => (
                                <div key={index} className="border p-2 rounded mb-2">
                                    <div className="mb-2">
                                        <label className="block text-xs font-medium text-gray-600">Nombre:</label>
                                        <input
                                            type="text"
                                            value={premio.nombre}
                                            onChange={(e) => handlePremioChange(index, "nombre", e.target.value)}
                                            className="border rounded w-full px-2 py-1 mt-1 text-sm"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-xs font-medium text-gray-600">Descripción:</label>
                                        <input
                                            type="text"
                                            value={premio.descripcion}
                                            onChange={(e) => handlePremioChange(index, "descripcion", e.target.value)}
                                            className="border rounded w-full px-2 py-1 mt-1 text-sm"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-xs font-medium text-gray-600">
                                            Puntos Necesarios:
                                        </label>
                                        <input
                                            type="number"
                                            value={premio.puntosNecesarios}
                                            onChange={(e) =>
                                                handlePremioChange(index, "puntosNecesarios", Number(e.target.value))
                                            }
                                            className="border rounded w-full px-2 py-1 mt-1 text-sm"
                                        />
                                    </div>
                                    <div className="flex justify-end">
                                        <button onClick={() => removePremio(index)} className="text-red-500 text-xs">
                                            Eliminar
                                        </button>
                                    </div>
                                </div>
                            ))
                        ) : (
                            <p className="text-sm text-gray-600">No hay premios definidos.</p>
                        )}
                    </div>
                    <div className="flex justify-end mb-4">
                        <BotonAccion onClick={addPremio} color="bg-blue-500" text="Añadir Premio" />
                    </div>
                </>
            )}
            <div className="mt-4 flex justify-between">
                <BotonAccion onClick={onCancel} color="bg-red-500" text="Cancelar" />
                <BotonAccion onClick={handleGuardar} color="bg-green-500" text="✅ Guardar Estrategia" />
            </div>
        </div>
    );
};

export default ModificarEstrategia;
