// src/pages/RewardsPage.jsx

import React, { useState, useEffect } from 'react';
import { get, post, patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import ManageRewardModal from '../../components/modals/ManageRewardModal';
import useAuthStore from '../../store/authStore';

const RewardsPage = () => {
    const { user } = useAuthStore();
    const [rewards, setRewards] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedReward, setSelectedReward] = useState(null);

    const userId = user?.userId || '';

    useEffect(() => {
        if (userId) {
            fetchMarcasAndRewards();
        }
    }, [userId]);

    const fetchMarcasAndRewards = async () => {
        try {
            const marcasResponse = await get(`marcas/user/${userId}`);
            setMarcas(marcasResponse);

            // Obtener recompensas de cada marca y fusionarlas
            const allRewards = await Promise.all(
                marcasResponse.map(async (marca) => {
                    try {
                        const rewardsResponse = await get(`rewards/marca/${marca._id}`);
                        // Adjuntar nombre de marca en cada recompensa
                        return rewardsResponse.map((reward) => ({
                            ...reward,
                            marcaNombre: marca.nombre,
                        }));
                    } catch (error) {
                        console.error(
                            `Error al obtener recompensas de la marca: ${marca._id}`,
                            error
                        );
                        return [];
                    }
                })
            );
            setRewards(allRewards.flat());
        } catch (error) {
            console.error('Error al obtener marcas o recompensas:', error);
            Swal.fire('Error', 'No se pudieron cargar las marcas o recompensas.', 'error');
        }
    };

    const handleCreateOrUpdate = async (rewardData) => {
        try {
            if (selectedReward) {
                await patch(`rewards/${selectedReward._id}`, rewardData);
                Swal.fire('Éxito', 'Recompensa actualizada correctamente.', 'success');
            } else {
                await post('rewards', rewardData);
                Swal.fire('Éxito', 'Recompensa creada correctamente.', 'success');
            }
            setShowModal(false);
            fetchMarcasAndRewards();
        } catch (error) {
            console.error('Error al guardar la recompensa:', error);
            Swal.fire('Error', 'No se pudo guardar la recompensa.', 'error');
        }
    };

    return (
        <div className="min-h-screen bg-gray-100 overflow-x-hidden">
            <div className="w-full max-w-screen-xl mx-auto px-4 py-6 sm:px-6">
                <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-gray-800">
                    Gestión de Recompensas
                </h1>

                <button
                    className="bg-green-500 text-white px-4 py-2 rounded mb-4"
                    onClick={() => {
                        setSelectedReward(null);
                        setShowModal(true);
                    }}
                >
                    Crear Recompensa
                </button>

                {rewards.length === 0 ? (
                    <p className="text-gray-600">No hay recompensas registradas.</p>
                ) : (
                    <>
                        {/* =========================================
                            PREMIOS
                           ========================================= */}
                        <h2 className="text-xl sm:text-2xl font-bold mt-6 mb-3 text-gray-800">
                            Premios
                        </h2>

                        {/* VERSIÓN DESKTOP (tablas) ─ Visible sólo en md+ */}
                        <div className="hidden md:block w-full overflow-x-auto">
                            <table className="w-full table-auto border-collapse text-sm sm:text-base">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Nombre
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Descripción
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Puntos
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Marca
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewards
                                        .filter((r) => r.tipo === 'PREMIO')
                                        .map((reward) => (
                                            <tr key={reward._id} className="hover:bg-gray-50">
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.nombre}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.descripcion}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.puntos ?? '-'}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.marcaNombre}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    <button
                                                        className="bg-blue-500 text-white px-2 py-1 rounded"
                                                        onClick={() => {
                                                            setSelectedReward(reward);
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        Editar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                        {/* VERSIÓN MÓVIL ─ Tarjetas simplificadas ─ Visible sólo en < md */}
                        <div className="md:hidden space-y-4 px-2 py-2">
                            {rewards
                                .filter((r) => r.tipo === 'PREMIO')
                                .map((reward) => (
                                    <div
                                        key={reward._id}
                                        className="bg-white border border-gray-300 rounded-lg shadow-sm p-4"
                                    >
                                        {/* Datos principales */}
                                        <h2 className="text-lg font-bold truncate text-gray-800 mb-1">
                                            {reward.nombre}
                                        </h2>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Descripción:</span>{' '}
                                            {reward.descripcion}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Puntos:</span>{' '}
                                            {reward.puntos ?? '-'}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Marca:</span>{' '}
                                            {reward.marcaNombre}
                                        </p>

                                        {/* Botón Editar */}
                                        <div className="mt-3">
                                            <button
                                                className="bg-blue-500 text-white px-3 py-1 rounded"
                                                onClick={() => {
                                                    setSelectedReward(reward);
                                                    setShowModal(true);
                                                }}
                                            >
                                                Editar
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>

                        {/* =========================================
                            DESCUENTOS
                           ========================================= */}
                        <h2 className="text-xl sm:text-2xl font-bold mt-6 mb-3 text-gray-800">
                            Descuentos
                        </h2>

                        {/* DESKTOP (md+) ─ tabla */}
                        <div className="hidden md:block w-full overflow-x-auto">
                            <table className="w-full table-auto border-collapse text-sm sm:text-base">
                                <thead>
                                    <tr className="bg-gray-200">
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Nombre
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Descripción
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Monto
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            %
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Mínimo
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Marca
                                        </th>
                                        <th className="border px-2 py-2 break-words whitespace-normal text-left">
                                            Acciones
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {rewards
                                        .filter((r) => r.tipo === 'DESCUENTO')
                                        .map((reward) => (
                                            <tr key={reward._id} className="hover:bg-gray-50">
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.nombre}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.descripcion}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.cantidadMonetaria != null
                                                        ? `${reward.cantidadMonetaria} ${reward.currency || ''
                                                        }`
                                                        : '-'}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.porcentaje ?? '-'}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.montoMinimo ?? '-'}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    {reward.marcaNombre}
                                                </td>
                                                <td className="border px-2 py-2 break-words whitespace-normal">
                                                    <button
                                                        className="bg-blue-500 text-white px-2 py-1 rounded"
                                                        onClick={() => {
                                                            setSelectedReward(reward);
                                                            setShowModal(true);
                                                        }}
                                                    >
                                                        Editar
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>

                        {/* MÓVIL (< md) ─ tarjetas */}
                        <div className="md:hidden space-y-4 px-2 py-2">
                            {rewards
                                .filter((r) => r.tipo === 'DESCUENTO')
                                .map((reward) => (
                                    <div
                                        key={reward._id}
                                        className="bg-white border border-gray-300 rounded-lg shadow-sm p-4"
                                    >
                                        <h2 className="text-lg font-bold truncate text-gray-800 mb-1">
                                            {reward.nombre}
                                        </h2>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Descripción:</span>{' '}
                                            {reward.descripcion}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Monto:</span>{' '}
                                            {reward.cantidadMonetaria != null
                                                ? `${reward.cantidadMonetaria} ${reward.currency || ''}`
                                                : '-'}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">%</span>{' '}
                                            {reward.porcentaje ?? '-'}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Mínimo:</span>{' '}
                                            {reward.montoMinimo ?? '-'}
                                        </p>
                                        <p className="text-sm text-gray-700">
                                            <span className="font-semibold">Marca:</span>{' '}
                                            {reward.marcaNombre}
                                        </p>

                                        <div className="mt-3">
                                            <button
                                                className="bg-blue-500 text-white px-3 py-1 rounded"
                                                onClick={() => {
                                                    setSelectedReward(reward);
                                                    setShowModal(true);
                                                }}
                                            >
                                                Editar
                                            </button>
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </>
                )}

                {/* MODAL de creación/edición */}
                {showModal && (
                    <ManageRewardModal
                        reward={selectedReward}
                        marcas={marcas}
                        onClose={() => setShowModal(false)}
                        onSave={handleCreateOrUpdate}
                    />
                )}
            </div>
        </div>
    );
};

export default RewardsPage;
