// src/pages/communication/EmailConfigPage.jsx
import React from 'react';
import SMTPConfigForm from '../../components/communication/SMTPConfigForm';
import DKIMConfigForm from '../../components/communication/DKIMConfigForm';
import BackToCommunication from '../../components/communication/BackToCommunication';

function EmailConfigPage() {
    return (
        <div>
            <BackToCommunication />
            <h1 className="text-2xl font-bold mb-4">Configuración de Email</h1>
            <SMTPConfigForm />
            <DKIMConfigForm />
        </div>
    );
}

export default EmailConfigPage;