import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import Select from 'react-select';
import {
    ResponsiveContainer,
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    AreaChart,
    Area,
    LineChart,
    Line
} from 'recharts';

//
// COMPONENTE: TotalSalesBarChart
// Grafico de barras para mostrar ventas totales mensuales de la marca
//
const TotalSalesBarChart = ({ marcaId }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!marcaId) return;
        const fetchData = async () => {
            try {
                // Endpoint: /statistics/sales/monthly/:marcaId
                const response = await get(`statistics/sales/monthly/${marcaId}`);
                setData(response);
            } catch (error) {
                console.error('Error fetching total sales data:', error);
                Swal.fire('Error', 'No se pudieron cargar las ventas totales.', 'error');
            }
        };
        fetchData();
    }, [marcaId]);
    return (
        <div className="w-full h-64">
            <ResponsiveContainer>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="sales" fill="#4f46e5" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

//
// COMPONENTE: NewCustomersBarChart
// Grafico de barras para mostrar nuevos clientes mensuales
//
const NewCustomersBarChart = ({ marcaId }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!marcaId) return;
        const fetchData = async () => {
            try {
                // Endpoint: /statistics/new-customers/monthly/:marcaId
                const response = await get(`statistics/new-customers/monthly/${marcaId}`);
                setData(response);
            } catch (error) {
                console.error('Error fetching new customers data:', error);
                Swal.fire('Error', 'No se pudieron cargar los nuevos clientes.', 'error');
            }
        };
        fetchData();
    }, [marcaId]);
    return (
        <div className="w-full h-64">
            <ResponsiveContainer>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="newCustomers" fill="#10b981" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

//
// COMPONENTE: RepurchaseRateAreaChart
// Grafico de área para mostrar la tasa de recompra mensual
//
const RepurchaseRateAreaChart = ({ marcaId }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!marcaId) return;
        const fetchData = async () => {
            try {
                // Endpoint: /statistics/repurchase-rate/monthly/:marcaId
                const response = await get(`statistics/repurchase-rate/monthly/${marcaId}`);
                setData(response);
            } catch (error) {
                console.error('Error fetching repurchase rate data:', error);
                Swal.fire('Error', 'No se pudo cargar la tasa de recompra.', 'error');
            }
        };
        fetchData();
    }, [marcaId]);
    return (
        <div className="w-full h-64">
            <ResponsiveContainer>
                <AreaChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis tickFormatter={(value) => `${(value * 100).toFixed(0)}%`} />
                    <Tooltip formatter={(value) => `${(value * 100).toFixed(2)}%`} />
                    <Area type="monotone" dataKey="repurchaseRate" stroke="#f59e0b" fill="#fbbf24" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    );
};

//
// COMPONENTE: AverageTicketBarChart
// Grafico de barras para mostrar el ticket promedio mensual
//
const AverageTicketBarChart = ({ marcaId }) => {
    const [data, setData] = useState([]);
    useEffect(() => {
        if (!marcaId) return;
        const fetchData = async () => {
            try {
                // Endpoint: /statistics/average-ticket/monthly/:marcaId
                const response = await get(`statistics/average-ticket/monthly/${marcaId}`);
                setData(response);
            } catch (error) {
                console.error('Error fetching average ticket data:', error);
                Swal.fire('Error', 'No se pudo cargar el ticket promedio.', 'error');
            }
        };
        fetchData();
    }, [marcaId]);
    return (
        <div className="w-full h-64">
            <ResponsiveContainer>
                <BarChart data={data}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="month" />
                    <YAxis />
                    <Tooltip />
                    <Bar dataKey="avgTicket" fill="#3b82f6" />
                </BarChart>
            </ResponsiveContainer>
        </div>
    );
};

//
// COMPONENTE: HotspotsLineChart
// Grafico de línea que muestra los puntos calientes diarios y permite seleccionar un día de la semana
//
const HotspotsLineChart = ({ marcaId }) => {
    const [data, setData] = useState([]);
    const [selectedDay, setSelectedDay] = useState({ value: 'Monday', label: 'Lunes' });
    const dayOptions = [
        { value: 'Monday', label: 'Lunes' },
        { value: 'Tuesday', label: 'Martes' },
        { value: 'Wednesday', label: 'Miércoles' },
        { value: 'Thursday', label: 'Jueves' },
        { value: 'Friday', label: 'Viernes' },
        { value: 'Saturday', label: 'Sábado' },
        { value: 'Sunday', label: 'Domingo' },
    ];

    useEffect(() => {
        if (!marcaId) return;
        const fetchData = async () => {
            try {
                // Endpoint: /statistics/hotspots/daily?day=...&marcaId=...
                const response = await get(`statistics/hotspots/daily?day=${selectedDay.value}&marcaId=${marcaId}`);
                setData(response);
            } catch (error) {
                console.error('Error fetching hotspots data:', error);
                Swal.fire('Error', 'No se pudieron cargar los puntos calientes del día.', 'error');
            }
        };
        fetchData();
    }, [selectedDay, marcaId]);

    return (
        <div className="w-full">
            <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Puntos Calientes del Día</h3>
                <div className="w-40">
                    <Select
                        value={selectedDay}
                        onChange={setSelectedDay}
                        options={dayOptions}
                    />
                </div>
            </div>
            <div className="w-full h-64">
                <ResponsiveContainer>
                    <LineChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="hour" />
                        <YAxis />
                        <Tooltip />
                        <Line type="monotone" dataKey="value" stroke="#9333ea" />
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
};

//
// DashboardAnalyticsPage
//
const DashboardAnalyticsPage = () => {
    const [marcas, setMarcas] = useState([]);
    const [selectedMarca, setSelectedMarca] = useState(null);

    useEffect(() => {
        const fetchMarcas = async () => {
            try {
                const response = await get('marcas');
                setMarcas(response);
                if (response.length > 0) {
                    setSelectedMarca(response[0]);
                }
            } catch (error) {
                console.error('Error fetching marcas:', error);
                Swal.fire('Error', 'No se pudieron cargar las marcas.', 'error');
            }
        };
        fetchMarcas();
    }, []);

    const handleMarcaChange = (selectedOption) => {
        setSelectedMarca(selectedOption);
    };

    if (!selectedMarca) {
        return <div className="p-6 text-center">Cargando marcas...</div>;
    }

    const marcaOptions = marcas.map((m) => ({
        value: m._id,
        label: m.nombre,
        ...m,
    }));

    return (
        <div className="min-h-screen bg-gray-50 p-8">
            <div className="container mx-auto">
                <header className="mb-12 text-center">
                    <h1 className="text-5xl font-bold text-gray-800">Dashboard Analítico</h1>
                    <p className="text-xl text-gray-600 mt-4">
                        Visualiza un resumen general y detallado de los KPIs de tu negocio para tomar decisiones estratégicas.
                    </p>
                    <div className="mt-4 w-64 mx-auto">
                        <Select
                            options={marcaOptions}
                            value={{ value: selectedMarca._id, label: selectedMarca.nombre, ...selectedMarca }}
                            onChange={handleMarcaChange}
                            placeholder="Selecciona una marca..."
                        />
                    </div>
                </header>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Ventas Totales Mensuales</h2>
                    <TotalSalesBarChart marcaId={selectedMarca._id} />
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Nuevos Clientes Mensuales</h2>
                    <NewCustomersBarChart marcaId={selectedMarca._id} />
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Tasa de Recompra</h2>
                    <RepurchaseRateAreaChart marcaId={selectedMarca._id} />
                </section>

                <section className="mb-12">
                    <h2 className="text-3xl font-semibold text-gray-800 mb-4">Ticket Promedio Mensual</h2>
                    <AverageTicketBarChart marcaId={selectedMarca._id} />
                </section>

                <section className="mb-12">
                    <HotspotsLineChart marcaId={selectedMarca._id} />
                </section>
            </div>
        </div>
    );
};

export default DashboardAnalyticsPage;
