// src/components/common/MainMenu.jsx
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
    FaChevronDown,
    FaUserPlus,
    FaBars,
    FaTimes,
    FaCoins,
} from 'react-icons/fa';
import CrearClienteForm from '../modals/CrearClienteForm';
import SumarPuntosModal from '../modals/SumarPuntosModal';

export default function MainMenu() {
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState(false);
    const [showClienteForm, setShowClienteForm] = useState(false);
    const [showSumarPuntosModal, setShowSumarPuntosModal] = useState(false);
    const [defaultMarcaId, setDefaultMarcaId] = useState(null);
    const [tiendas, setTiendas] = useState([]);
    const toggleMenu = () => {
        setMenuOpen(!menuOpen);
    };

    // Solo se encarga de abrir el modal
    const handleCrearCliente = () => {
        setShowClienteForm(true);
    };

    const handleSumarPuntos = () => {
        setShowSumarPuntosModal(true);
    };

    const closeClienteForm = () => {
        setShowClienteForm(false);
    };

    const closeSumarPuntosModal = () => {
        setShowSumarPuntosModal(false);
    };

    return (
        <nav className="bg-white shadow top-[64px] z-40">
            <div className="max-w-7xl mx-auto px-4">
                {/* Cabecera del menú (logo + hamburguesa) */}
                <div className="flex items-center justify-between py-2">
                    {/* Botón hamburguesa (sólo mobile) */}
                    <div className="flex md:hidden">
                        <button
                            onClick={toggleMenu}
                            type="button"
                            className="text-gray-600 hover:text-gray-800 focus:outline-none"
                        >
                            {menuOpen ? (
                                <FaTimes className="text-2xl" />
                            ) : (
                                <FaBars className="text-2xl" />
                            )}
                        </button>
                    </div>
                </div>
            </div>

            {/** 
       *  MENÚ DESKTOP: 
       *  Aparece en una barra inferior (border-t) con dropdowns por hover 
       */}
            <div className="hidden md:block border-t border-gray-200">
                <div className="max-w-7xl mx-auto px-4">
                    <ul className="flex items-center space-x-6 h-12">
                        {/* GESTION */}
                        <li className="relative group py-1">
                            <button className="flex items-center px-2 hover:text-gray-700 focus:outline-none">
                                Gestión <FaChevronDown className="ml-1 text-sm" />
                            </button>
                            {/* Submenú: sin 'mt-x' o muy pequeño para no perder hover */}
                            <ul
                                className="absolute left-0 top-full mt-0 w-48 bg-white border border-gray-200 rounded shadow-lg 
                  opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto 
                  transition-opacity z-50"
                            >
                                <li>
                                    <Link
                                        to="/marcas"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Gestión de Marcas
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/tiendas"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Gestión de Tiendas
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/clientes"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Gestión de Clientes
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/inventario"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Gestión de Productos
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        {/* CAMPAÑAS Y PROMOCIONES */}
                        <li className="relative group py-1">
                            <button className="flex items-center px-2 hover:text-gray-700 focus:outline-none">
                                Campañas y Promociones <FaChevronDown className="ml-1 text-sm" />
                            </button>
                            {/* Submenú: Dos opciones de campañas y gestión de recompensas */}
                            <ul
                                className="absolute left-0 top-full mt-0 w-48 bg-white border border-gray-200 rounded shadow-lg 
                  opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto 
                  transition-opacity z-50"
                            >

                                <li>
                                    <Link to="/game-loyalty/manage" className="block px-4 py-2 hover:bg-gray-100">
                                        Gestionar Campañas de Juegos
                                    </Link>
                                </li>

                                <li>
                                    <Link to="/referral-campaign/manage" className="block px-4 py-2 hover:bg-gray-100">
                                        Gestionar Campañas de Referidos
                                    </Link>
                                </li>


                                <li>
                                    <Link to="/rewards" className="block px-4 py-2 hover:bg-gray-100">
                                        Gestionar Recompensas
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {/* Feedback y Alertas */}
                        <li className="relative group py-1">
                            <button className="flex items-center px-2 hover:text-gray-700 focus:outline-none">
                                Feedback y Alertas <FaChevronDown className="ml-1 text-sm" />
                            </button>
                            <ul
                                className="absolute left-0 top-full mt-0 w-48 bg-white border border-gray-200 rounded shadow-lg 
                  opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto 
                  transition-opacity z-50"
                            >
                                <li>
                                    <Link
                                        to="/dashboard-surveys"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Feedback
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        to="/alertas"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Alertas
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {/* COMUNICACIÓN */}
                        {/* <li className="relative group">
                            <button className="flex items-center px-2 hover:text-gray-700 focus:outline-none">
                                Comunicación <FaChevronDown className="ml-1 text-sm" />
                            </button>
                            <ul className="absolute left-0 top-full mt-0 w-48 bg-white border border-gray-200 rounded shadow-lg opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto transition-opacity z-50">
                                <li>
                                    <Link to="/communication/dashboard" className="block px-4 py-2 hover:bg-gray-100">Centro de Comunicación</Link>
                                </li>
                                <li>
                                    <Link to="/communication/inbox" className="block px-4 py-2 hover:bg-gray-100">Bandeja de Entrada</Link>
                                </li>
                                <li>
                                    <Link to="/communication/config" className="block px-4 py-2 hover:bg-gray-100">Configuración de Email</Link>
                                </li>
                                <li>
                                    <Link to="/communication/metrics" className="block px-4 py-2 hover:bg-gray-100">Métricas</Link>
                                </li>
                                <li>
                                    <Link to="/communication/send" className="block px-4 py-2 hover:bg-gray-100">Enviar Mensaje</Link>
                                </li>
                                <li>
                                    <Link to="/communication/schedule" className="block px-4 py-2 hover:bg-gray-100">Programar Mensajes</Link>
                                </li>
                                <li>
                                    <Link to="/whatsapp/configurar" className="block px-4 py-2 hover:bg-gray-100">Configurar WhatsApp</Link>
                                </li>
                            </ul>
                        </li> */}

                        {/* ANÁLISIS */}
                        <li className="relative group py-1">
                            <button className="flex items-center px-2 hover:text-gray-700 focus:outline-none">
                                Análisis <FaChevronDown className="ml-1 text-sm" />
                            </button>
                            <ul
                                className="absolute left-0 top-full mt-0 w-48 bg-white border border-gray-200 rounded shadow-lg 
                  opacity-0 group-hover:opacity-100 pointer-events-none group-hover:pointer-events-auto 
                  transition-opacity z-50"
                            >
                                <li>
                                    <Link
                                        to="/statistics/dashboard"
                                        className="block px-4 py-2 hover:bg-gray-100"
                                    >
                                        Estadísticas
                                    </Link>
                                </li>
                            </ul>
                        </li>

                        {/* Botones de acción a la derecha */}
                        <li className="ml-auto flex items-center gap-2">
                            <button
                                onClick={handleCrearCliente}
                                className="flex items-center bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600 transition-colors whitespace-nowrap"
                            >
                                <FaUserPlus className="mr-2" />
                                Crear Cliente
                            </button>
                            <button
                                onClick={handleSumarPuntos}
                                className="flex items-center bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600 transition-colors whitespace-nowrap"
                            >
                                <FaCoins className="mr-2" />
                                Sumar Puntos
                            </button>
                        </li>
                    </ul>
                </div>
            </div >

            {/**
       * MENÚ MOBILE:
       * Se abre si "menuOpen" = true
       */}
            {
                menuOpen && (
                    <div className="md:hidden border-t border-gray-200">
                        <div className="px-4 pt-2 pb-4 bg-white shadow">
                            {/* Botón rápido Crear Cliente en mobile */}
                            <div className="mb-4">
                                <button
                                    onClick={() => {
                                        toggleMenu();
                                        handleCrearCliente();
                                    }}
                                    className="flex items-center bg-blue-500 text-white px-3 py-2 rounded hover:bg-blue-600 transition-colors  whitespace-nowrap"
                                >
                                    <FaUserPlus className="mr-2" />
                                    Crear Cliente
                                </button>
                            </div>
                            {/* Botón "Sumar Puntos" en mobile */}
                            <div className="mb-4">
                                <button
                                    onClick={() => {
                                        toggleMenu();
                                        handleSumarPuntos();
                                    }}
                                    className="flex items-center bg-green-500 text-white px-3 py-2 rounded hover:bg-green-600 transition-colors  whitespace-nowrap"
                                >
                                    <FaCoins className="mr-2" />
                                    Sumar Puntos
                                </button>
                            </div>

                            {/* Categorías en vertical */}
                            <ul className="space-y-2">
                                {/* Gestión */}
                                <li>
                                    <p className="font-semibold text-gray-800 mb-1">Gestión</p>
                                    <div className="ml-2 space-y-1">
                                        <Link
                                            to="/marcas"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Gestión de Marcas
                                        </Link>
                                        <Link
                                            to="/tiendas"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Gestión de Tiendas
                                        </Link>
                                        <Link
                                            to="/clientes"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Gestión de Clientes
                                        </Link>
                                        <Link
                                            to="/inventario"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Gestión de Productos
                                        </Link>
                                    </div>
                                </li>

                                {/* Campañas y Promociones */}
                                <li>
                                    <p className="font-semibold text-gray-800 mb-1">Campañas y Promociones</p>
                                    <div className="ml-2 space-y-1">
                                        <Link to="/game-loyalty/manage" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">
                                            Campañas de Juegos
                                        </Link>
                                        <Link to="/referral-campaign/manage" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">
                                            Campañas de Referidos
                                        </Link>
                                        <Link to="/rewards" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">
                                            Gestionar Recompensas
                                        </Link>
                                    </div>
                                </li>

                                {/* <li>
                                <p className="font-semibold text-gray-800 mb-1">Comunicación</p>
                                <div className="ml-2 space-y-1">
                                    <Link to="/communication/dashboard" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Centro de Comunicación</Link>
                                    <Link to="/communication/inbox" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Bandeja de Entrada</Link>
                                    <Link to="/communication/config" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Configuración de Email</Link>
                                    <Link to="/communication/metrics" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Métricas</Link>
                                    <Link to="/communication/send" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Enviar Mensaje</Link>
                                    <Link to="/communication/schedule" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Programar Mensajes</Link>
                                    <Link to="/whatsapp/configurar" onClick={toggleMenu} className="block px-2 py-1 hover:bg-gray-100 rounded">Configurar WhatsApp</Link>
                                </div>
                            </li> */}

                                {/* Feedback y Alertas */}
                                <li>
                                    <p className="font-semibold text-gray-800 mb-1">Feedback y Alertas</p>
                                    <div className="ml-2 space-y-1">
                                        <Link
                                            to="/dashboard-surveys"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Feedback
                                        </Link>
                                        <Link
                                            to="/alertas"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Alertas
                                        </Link>
                                    </div>
                                </li>

                                {/* Análisis */}
                                <li>
                                    <p className="font-semibold text-gray-800 mb-1">Análisis</p>
                                    <div className="ml-2 space-y-1">
                                        <Link
                                            to="/statistics"
                                            onClick={toggleMenu}
                                            className="block px-2 py-1 hover:bg-gray-100 rounded"
                                        >
                                            Estadísticas
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                )
            }
            {/* Modal para crear cliente */}
            {
                showClienteForm && (
                    <CrearClienteForm
                        marcaId={defaultMarcaId}
                        tiendas={tiendas}
                        onClose={closeClienteForm}
                        onClienteCreated={() => {
                            // Acción posterior a la creación
                        }}
                    />
                )
            }
            {
                showSumarPuntosModal && (
                    <SumarPuntosModal
                        onClose={() => setShowSumarPuntosModal(false)}
                    />
                )
            }
        </nav >
    );
}