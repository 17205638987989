// src/components/modals/QrPdfModal.jsx
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { getBlob } from '../../helpers/apiHelper';

const sizes = [
    { label: 'Pequeño (150x150)', value: '150' },
    { label: 'Mediano (300x300)', value: '300' },
    { label: 'Grande (450x450)', value: '450' },
];

const designs = [
    { label: 'Diseño 1', value: 'design1' },
    { label: 'Diseño 2', value: 'design2' },
    { label: 'Diseño 3', value: 'design3' },
];

const QrPdfModal = ({ tiendaId, onClose }) => {
    const [selectedSize, setSelectedSize] = useState(sizes[1].value); // Valor por defecto "Mediano"
    const [selectedDesign, setSelectedDesign] = useState(designs[0].value); // Valor por defecto "Diseño 1"
    const [loading, setLoading] = useState(false);

    const downloadFile = (blob, filename) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
    };

    const handleDownloadQR = async () => {
        setLoading(true);
        try {
            const blob = await getBlob(`tiendas/${tiendaId}/qr?size=${selectedSize}`);
            downloadFile(blob, `qr-${tiendaId}-${selectedSize}.png`);
        } catch (error) {
            Swal.fire('Error', 'No se pudo descargar el QR', 'error');
        } finally {
            setLoading(false);
        }
    };

    const handleDownloadPDF = async () => {
        setLoading(true);
        try {
            const blob = await getBlob(`tiendas/${tiendaId}/pdf?design=${selectedDesign}`);
            downloadFile(blob, `tienda-${tiendaId}.pdf`);
        } catch (error) {
            Swal.fire('Error', 'No se pudo descargar el PDF', 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-md p-6 overflow-y-auto">
                <h2 className="text-2xl font-bold text-center mb-4">Descargar Material Promocional</h2>

                {/* Seleccionar tamaño de QR */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">
                        Código QR - Selecciona tamaño:
                    </label>
                    <select
                        value={selectedSize}
                        onChange={(e) => setSelectedSize(e.target.value)}
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                    >
                        {sizes.map((s) => (
                            <option key={s.value} value={s.value}>
                                {s.label}
                            </option>
                        ))}
                    </select>
                </div>

                {/* Seleccionar diseño de PDF */}
                <div className="mb-4">
                    <label className="block text-gray-700 font-semibold mb-2">
                        Selecciona un Diseño:
                    </label>
                    <select
                        value={selectedDesign}
                        onChange={(e) => setSelectedDesign(e.target.value)}
                        className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                    >
                        {designs.map((d) => (
                            <option key={d.value} value={d.value}>
                                {d.label}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="flex flex-col space-y-3">
                    <button
                        onClick={handleDownloadQR}
                        disabled={loading}
                        className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition"
                    >
                        {loading ? 'Descargando QR...' : 'Descargar QR'}
                    </button>
                    <button
                        onClick={handleDownloadPDF}
                        disabled={loading}
                        className="bg-purple-600 text-white px-4 py-2 rounded hover:bg-purple-700 transition"
                    >
                        {loading ? 'Descargando PDF...' : 'Descargar PDF'}
                    </button>
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600 transition"
                    >
                        Cerrar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default QrPdfModal;
