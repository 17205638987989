// src/analytics.js
import ReactGA4 from 'react-ga4';

// Inicializa GA4 con tu Measurement ID.
// Asegúrate de reemplazar 'G-B5T6XRN3KC' con tu Measurement ID real.
const initializeAnalytics = () => {
    ReactGA4.initialize('G-B5T6XRN3KC');
};

export default initializeAnalytics;
