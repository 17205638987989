// DKIMConfigForm.jsx
import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';

const DKIMConfigForm = () => {
    const [form, setForm] = useState({
        dkimDomain: '', dkimSelector: '', dkimPrivateKey: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        await post('email-config/dkim-config', form);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="DKIM Domain" onChange={(e) => setForm({ ...form, dkimDomain: e.target.value })} />
            <button type="submit">Guardar</button>
        </form>
    );
};

export default DKIMConfigForm;