import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const SegmentacionClientes = () => {
    const { marcaId } = useParams();
    const [segments, setSegments] = useState({ VIP: [], Frecuente: [], Inactivo: [] });
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchSegmentacion = async () => {
            setLoading(true);
            try {
                const data = await get(`clientes/segmentacion/${marcaId}`);
                setSegments(data);
            } catch (error) {
                Swal.fire('Error', error.message, 'error');
            } finally {
                setLoading(false);
            }
        };
        if (marcaId) {
            fetchSegmentacion();
        }
    }, [marcaId]);

    if (loading) return <div>Cargando segmentación...</div>;

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-4xl font-bold mb-4">Segmentación de Clientes</h1>
            <div>
                <h2 className="text-2xl font-semibold">Clientes VIP</h2>
                {segments.VIP.map(cliente => (
                    <div key={cliente._id}>
                        {cliente.nombre} {cliente.apellido}
                    </div>
                ))}
            </div>
            <div>
                <h2 className="text-2xl font-semibold">Clientes Frecuentes</h2>
                {segments.Frecuente.map(cliente => (
                    <div key={cliente._id}>
                        {cliente.nombre} {cliente.apellido}
                    </div>
                ))}
            </div>
            <div>
                <h2 className="text-2xl font-semibold">Clientes Inactivos</h2>
                {segments.Inactivo.map(cliente => (
                    <div key={cliente._id}>
                        {cliente.nombre} {cliente.apellido}
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SegmentacionClientes;
