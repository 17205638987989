// Pregunta.jsx
import React from "react";

const Pregunta = ({ pregunta, opciones = [], seleccion, onSeleccion }) => {
    if (!opciones || opciones.length === 0) return null;

    return (
        <div className="flex flex-col items-center w-full">
            <p className="text-md font-semibold mb-2">{pregunta}</p>
            <div className="grid grid-cols-1 gap-2 w-full">
                {opciones.map((opcion) => (
                    <button
                        key={opcion}
                        className={`px-4 py-2 rounded-lg border ${seleccion === opcion ? "bg-blue-500 text-white" : "bg-gray-100"}`}
                        onClick={() => onSeleccion(opcion)}
                    >
                        {opcion}
                    </button>
                ))}
            </div>
        </div>
    );
};

export default Pregunta;
