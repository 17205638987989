// src/AppContent.jsx
import React, { useEffect, useState } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import initializeAnalytics from './analytics';
import useAuthStore from './store/authStore';
import { get } from './helpers/apiHelper';

// Importación de páginas y componentes globales
import Home from './pages/common/Home';
import Login from './pages/auth/Login';
import Register from './pages/auth/Register';
import Dashboard from './pages/common/Dashboard';
import ProtectedRoute from './components/routes/ProtectedRoute';
import Header from './components/common/Header';
import Footer from './components/common/Footer';
import Sidebar from './components/common/Sidebar';
import PublicRoute from './components/routes/PublicRoute';
import TermsAndConditions from './pages/common/TermsAndConditions';
import CookiePolicyPage from './pages/common/CookiePolicyPage';
import PaymentResult from './pages/payment/PaymentResult';
import Settings from './pages/cliente/Settings';
import ResetPassword from './pages/auth/ResetPassword';
import ChangePassword from './pages/auth/ChangePassword';
import EmailVerification from './pages/auth/EmailVerification';
import SubscriptionPage from './pages/payment/SubscriptionPage';
import ProtectedLayout from './components/routes/ProtectedLayout';
import MarcasPage from './pages/tienda/MarcasPage';
import TiendasPage from './pages/tienda/TiendasPage';
import ClientesList from './pages/cliente/ClientesList';
import CrearCliente from './pages/cliente/CrearCliente';
import HistoryPage from './pages/cliente/HistoryPage';
import TrabajadorLogin from './pages/auth/TrabajadorLogin';
import TrabajadorDashboard from './pages/trabajador/TrabajadorDashboard';
import TrabajadorRegister from './pages/auth/TrabajadorRegister';
import ProtectedTrabajadorRoute from './components/routes/ProtectedTrabajadorRoute';
import PricingPage from './pages/common/PricingPage';
import FeaturesPage from './pages/common/FeaturesPage';
import AboutPage from './pages/common/AboutPage';

// Encuestas y Feedback
import DashboardSurveys from './pages/feedback/DashboardSurveys';
import SurveyCreator from './pages/feedback/SurveyCreator';
import SurveyResults from './pages/feedback/SurveyResults';
import SurveyPage from './pages/feedback/SurveyPage';
import CookieConsent from './components/common/CookieConsent';
import TicketView from './pages/cliente/TicketView';
import HeaderTrabajadores from './components/common/HeaderTrabajadores';

// Estadísticas
import StatisticsDashboard from './pages/statistics/StatisticsDashboard';
import StatisticsPage from './pages/statistics/StatisticsPage';
import StoreStatisticsPage from './pages/statistics/StoreStatisticsPage';
import CustomerStatisticsPage from './pages/statistics/CustomerStatisticsPage';
import ClientesPage from './pages/cliente/ClientesPage';
import ImportClientesPage from './pages/cliente/ImportClientesPage';
import PrizeHistoryPage from './pages/cliente/PrizeHistoryPage';
import InventarioPage from './pages/inventario/InventarioPage';
import AlertModal from './components/modals/AlertModal';
import AlertPage from './pages/alertas/AlertPage';
import RewardsPage from './pages/rewards/RewardsPage';

// Campaña GameLoyalty
import CampaignManagementPage from './pages/gameLoyalty/CampaignManagementPage';
import CampaignPublicPage from './pages/gameLoyalty/CampaignPublicPage';
import CampaignDetailPage from './pages/gameLoyalty/CampaignDetailPage';
import CampaignPublicEmbedPage from './pages/gameLoyalty/CampaignPublicEmbedPage';

// Layout Bare para contenido embebible
import BareLayout from './components/layouts/BareLayout';
import DashboardCustomizable from './components/dashboards/DashboardCustomizable';
import SegmentacionClientes from './pages/cliente/SegmentacionClientes';

// Comunicación
import InboxPage from './pages/communication/InboxPage';
import EmailConfigPage from './pages/communication/EmailConfigPage';
import CommunicationMetricsPage from './pages/communication/CommunicationMetricsPage';
import SendMessagePage from './pages/communication/SendMessagePage';
import SchedulePage from './pages/communication/SchedulePage';
import CommunicationDashboard from './pages/communication/CommunicationDashboard';
import QRRegisterPage from './pages/public/QRRegisterPage';
import Blog from './pages/blog/Blog';
import BlogPost from './pages/blog/BlogPost';

import AsistenteConfiguracionModal from "./components/asistenteConfiguracion/AsistenteConfiguracionModal";
import MainMenu from './components/common/MainMenu';
import UnderConstruction from './pages/common/UnderConstruction';
import DashboardAnalyticsPage from './pages/statistics/DashboardAnalyticsPage';
import WhatsAppConfigurationPage from './pages/communication/WhatsAppConfigurationPage';
import ReferralCampaignManagementPage from './pages/referralCampaign/ReferralCampaignManagementPage';

const AppContent = () => {
    const { trabajador, user } = useAuthStore();
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [alerts, setAlerts] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const [marcasLoaded, setMarcasLoaded] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showAsistente, setShowAsistente] = useState(false);

    const location = useLocation();

    const isEmbedRoute = location.pathname.startsWith('/game-loyalty/embed/');
    const isPublicQRRegister = location.pathname.startsWith('/qr-register');
    const isTicketRoute = location.pathname.startsWith('/tickets/');

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    useEffect(() => {
        const fetchMarcasAndAlerts = async () => {
            try {
                if (user?.userId) {
                    const responseMarcas = await get(`marcas/user/${user.userId}`);
                    setMarcas(responseMarcas);
                    setMarcasLoaded(true);

                    if (responseMarcas.length > 0) {
                        const alertPromises = responseMarcas.map((marca) =>
                            get(`alertas/marca/${marca._id}`)
                        );
                        const allAlerts = (await Promise.all(alertPromises)).flat();
                        setAlerts(allAlerts);

                        if (allAlerts.length && !sessionStorage.getItem('alertModalShown')) {
                            setShowModal(true);
                            sessionStorage.setItem('alertModalShown', 'true');
                        }
                    }
                }
            } catch (error) {
                console.error('Error fetching marcas or alerts:', error);
            }
        };

        fetchMarcasAndAlerts();
    }, [user]);

    useEffect(() => {
        initializeAnalytics();
    }, []);

    useEffect(() => {
        if (user?.userId && marcasLoaded) {
            if (marcas.length === 0) {
                get("asistente-configuracion")
                    .then((data) => {
                        // Si no existe configuración previa o no hay marcas, se activa el asistente
                        if (!data.configuracion || marcas.length === 0) {
                            setShowAsistente(true);
                        } else {
                            setShowAsistente(false);
                        }
                    })
                    .catch((error) => {
                        console.error("Error obteniendo configuración:", error);
                        setShowAsistente(true);
                    });
            } else {
                // Si ya existen marcas, nos aseguramos de que el asistente no se muestre
                setShowAsistente(false);
            }
        }
    }, [user, marcas, marcasLoaded]);



    return (
        <div>
            {showModal && alerts.length > 0 && (
                <AlertModal alerts={alerts} onClose={() => setShowModal(false)} />
            )}

            {showAsistente && (
                <AsistenteConfiguracionModal
                    isOpen={true}
                    onClose={() => setShowAsistente(false)}
                    userId={user?.userId} // ✅ Se pasa al modal correctamente
                />
            )}

            {/* Mostrar globalmente el header y sidebar solo si NO es ruta embebida ni QRRegisterPage */}
            {(!isEmbedRoute && !isPublicQRRegister && !isTicketRoute) && (
                <>
                    {trabajador ? (
                        <HeaderTrabajadores />
                    ) : (
                        <>
                            <Header toggleSidebar={toggleSidebar} />
                            {user?.userId ? <MainMenu /> : ''}

                        </>
                    )}
                    <Sidebar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
                </>
            )}

            <main>
                <Routes>
                    {/* Ruta embebida usando BareLayout */}
                    <Route element={<BareLayout />}>
                        <Route path="/game-loyalty/embed/:campaignId" element={<CampaignPublicEmbedPage />} />
                        <Route path="/qr-register/:tiendaId" element={<QRRegisterPage />} />
                        <Route path="/tickets/:ticketUrl" element={<TicketView />} />
                    </Route>

                    {/* Rutas públicas */}
                    <Route element={<PublicRoute />}>
                        <Route path="/" element={<Home />} />
                        <Route path="/features" element={<FeaturesPage />} />
                        <Route path="/about" element={<AboutPage />} />
                        <Route path="/pricing" element={<PricingPage />} />
                        <Route path="/login" element={<Login />} />
                        <Route path="/register" element={<Register />} />
                        <Route path="/trabajador/login" element={<TrabajadorLogin />} />
                        <Route path="/trabajador/register" element={<TrabajadorRegister />} />
                        <Route path="/reset-password" element={<ResetPassword />} />
                        <Route path="/change-password" element={<ChangePassword />} />
                        <Route path="/verify" element={<EmailVerification />} />
                        <Route path="/game-loyalty/public/:campaignId" element={<CampaignPublicPage />} />
                        <Route path="/terminos" element={<TermsAndConditions />} />
                        <Route path="/politica-de-cookies" element={<CookiePolicyPage />} />

                        <Route path="/blog" element={<Blog />} />
                        <Route path="/blog/:slug" element={<BlogPost />} />
                        {/* Ruta fallback */}
                        <Route path="*" element={<div>404 Not Found</div>} />

                    </Route>

                    {/* Rutas protegidas */}
                    <Route element={<ProtectedRoute />}>
                        <Route element={<ProtectedLayout />}>
                            <Route path="/dashboard" element={<Dashboard />} />
                            <Route
                                path="/dashboard/custom"
                                element={user ? <DashboardCustomizable userId={user.userId} /> : <Navigate to="/login" />}
                            />
                            <Route path="/settings" element={<Settings />} />
                            <Route path="/subscribe" element={<SubscriptionPage />} />
                            <Route path="/marcas" element={<MarcasPage />} />
                            <Route path="/tiendas" element={<TiendasPage />} />
                            <Route path="/clientes" element={<ClientesPage />} />
                            <Route path="/clientes/importar" element={<ImportClientesPage />} />
                            <Route path="/clientes/segmentacion/:marcaId" element={<SegmentacionClientes />} />
                            <Route path="/tiendas/:tiendaId/clientes" element={<ClientesList />} />
                            <Route path="/tiendas/:tiendaId/clientes/create" element={<CrearCliente />} />
                            <Route path="/history/:clienteId" element={<HistoryPage />} />
                            <Route path="/canjeos/:clienteId" element={<PrizeHistoryPage />} />
                            <Route path="/inventario" element={<InventarioPage />} />
                            <Route path="/rewards" element={<RewardsPage />} />
                            <Route path="/alertas" element={<AlertPage />} />

                            {/* Gestión de comunicaciones */}
                            <Route path="/communication/dashboard" element={<CommunicationDashboard />} />
                            <Route path="/communication/inbox" element={<InboxPage />} />
                            <Route path="/communication/config" element={<EmailConfigPage />} />
                            <Route path="/communication/metrics" element={<CommunicationMetricsPage />} />
                            <Route path="/communication/send" element={<SendMessagePage />} />
                            <Route path="/communication/schedule" element={<SchedulePage />} />
                            <Route path="/whatsapp/configurar" element={<WhatsAppConfigurationPage />} />

                            {/* Gestión de campañas GameLoyalty */}
                            <Route path="/game-loyalty/manage" element={<CampaignManagementPage />} />
                            <Route path="/game-loyalty/detail/:campaignId" element={<CampaignDetailPage />} />
                            <Route path="/referral-campaign/manage" element={<ReferralCampaignManagementPage />} />

                            {/* Encuestas */}
                            <Route path="/dashboard-surveys" element={<DashboardSurveys />} />
                            <Route path="/create-survey" element={<SurveyCreator />} />
                            <Route path="/survey-results/:surveyId" element={<SurveyResults />} />

                            {/* Estadísticas */}
                            <Route path="/statistics/dashboard" element={<DashboardAnalyticsPage />} />
                            <Route path="/statistics/*" element={<StatisticsDashboard />}>
                                <Route path="marca/:marcaId?" element={<StatisticsPage />} />
                                <Route path="tienda/:tiendaId?" element={<StoreStatisticsPage />} />
                                <Route path="cliente/:clienteId?" element={<CustomerStatisticsPage />} />
                                <Route path="" element={<Navigate replace to="marca" />} />
                            </Route>

                            <Route path="/modulo-en-proceso" element={<UnderConstruction />} />

                        </Route>
                    </Route>

                    {/* Rutas protegidas para trabajadores */}
                    <Route element={<ProtectedTrabajadorRoute />}>
                        <Route path="/trabajador/:trabajadorId/dashboard" element={<TrabajadorDashboard />} />
                        <Route path="/trabajador/:trabajadorId/tiendas/:tiendaId/clientes" element={<ClientesList />} />
                        <Route path="/trabajador/:trabajadorId/tiendas/:tiendaId/clientes/create" element={<CrearCliente />} />
                    </Route>

                    <Route path="/payment-result" element={<PaymentResult />} />
                    <Route path="/surveys/:surveyId" element={<SurveyPage />} />
                    <Route path="*" element={<Navigate replace to="/" />} />
                </Routes>
                <CookieConsent />
            </main>

            {!isEmbedRoute && !isTicketRoute && <Footer />}

        </div>
    );
};

export default AppContent;
