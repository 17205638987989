import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { post } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';
import Swal from 'sweetalert2';

function SurveyCreator() {
    const navigate = useNavigate();
    const { user } = useAuthStore();
    const [showModal, setShowModal] = useState(false);
    const [surveyTitle, setSurveyTitle] = useState('');

    const toggleModal = () => {
        setShowModal(!showModal);
    };

    const handleCreateSurvey = async () => {
        const predefinedQuestions = [
            { type: "likert", question: "¿Cómo calificaría la calidad de los productos/servicios que recibió?", options: ["1", "2", "3", "4", "5"] },
            { type: "likert", question: "¿Está satisfecho con la atención al cliente proporcionada durante su visita?", options: ["1", "2", "3", "4", "5"] },
            { type: "likert", question: "¿Cómo evaluaría el tiempo de espera para ser atendido?", options: ["1", "2", "3", "4", "5"] },
            { type: "likert", question: "¿Cómo calificaría el ambiente y la comodidad de nuestras instalaciones?", options: ["1", "2", "3", "4", "5"] },
            { type: "likert", question: "¿Considera que el precio pagado es justo por la calidad del producto/servicio recibido?", options: ["1", "2", "3", "4", "5"] },
            { type: "likert", question: "¿Qué tan probable es que recomiendes nuestro comercio a un amigo?", options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"] },
            { type: "openEnded", question: "¿Qué podríamos hacer para mejorar su experiencia en futuras visitas?" },
            { type: "openEnded", question: "Si desea añadir algún otro comentario sobre su experiencia, por favor, hágalo aquí." }
        ];


        try {
            const newSurvey = await post('surveys', { title: surveyTitle, creatorId: user.id, questions: predefinedQuestions });
            navigate(`/survey/${newSurvey.id}`);
            Swal.fire({
                icon: 'success',
                title: 'Survey creada correctamente!',
                text: 'Comparte la url para conseguir respuestas.',
                confirmButtonColor: '#3085d6'
            });
        } catch (error) {
            console.error('Failed to create survey:', error);
            Swal.fire({
                icon: 'error',
                title: 'Error creating survey',
                text: 'Please try again.',
                confirmButtonColor: '#dc3545'
            });
        }
    };

    return (
        <div className="container mx-auto p-4 mb-4 text-center">
            <button
                onClick={toggleModal}
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
            >
                Crear encuesta nueva
            </button>

            {/* Modal */}
            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-50 p-4">
                    <div className="bg-white p-6 rounded-lg text-black w-full max-w-md">
                        <h2 className="text-lg md:text-xl font-bold mb-4">
                            Título de tu encuesta
                        </h2>
                        <input
                            type="text"
                            className="border border-gray-300 rounded-md w-full p-2 mb-4"
                            placeholder="Ingresa el título de la encuesta"
                            value={surveyTitle}
                            onChange={(e) => setSurveyTitle(e.target.value)}
                        />
                        <div className="flex justify-end">
                            <button
                                onClick={toggleModal}
                                className="mr-2 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleCreateSurvey}
                                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                            >
                                Crear Encuesta
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default SurveyCreator;
