import React, { useState, useEffect } from 'react';
import { get, post } from '../../helpers/apiHelper';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

function CrearCliente() {
  const { tiendaId } = useParams();
  const navigate = useNavigate();
  const [cliente, setCliente] = useState({
    nombre: '',
    apellido: '',
    email: '',
    telefono: '',
    fechaCumpleanos: '',
    marca: '',
    tienda: tiendaId,
    geographic_info: {
      country: '',
      state: '',
      city: '',
      postal_code: ''
    }
  });
  const [codigoReferido, setCodigoReferido] = useState('');

  useEffect(() => {
    const fetchMarca = async () => {
      try {
        const response = await get(`tiendas/${tiendaId}`);
        const marcaId = response.marca;
        setCliente((prev) => ({ ...prev, marca: marcaId }));
      } catch (error) {
        console.error('Error fetching marca:', error);
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };
    fetchMarca();
  }, [tiendaId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('geographic_info')) {
      const [group, key] = name.split('.');
      setCliente((prev) => ({
        ...prev,
        [group]: { ...prev[group], [key]: value }
      }));
    } else {
      setCliente((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleCodigoReferidoChange = (e) => {
    setCodigoReferido(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!cliente.email || !cliente.telefono) {
      Swal.fire({
        title: 'Error',
        text: 'Debe proporcionar al menos un email y un teléfono',
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
      return;
    }

    const payload = { ...cliente };

    if (codigoReferido.trim() !== '') {
      const cleanedCode = codigoReferido.trim().toLowerCase().replace(/\s+/g, '');
      try {
        const refResponse = await get(
          `clientes/referral?code=${cleanedCode}&marcaId=${cliente.marca}`
        );
        if (!refResponse || !refResponse._id) {
          Swal.fire({
            title: 'Error',
            text: 'El código de referido no es válido o no pertenece a un cliente de tu marca',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
          return;
        }
        payload.referidoPor = refResponse._id;
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Error al validar el código de referido',
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
        return;
      }
    }

    try {
      await post('clientes', payload);
      Swal.fire({
        title: 'Éxito',
        text: 'Cliente creado correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
      navigate(-1);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md max-w-3xl mx-auto overflow-y-auto max-h-screen md:ml-64">
      <h1 className="text-4xl font-bold mb-4">Crear Cliente</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="nombre">
            Nombre*
          </label>
          <input
            type="text"
            name="nombre"
            value={cliente.nombre}
            onChange={handleChange}
            required
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="apellido">
            Apellido*
          </label>
          <input
            type="text"
            name="apellido"
            value={cliente.apellido}
            onChange={handleChange}
            required
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="email">
            Email*
          </label>
          <input
            type="email"
            name="email"
            value={cliente.email}
            onChange={handleChange}
            required
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="telefono">
            Teléfono*
          </label>
          <input
            type="text"
            name="telefono"
            value={cliente.telefono}
            onChange={handleChange}
            required
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="fechaCumpleanos">
            Fecha de Nacimiento*
          </label>
          <input
            type="date"
            name="fechaCumpleanos"
            value={cliente.fechaCumpleanos}
            onChange={handleChange}
            required
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        {/* Campo para código de referido */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="codigoReferido">
            Código de referido (opcional)
          </label>
          <input
            type="text"
            name="codigoReferido"
            value={codigoReferido}
            onChange={handleCodigoReferidoChange}
            placeholder="Ingresa el código de referido"
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        {/* Información geográfica */}
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.country">
            País
          </label>
          <input
            type="text"
            name="geographic_info.country"
            value={cliente.geographic_info.country}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.state">
            Estado/Región
          </label>
          <input
            type="text"
            name="geographic_info.state"
            value={cliente.geographic_info.state}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.city">
            Ciudad
          </label>
          <input
            type="text"
            name="geographic_info.city"
            value={cliente.geographic_info.city}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="mb-4">
          <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor="geographic_info.postal_code">
            Código Postal
          </label>
          <input
            type="text"
            name="geographic_info.postal_code"
            value={cliente.geographic_info.postal_code}
            onChange={handleChange}
            className="shadow border rounded w-full py-2 px-3"
          />
        </div>
        <div className="flex justify-end">
          <button
            type="button"
            onClick={() => navigate(-1)}
            className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
          >
            Cancelar
          </button>
          <button
            type="submit"
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
          >
            Crear
          </button>
        </div>
      </form>
    </div>
  );
}

export default CrearCliente;
