// src/components/AccumulatePointsModal.jsx

import React, { useState, useEffect } from 'react';
import { get, patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const AccumulatePointsModal = ({
  ticketId,
  montoConsumido,
  setMontoConsumido,
  puntosMaximos,
  puntosAcumulados,
  onClose,
  onAccumulate,
  tipoFidelizacion,
  clienteId,
  productos,
}) => {
  const [selectedPoints, setSelectedPoints] = useState(1);
  const [puntosDisponibles, setPuntosDisponibles] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [totalWithDiscount, setTotalWithDiscount] = useState(0);

  useEffect(() => {
    if (tipoFidelizacion === 'PUNTOS') {
      const maxPuntosDisponibles = puntosMaximos - puntosAcumulados;
      const opciones = Array.from({ length: maxPuntosDisponibles }, (_, i) => i + 1);
      setPuntosDisponibles(opciones);
    }
  }, [puntosMaximos, puntosAcumulados, tipoFidelizacion]);

  useEffect(() => {
    if (selectedProducts.length > 0) {
      const total = selectedProducts.reduce((acc, curr) => {
        const producto = productos.find((p) => p._id === curr.productId);
        return acc + (producto?.precio || 0) * curr.cantidad;
      }, 0);
      setMontoConsumido(total);
    } else {
      setMontoConsumido(0);
    }
  }, [selectedProducts, productos, setMontoConsumido]);

  useEffect(() => {
    fetchDiscounts();
  }, []);

  useEffect(() => {
    updateTotalWithDiscount();
  }, [selectedProducts, selectedDiscounts, montoConsumido]);


  const fetchDiscounts = async () => {
    try {
      const response = await get(`rewards/available/${clienteId}`);

      // 🔹 Filtrar solo los descuentos antes de actualizar el estado
      const filteredDiscounts = response.filter(reward => {
        if (!reward.recompensa) return false; // Evitar errores si no hay recompensa

        const now = new Date();
        const isActive = reward.recompensa.activo ?? true;
        const isNotExpired =
          !reward.recompensa.fechaCaducidad || new Date(reward.recompensa.fechaCaducidad) > now;
        const isDiscountType =
          reward.recompensa.tipo?.trim().toUpperCase() === "DESCUENTO";

        return isActive && isNotExpired && isDiscountType;
      });

      setDiscounts(filteredDiscounts);
    } catch (error) {
      Swal.fire("Error", "No se pudieron cargar los descuentos.", "error");
    }
  };

  const handleMontoChange = (e) => {
    let value = e.target.value.replace(',', '.');
    setMontoConsumido(Number(value));
  };

  const handlePointsChange = (e) => {
    setSelectedPoints(Number(e.target.value));
  };

  const handleProductSelection = (productId) => {
    setSelectedProducts((prevSelected) => {
      const existingProduct = prevSelected.find((p) => p.productId === productId);
      if (existingProduct) {
        // Si el producto ya está seleccionado, lo deselecciona
        return prevSelected.filter((p) => p.productId !== productId);
      } else {
        // Si no está seleccionado, lo agrega con cantidad 1 por defecto
        return [...prevSelected, { productId, cantidad: 1 }];
      }
    });
  };

  const handleProductQuantityChange = (productId, cantidad) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.map((p) =>
        p.productId === productId ? { ...p, cantidad: Number(cantidad) } : p
      )
    );
  };

  const handleDiscountSelection = (discountId) => {
    const discount = discounts.find((d) => d._id === discountId);
    if (!discount) return;

    const { montoMinimo = 0 } = discount.recompensa;

    if (montoMinimo > montoConsumido) {
      Swal.fire("Restricción no cumplida", `El monto mínimo para este descuento es $${montoMinimo.toFixed(2)}`, "warning");
      return;
    }

    setSelectedDiscounts((prev) =>
      prev.includes(discountId)
        ? prev.filter((id) => id !== discountId)
        : [...prev, discountId]
    );
  };


  const handleAccumulate = async () => {
    const selectedRewardCodes = selectedDiscounts.map(discountId => {
      const discount = discounts.find(d => d._id === discountId);
      return discount ? discount._id : null;
    }).filter(Boolean);

    // Construir el array de productos con nombre y precio incluidos
    const formattedProducts = selectedProducts.map(product => {
      const productData = productos.find(p => p._id === product.productId);
      return {
        nombre: productData?.nombre || "Producto sin nombre",
        cantidad: product.cantidad,
        precio: productData?.precio || 0,
        descripcion: productData?.descripcion || "",
      };
    });

    try {
      if (selectedRewardCodes.length > 0) {
        // Si hay descuentos, aplicar los descuentos antes de acumular puntos
        await patch(`tickets/${ticketId}/apply-discount`, {
          ticketId,
          clienteId,
          descuentos: selectedRewardCodes,
          monto: montoConsumido, // Monto original sin descuento
          montoConDescuento: totalWithDiscount, // Monto después del descuento
          puntos: selectedPoints,
          productos: formattedProducts, // Enviar productos con nombre y precio
        });
      } else {
        // Si NO hay descuentos, solo acumular puntos normalmente
        await patch(`tickets/${ticketId}/accumulate`, {
          ticketId, // Asegurar que se envía correctamente
          monto: montoConsumido, // Monto sin descuentos
          puntos: selectedPoints,
          productos: formattedProducts, // Enviar productos con nombre y precio
        });
      }

      // Notificar éxito
      Swal.fire("Éxito", "Puntos acumulados correctamente.", "success");
      onAccumulate(selectedPoints, selectedProducts, selectedDiscounts, totalWithDiscount);
    } catch (error) {
      console.error("Error al acumular puntos:", error);
      Swal.fire("Error", "No se pudieron procesar los puntos o descuentos.", "error");
    }
  };

  const updateTotalWithDiscount = () => {
    let total = montoConsumido;

    selectedDiscounts.forEach(discountId => {
      const discount = discounts.find(d => d._id === discountId);
      if (discount) {
        const { cantidadMonetaria = 0, porcentaje = 0 } = discount.recompensa;
        total -= cantidadMonetaria;
        total -= (total * porcentaje) / 100;
      }
    });

    setTotalWithDiscount(Math.max(total, 0)); // Evitar valores negativos
  };


  return (
    <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center p-4">
      {/* Fondo oscuro */}
      <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
      {/* Contenido del modal */}
      <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:w-3/4 lg:w-1/2 z-20">
        <div className="px-4 py-5 sm:px-6">
          <h2 className="text-xl font-bold mb-4 text-center">Acumular Puntos</h2>
          {/* Selección de Productos */}
          <div className="mb-4">
            <label className="block mb-2">Selecciona Productos</label>
            <div className="max-h-48 overflow-y-auto border border-gray-300 rounded p-2">
              {productos && productos.length > 0 ? (
                productos.map((producto) => {
                  const isSelected = selectedProducts.find(
                    (p) => p.productId === producto._id
                  );
                  return (
                    <div key={producto._id} className="flex items-center mb-2">
                      <input
                        type="checkbox"
                        id={`product-${producto._id}`}
                        checked={isSelected ? true : false}
                        onChange={() => handleProductSelection(producto._id)}
                        className="mr-2"
                      />
                      <label htmlFor={`product-${producto._id}`} className="flex-1">
                        {producto.nombre} - ${producto.precio.toFixed(2)}
                      </label>
                      {isSelected && (
                        <input
                          type="number"
                          min="1"
                          value={isSelected.cantidad}
                          onChange={(e) =>
                            handleProductQuantityChange(producto._id, e.target.value)
                          }
                          className="w-16 p-1 border border-gray-300 rounded"
                        />
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-500">No hay productos disponibles.</p>
              )}
            </div>
          </div>
          {/* Descuentos Disponibles */}
          <div className="mb-4">
            <label className="block text-lg font-semibold mb-2">Selecciona Descuentos</label>

            <div className="max-h-48 overflow-y-auto border border-gray-300 rounded p-3">
              {discounts.length > 0 ? (
                discounts.map((discount) => {

                  const { _id, recompensa } = discount;
                  const {
                    nombre,
                    cantidadMonetaria = 0,
                    porcentaje = 0,
                    fechaCaducidad,
                    currency = "EUR",
                    montoMinimo = 0,
                  } = recompensa;

                  return (
                    <div key={_id} className="flex flex-col border-b pb-2 mb-2">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id={`discount-${_id}`}
                          checked={selectedDiscounts.includes(_id)}
                          onChange={() => handleDiscountSelection(_id)}
                          className="mr-2"
                          disabled={montoMinimo > montoConsumido}
                        />

                        <label htmlFor={`discount-${_id}`} className="flex-1 text-base font-medium">
                          {nombre} -{" "}
                          {cantidadMonetaria > 0
                            ? `💰 ${currency} ${cantidadMonetaria.toFixed(2)}`
                            : `💸 ${porcentaje}% Descuento`}
                        </label>
                      </div>

                      {/* Monto mínimo requerido */}
                      {montoMinimo > 0 && (
                        <p className="text-sm text-gray-600 ml-6">
                          * Monto mínimo: ${montoMinimo.toFixed(2)}
                        </p>
                      )}

                      {/* Fecha de caducidad si está cerca */}
                      {fechaCaducidad && (
                        <p
                          className={`text-sm ml-6 ${new Date(fechaCaducidad) < new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                            ? "text-red-500"
                            : "text-gray-500"
                            }`}
                        >
                          Expira el {new Date(fechaCaducidad).toLocaleDateString()}{" "}
                          {new Date(fechaCaducidad) < new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
                            ? "(Pronto expira)"
                            : ""}
                        </p>
                      )}
                    </div>
                  );
                })
              ) : (
                <p className="text-gray-500 text-center">No hay descuentos disponibles.</p>
              )}
            </div>
          </div>


          {/* Monto Consumido y Total con Descuento */}
          <div className="mb-4">
            <label className="block mb-2">Monto Consumido</label>
            <input
              type="number"
              value={montoConsumido}
              onChange={handleMontoChange}
              className="p-2 border border-gray-300 rounded w-full"
            />
            <p className="text-green-600 mt-2">Total con descuento: ${totalWithDiscount.toFixed(2)}</p>
          </div>
          {/* Puntos a Acumular */}
          <div className="mb-4">
            <label className="block mb-2">Puntos a Acumular</label>
            {tipoFidelizacion === 'PUNTOS' ? (
              <select
                value={selectedPoints}
                onChange={handlePointsChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
              >
                {puntosDisponibles.map((punto, index) => (
                  <option key={index} value={punto}>
                    {punto} puntos
                  </option>
                ))}
              </select>
            ) : (
              <input
                type="number"
                value={selectedPoints}
                onChange={handlePointsChange}
                className="mb-4 p-2 border border-gray-300 rounded w-full"
                min="1"
              />
            )}
          </div>
          {/* Botones de Acción */}
          <div className="flex justify-end">
            <button
              className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
              onClick={onClose}
            >
              Cancelar
            </button>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700"
              onClick={handleAccumulate}
              disabled={
                (tipoFidelizacion === 'PUNTOS' && selectedPoints > puntosDisponibles.length) ||
                montoConsumido <= 0
              }
            >
              Acumular
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccumulatePointsModal;
