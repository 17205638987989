import React from 'react';
import WhatsAppConfigForm from '../../components/communication/WhatsAppConfigForm';

const WhatsAppConfigurationPage = () => {
    return (
        <div className="min-h-screen bg-gray-100 flex items-center justify-center p-4">
            <WhatsAppConfigForm />
        </div>
    );
};

export default WhatsAppConfigurationPage;
