// src/pages/tienda/TiendasPage.jsx
import React, { useState, useEffect } from 'react';
import { get, post, del } from '../../helpers/apiHelper';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import TrabajadorModal from '../../components/modals/TrabajadorModal';
import CreateStoreModal from '../../components/modals/CreateStoreModal';
import QrPdfModal from '../../components/modals/QrPdfModal';
import useAuthStore from '../../store/authStore';

function TiendasPage() {
  const { user } = useAuthStore();
  const [tiendas, setTiendas] = useState([]);
  const [marcas, setMarcas] = useState([]);
  const [showStoreModal, setShowStoreModal] = useState(false);
  const [showTrabajadorModal, setShowTrabajadorModal] = useState(false);
  const [showQrModal, setShowQrModal] = useState(false);
  const [selectedTiendaId, setSelectedTiendaId] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetchTiendas();
    fetchMarcas();
  }, []);

  const fetchTiendas = async () => {
    try {
      const response = await get('tiendas');
      setTiendas(response);
    } catch (error) {
      console.error('Error fetching tiendas:', error);
    }
  };

  const fetchMarcas = async () => {
    try {
      const response = await get('marcas');
      setMarcas(response);
    } catch (error) {
      console.error('Error fetching marcas:', error);
    }
  };

  const openStoreModal = () => setShowStoreModal(true);
  const closeStoreModal = () => setShowStoreModal(false);

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar!',
      cancelButtonText: 'Cancelar'
    });
    if (result.isConfirmed) {
      try {
        await del(`tiendas/${id}`);
        fetchTiendas();
        Swal.fire('Eliminada!', 'La tienda ha sido eliminada.', 'success');
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    }
  };

  const handleViewClientes = (tiendaId) => {
    navigate(`/tiendas/${tiendaId}/clientes`);
  };

  const handleCreateCliente = (tiendaId) => {
    navigate(`/tiendas/${tiendaId}/clientes/create`);
  };

  const handleShowTrabajadorModal = (tiendaId) => {
    setSelectedTiendaId(tiendaId);
    setShowTrabajadorModal(true);
  };

  // Abre el modal para ver/descargar QR y PDF
  const handleShowQrModal = (tiendaId) => {
    setSelectedTiendaId(tiendaId);
    setShowQrModal(true);
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h1 className="text-2xl md:text-4xl font-bold mb-4">Tiendas</h1>
      <p className="text-lg mb-6">
        Gestiona tus tiendas y accede a herramientas para aumentar la captación de clientes.
      </p>
      <button
        className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 mb-6"
        onClick={openStoreModal}
      >
        Crear Tienda
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {tiendas.map((tienda) => (
          <div key={tienda._id} className="border p-4 rounded-lg shadow-sm flex flex-col">
            <h2 className="text-xl font-bold mb-2">{tienda.nombre}</h2>
            <p className="mb-2">{tienda.descripcion}</p>
            <p className="text-sm mb-4">
              <strong>Dirección:</strong> {tienda.via} {tienda.calle}, {tienda.numero}, {tienda.poblacion}, {tienda.pais}, {tienda.region}
            </p>
            <div className="flex flex-wrap gap-2 mb-4">
              <button
                className="bg-green-500 text-white text-sm px-3 py-2 rounded hover:bg-green-700 flex-1"
                onClick={() => handleViewClientes(tienda._id)}
              >
                Ver Clientes
              </button>
              <button
                className="bg-yellow-500 text-white text-sm px-3 py-2 rounded hover:bg-yellow-700 flex-1"
                onClick={() => handleCreateCliente(tienda._id)}
              >
                Crear Cliente
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2 mb-4">
              <button
                className="bg-blue-600 text-white text-sm px-3 py-2 rounded hover:bg-blue-700"
                onClick={() => handleShowTrabajadorModal(tienda._id)}
              >
                Añadir Trabajador
              </button>
              <button
                className="bg-indigo-600 text-white text-sm px-3 py-2 rounded hover:bg-indigo-700"
                onClick={() => handleShowQrModal(tienda._id)}
              >
                QR/PDF
              </button>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <button
                className="bg-red-600 text-white text-sm px-3 py-2 rounded hover:bg-red-700"
                onClick={() => handleDelete(tienda._id)}
              >
                Eliminar
              </button>
            </div>
          </div>
        ))}
      </div>
      {
        showStoreModal && (
          <CreateStoreModal
            isOpen={showStoreModal}
            onClose={closeStoreModal}
            onSubmit={(data) => {
              post('tiendas', { ...data, usuario: user.userId })
                .then(() => {
                  fetchTiendas();
                  closeStoreModal();
                  Swal.fire('Éxito', 'Tienda creada correctamente', 'success');
                })
                .catch((error) => {
                  Swal.fire('Error', error.message, 'error');
                });
            }}
            marcas={marcas}
          />
        )
      }
      <TrabajadorModal
        show={showTrabajadorModal}
        onClose={() => setShowTrabajadorModal(false)}
        tiendaId={selectedTiendaId}
      />
      {
        showQrModal && (
          <QrPdfModal tiendaId={selectedTiendaId} onClose={() => setShowQrModal(false)} />
        )
      }
    </div >
  );
}

export default TiendasPage;
