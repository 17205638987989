// src/pages/communication/SendMessagePage.jsx
import React from 'react';
import MessageForm from '../../components/communication/MessageForm';
import SegmentSelector from '../../components/communication/SegmentSelector';
import BackToCommunication from '../../components/communication/BackToCommunication';

function SendMessagePage() {
    return (
        <div>
            <BackToCommunication />
            <h1 className="text-2xl font-bold mb-4">Enviar Mensajes</h1>
            <SegmentSelector />
            <MessageForm />
        </div>
    );
}

export default SendMessagePage;