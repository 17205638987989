// src/components/feedback/OpenTextResponses.jsx
import React from 'react';

const OpenTextResponses = ({ responses }) => {
  // Agrupar las respuestas por pregunta
  const groupedResponses = responses.reduce((acc, response) => {
    acc[response.question] = acc[response.question] || [];
    acc[response.question].push(response.answer);
    return acc;
  }, {});

  return (
    <div className="bg-gray-100 p-4 md:p-5 rounded-lg my-5">
      <h2 className="text-xl md:text-2xl font-semibold mb-4">Respuestas Abiertas</h2>
      {Object.entries(groupedResponses).map(([question, answers], index) => (
        <div key={index} className="mb-4">
          {/* break-words en la pregunta */}
          <p className="font-bold mb-2 break-words whitespace-normal">{question}</p>
          {answers.map((answer, idx) => (
            // break-words y whitespace-normal para que no fuerce ancho
            <p
              key={idx}
              className="pl-4 md:pl-5 text-justify text-sm md:text-base break-words whitespace-normal"
            >
              {answer}
            </p>
          ))}
        </div>
      ))}
    </div>
  );
};

export default OpenTextResponses;
