import React from 'react';
import { Helmet } from 'react-helmet';
import raul from "../../assets/raul.png";
import luis from "../../assets/luis.png";
import koda from "../../assets/koda.png";

const AboutPage = () => {
    return (
        <div className="min-h-screen bg-white text-gray-800">
            <Helmet>
                <title>
                    Acerca de PetitPrime – Empresa de Fidelización y Equipo de Software de Lealtad
                </title>
                <meta
                    name="description"
                    content="Conoce la historia, misión y el equipo detrás de PetitPrime, el ecosistema de fidelización digital que transforma la interacción con tus clientes. Innovación, compromiso y excelencia en cada solución."
                />
                <meta
                    name="keywords"
                    content="empresa de fidelización, equipo de software de lealtad, PetitPrime, fidelización digital"
                />
            </Helmet>
            <header className="bg-blue-600 text-white py-8 text-center">
                <h1 className="text-4xl font-bold">Acerca de PetitPrime</h1>
            </header>
            <main className="container mx-auto px-4 py-8">
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Nuestra Historia</h2>
                    <p>
                        PetitPrime es un ecosistema de fidelización digital de clientes que nace en 2020 con el objetivo de ayudar a las empresas a fidelizar a sus clientes y aumentar sus ventas. Durante el inicio del covid en 2021, surgió la necesidad de apoyar a pequeños y medianos comercios a recuperar su lugar en el mercado, lo que nos impulsó a desarrollar una solución omnicanal, personalizada y automatizada.
                    </p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Nuestra Misión y Visión</h2>
                    <p>
                        Nuestra misión es ofrecer una solución integral de fidelización que permita a las empresas crear programas de lealtad efectivos, impulsando la interacción y la fidelidad del cliente. Nos comprometemos con la innovación y la excelencia en el servicio, siempre buscando superar las expectativas de nuestros clientes.
                    </p>
                </section>
                <section className="mb-8">
                    <h2 className="text-2xl font-semibold mb-4">Nuestro Equipo</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="flex flex-col items-center p-4 border rounded shadow">
                            <img
                                src={luis}
                                alt="Luis Meza"
                                className="w-32 h-32 object-cover rounded-full mb-4"
                            />
                            <h3 className="text-xl font-bold">LUIS MEZA</h3>
                            <p className="text-center">
                                Master en Gestión de Empresas y especialista en Inteligencia Artificial. Ha implementado soluciones de IA para grandes compañías como Movistar, Universidad del Pacífico, Goldman Sachs y USAID.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-4 border rounded shadow">
                            <img
                                src={raul}
                                alt="Raül de Arriba"
                                className="w-32 h-32 object-cover rounded-full mb-4"
                            />
                            <h3 className="text-xl font-bold">RAÜL DE ARRIBA</h3>
                            <p className="text-center">
                                Ingeniero informático con más de 10 años de experiencia en desarrollo web, móvil, asistencia técnica y ciberseguridad. Ha colaborado con Logicalis Spain y la Universidad Autónoma de Barcelona.
                            </p>
                        </div>
                        <div className="flex flex-col items-center p-4 border rounded shadow">
                            <img
                                src={koda}
                                alt="Koda"
                                className="w-32 h-32 object-cover rounded-full mb-4"
                            />
                            <h3 className="text-xl font-bold">KODA</h3>
                            <p className="text-center">
                                El inseparable compañero de Raül, un border collie que aporta un toque humano y cercano en cada reunión.
                            </p>
                        </div>
                    </div>
                </section>
            </main>
            <footer className="bg-gray-200 text-center py-4">
                <p>
                    Únete a nosotros y sé parte de la transformación en fidelización digital.{" "}
                    <a href="/register" className="text-blue-600 font-bold">
                        Regístrate ahora
                    </a>.
                </p>
            </footer>
        </div>
    );
};

export default AboutPage;
