// src/components/clients/CustomerSegmentationFilter.jsx
import React from 'react';
import Select from 'react-select';

// Estilos personalizados para react-select que aplican el color a cada etiqueta
const customStyles = {
  container: (provided) => ({ ...provided, width: '100%' }),
  multiValue: (provided, state) => ({
    ...provided,
    backgroundColor: state.data.color || '#777',
  }),
  multiValueLabel: (provided) => ({ ...provided, color: 'white' }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

const CustomerSegmentationFilter = ({ onFilter, options }) => {
  const handleChange = (selectedOptions) => {
    // Si no se selecciona nada, se envía "All"
    if (!selectedOptions || selectedOptions.length === 0) {
      onFilter('All');
      return;
    }
    // Se obtienen los valores de cada opción
    const selectedTags = selectedOptions.map((option) => option.value);
    // Envía una cadena separada por comas (o puedes enviar el array directamente si prefieres)
    onFilter(selectedTags.join(','));
  };

  return (
    <div className="mb-4">
      <label className="block text-sm font-medium text-gray-700">
        Filtrar por Etiqueta
      </label>
      <Select
        isMulti
        options={options}
        onChange={handleChange}
        placeholder="Seleccione etiquetas..."
        styles={customStyles}
      />
    </div>
  );
};

export default CustomerSegmentationFilter;
