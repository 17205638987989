// src/pages/ClientesList.jsx (Adaptada para Trabajador)
import React, { useState, useEffect } from 'react';
import { get, patch } from '../../helpers/apiHelper';
import { useParams, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import Pagination from '../../components/common/Pagination';
import { FaEdit, FaStar } from 'react-icons/fa';
import EditClienteModal from '../../components/modals/EditClienteModal';
import AccumulatePointsModal from '../../components/modals/AccumulatePointsModal';
import RedeemPremiosModal from '../../components/modals/RedeemPremiosModal';
import RedeemRewardButton from '../../components/rewards/RedeemRewardButton';


function ClientesList() {
  const { tiendaId, trabajadorId } = useParams();
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [clientsPerPage] = useState(10);
  const [editingCliente, setEditingCliente] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showAccumulateModal, setShowAccumulateModal] = useState(false);
  const [showRedeemModal, setShowRedeemModal] = useState(false);
  const [montoConsumido, setMontoConsumido] = useState(0);
  const [selectedCliente, setSelectedCliente] = useState(null);
  const [productos, setProductos] = useState([]);
  const [loadingProductos, setLoadingProductos] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchClientes();
    fetchProductos();
  }, []);

  useEffect(() => {
    handleSearch();
  }, [searchTerm, clientes]);

  const fetchClientes = async () => {
    try {
      const response = await get(`clientes/tienda/${tiendaId}`);
      setClientes(response);
      setFilteredClientes(response);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const fetchProductos = async () => {
    setLoadingProductos(true);
    try {
      const response = await get(`productos?tiendaId=${tiendaId}&disponible=true`);
      setProductos(response);
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    } finally {
      setLoadingProductos(false);
    }
  };

  const handleSearch = () => {
    const results = clientes.filter(cliente =>
      cliente.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cliente.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
      cliente.telefono.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClientes(results);
  };

  const handleCreateCliente = () => {
    if (trabajadorId) {
      navigate(`/trabajador/${trabajadorId}/tiendas/${tiendaId}/clientes/create`);
    } else {
      navigate(`/tiendas/${tiendaId}/clientes/create`);
    }
  };

  const handleEditCliente = (cliente) => {
    setEditingCliente(cliente);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setEditingCliente(null);
    setShowModal(false);
  };

  const handleSaveCliente = async (updatedCliente) => {
    try {
      const { _id, ...data } = updatedCliente;
      await patch(`clientes/${_id}`, data);
      fetchClientes();
      handleCloseModal();
      Swal.fire({
        title: 'Éxito',
        text: 'Cliente actualizado correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleAccumulatePoints = () => {
    fetchClientes(); // ✅ Actualiza la lista de clientes sin hacer llamadas al backend
    handleCloseAccumulateModal(); // ✅ Cierra el modal
  }

  const handleRedeemPoints = async (cliente) => {
    try {
      await patch(`tickets/${cliente.tickets[cliente.tickets.length - 1]._id}/redeem`);
      fetchClientes();
      handleCloseAccumulateModal();
      Swal.fire({
        title: 'Éxito',
        text: 'Puntos canjeados correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar'
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleOpenAccumulateModal = (cliente) => {
    try {
      const lastTicket = cliente.tickets[cliente.tickets.length - 1];
      setSelectedCliente({ ...cliente, ticket: lastTicket });
      setShowAccumulateModal(true);
    } catch (error) {
      console.error('Error handling ticket:', error);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleOpenRedeemModal = (cliente) => {
    try {
      const lastTicket = cliente.tickets[cliente.tickets.length - 1];
      setSelectedCliente({ ...cliente, ticket: lastTicket });
      setShowRedeemModal(true);
    } catch (error) {
      console.error('Error handling ticket:', error);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const handleCloseRedeemModal = () => {
    setSelectedCliente(null);
    setShowRedeemModal(false);
  };

  const handleCloseAccumulateModal = () => {
    setSelectedCliente(null);
    setMontoConsumido(0);
    setShowAccumulateModal(false);
  };

  const handleRedeemSuccess = () => {
    setSelectedCliente(null);
    setShowRedeemModal(false);
    fetchClientes();
  };


  const handleViewHistory = (cliente) => {
    const clienteId = cliente._id;
    navigate(`/history/${clienteId}`);
  };

  const handleViewPrizeHistory = (cliente) => {
    const clienteId = cliente._id;
    navigate(`/canjeos/${clienteId}`);
  };

  // Obtener los clientes actuales
  const indexOfLastClient = currentPage * clientsPerPage;
  const indexOfFirstClient = indexOfLastClient - clientsPerPage;
  const currentClients = filteredClientes.slice(indexOfFirstClient, indexOfLastClient);

  // Cambiar de página
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="flex flex-col min-h-screen bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h1 className="text-4xl font-bold mb-4">Clientes</h1>
      <button
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mb-4 mr-4"
        onClick={handleCreateCliente}
      >
        Crear Cliente
      </button>
      <input
        type="text"
        placeholder="Buscar cliente"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        className="mb-4 p-2 border border-gray-300 rounded"
      />
      {loadingProductos && (
        <p className="mb-4 text-gray-500">Cargando productos...</p>
      )}
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
        {currentClients.map((cliente) => (
          <div key={cliente._id} className="border p-4 rounded mb-4 relative">
            <h2 className="text-2xl font-bold">{cliente.nombre} {cliente.apellido}</h2>
            <p>Email: {cliente.email}</p>
            <p>Teléfono: {cliente.telefono}</p>
            <p>Fecha de Fidelización: {new Date(cliente.fechaCreacion).toLocaleDateString()}</p>
            <p>Fecha de Cumpleaños: {new Date(cliente.fechaCumpleanos).toLocaleDateString()}</p>
            {cliente.tickets.length > 0 && cliente.tickets[cliente.tickets.length - 1] && (
              cliente.tickets[cliente.tickets.length - 1].tipoFidelizacion === 'PUNTOS' ? (
                <div className="flex space-x-1">
                  {[...Array(cliente.tickets[cliente.tickets.length - 1].puntos)].map((_, i) => (
                    <FaStar key={i} className="text-yellow-500" />
                  ))}
                  {[...Array(cliente.marca.puntosTicket - cliente.tickets[cliente.tickets.length - 1].puntos)].map((_, i) => (
                    <FaStar key={i} className="text-gray-300" />
                  ))}
                </div>
              ) : (
                <div>
                  <p>Puntos Acumulados: {cliente.tickets[cliente.tickets.length - 1].puntos}</p>
                  <p>Puntos Consumidos: {cliente.tickets[cliente.tickets.length - 1].acumulado - cliente.tickets[cliente.tickets.length - 1].puntos}</p>                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mt-2"
                    onClick={() => handleOpenRedeemModal(cliente)}
                  >
                    Canjear Puntos
                  </button>
                </div>
              )
            )}
            <button
              className="absolute top-2 right-2 text-gray-500 hover:text-gray-700"
              onClick={() => handleEditCliente(cliente)}
            >
              <FaEdit size={20} />
            </button>
            {cliente.tickets.length > 0 && cliente.tickets[cliente.tickets.length - 1] && (
              cliente.tickets[cliente.tickets.length - 1].tipoFidelizacion === 'PUNTOS' ? (
                cliente.tickets[cliente.tickets.length - 1].puntos >= cliente.marca.puntosTicket ? (
                  <>
                    <button
                      className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700 mt-2"
                      onClick={() => handleRedeemPoints(cliente)}
                    >
                      Canjear Tarjeta
                    </button>
                    <RedeemRewardButton clienteId={cliente._id} recompensaId={cliente.recompensaId} />
                  </>
                ) : (
                  <div>

                    <button
                      className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mt-2"
                      onClick={() => handleOpenAccumulateModal(cliente)}
                    >
                      Acumular Puntos
                    </button>

                    <RedeemRewardButton clienteId={cliente._id} recompensaId={cliente.recompensaId} />

                  </div>
                )
              ) : (
                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mt-2"
                  onClick={() => handleOpenAccumulateModal(cliente)}
                >
                  Acumular Puntos
                </button>
              )
            )}

            {/* Botón Condicional para Ver Historial de Canjeos de Premios */}
            {cliente.tickets.length > 0 && cliente.tickets[cliente.tickets.length - 1] && (
              cliente.tickets[cliente.tickets.length - 1].tipoFidelizacion === 'ACUMULACION' && (
                <button
                  className="bg-purple-500 text-white px-4 py-2 rounded hover:bg-purple-700 mt-2 ml-2"
                  onClick={() => handleViewPrizeHistory(cliente)}
                >
                  Ver Historial de Canjeos
                </button>
              )
            )}


            {!trabajadorId && (
              <button
                className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700 mt-2"
                onClick={() => handleViewHistory(cliente)}
              >
                Ver Historial de puntos
              </button>
            )}
          </div>
        ))}
      </div>
      <Pagination
        clientsPerPage={clientsPerPage}
        totalClients={filteredClientes.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      {showModal && (
        <EditClienteModal
          cliente={editingCliente}
          onClose={handleCloseModal}
          onSave={handleSaveCliente}
        />
      )}

      {showRedeemModal && selectedCliente && (
        <RedeemPremiosModal
          cliente={selectedCliente}
          onClose={handleCloseRedeemModal}
          trabajadorId={trabajadorId}
          onRedeemSuccess={handleRedeemSuccess}
        />
      )}

      {showAccumulateModal && selectedCliente && (
        <AccumulatePointsModal
          ticketId={selectedCliente.ticket._id}
          montoConsumido={montoConsumido}
          setMontoConsumido={setMontoConsumido}
          puntosMaximos={selectedCliente.marca.puntosTicket}
          puntosAcumulados={selectedCliente.ticket.puntos}
          onClose={handleCloseAccumulateModal}
          onAccumulate={handleAccumulatePoints}
          onRedeem={handleRedeemPoints}
          tipoFidelizacion={selectedCliente.ticket.tipoFidelizacion}
          clienteId={selectedCliente._id}
          productos={productos}
        />
      )}
    </div>
  );
}

export default ClientesList;
