// src/components/modals/DiscountAppliedModal.jsx
import React from 'react';

const DiscountAppliedModal = ({ montoFinal, descuentosAplicados, onClose }) => {
    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center p-4">
            <div className="fixed inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="bg-white p-6 rounded-lg shadow-lg z-10 max-w-sm w-full">
                <h2 className="text-xl font-semibold text-center mb-4">Descuento Aplicado</h2>
                <p className="text-gray-700">Se ha aplicado un descuento a esta transacción.</p>
                <ul className="mt-3">
                    {descuentosAplicados.map((desc, index) => (
                        <li key={index} className="text-gray-600">
                            - {desc.nombre}: {desc.porcentaje > 0 ? `${desc.porcentaje}%` : `💰 ${desc.cantidadMonetaria.toFixed(2)} EUR`}
                        </li>
                    ))}
                </ul>
                <p className="text-lg font-semibold mt-4">Monto final: ${montoFinal.toFixed(2)}</p>
                <button
                    className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 w-full"
                    onClick={onClose}
                >
                    Aceptar
                </button>
            </div>
        </div>
    );
};

export default DiscountAppliedModal;
