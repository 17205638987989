// src/pages/gameLoyalty/CampaignDetailPage.jsx

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import SendParticipationCode from '../../components/gameLoyalty/SendParticipationCode';
import jsPDF from 'jspdf';
import EmbedCodeModal from '../../components/gameLoyalty/GameLoyaltyEmbed';

/**
 * Versión "solo para móvil" (o muy simplificada).
 * Se reemplaza la tabla por una lista vertical de tarjetas 
 * para las participaciones, evitando overflow horizontal.
 */
function CampaignDetailPage() {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [participations, setParticipations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isEmbedModalOpen, setIsEmbedModalOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchCampaignDetails();
        fetchParticipations();
        // eslint-disable-next-line
    }, [campaignId]);

    // Obtener detalles de la campaña
    const fetchCampaignDetails = async () => {
        try {
            const data = await get(`game-loyalty/${campaignId}`);
            setCampaign(data);
        } catch (error) {
            console.error('Error al obtener los detalles de la campaña:', error);
            Swal.fire('Error', error.message, 'error');
        }
    };

    // Obtener participaciones
    const fetchParticipations = async () => {
        setLoading(true);
        try {
            const data = await get(`game-loyalty/${campaignId}/participations`);
            setParticipations(data);
        } catch (error) {
            console.error('Error al obtener las participaciones:', error);
            Swal.fire('Error', error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    // Conteo de canjeados
    const redeemedCount = participations.filter((p) => p.redeemed).length;

    // Generar PDF con link de canje
    const downloadPDF = () => {
        const redemptionUrl = `${window.location.origin}/game-loyalty/public/${campaignId}`;
        const doc = new jsPDF();
        doc.setFontSize(16);
        doc.text('Enlace para Canjear Puntos', 20, 20);
        doc.setFontSize(12);
        doc.text('Utiliza el siguiente enlace para canjear tus puntos:', 20, 30);
        doc.text(redemptionUrl, 20, 40);
        doc.text(
            'Copia y comparte este enlace para que tus clientes puedan canjear sus códigos.',
            20,
            50
        );
        doc.save('enlace_canjeo.pdf');
    };

    // Renderizar tarjetas de participaciones (en lugar de tabla)
    const renderParticipationCards = () => {
        if (loading) {
            return <p className="text-gray-600">Cargando participaciones...</p>;
        }
        if (!participations.length) {
            return <p className="text-gray-600">No hay participaciones registradas.</p>;
        }
        return (
            <div className="space-y-3">
                {participations.map((p, idx) => (
                    <div
                        key={idx}
                        className="rounded-md bg-gray-50 border border-gray-200 p-3 text-sm sm:text-base"
                    >
                        <p className="mb-1">
                            <strong>Email:</strong> {p.email}
                        </p>
                        <p className="mb-1 break-words">
                            <strong>Código:</strong> {p.code}
                        </p>
                        <p className="mb-1">
                            <strong>Canjeado:</strong> {p.redeemed ? 'Sí' : 'No'}
                        </p>
                        <p className="mb-1 break-words">
                            <strong>Fecha de Canje:</strong>{' '}
                            {p.redeemedAt ? new Date(p.redeemedAt).toLocaleString() : '-'}
                        </p>
                        <p className="break-words">
                            <strong>Premio Obtenido:</strong>{' '}
                            {p.prizeWon
                                ? `${p.prizeWon.nombre} – ${p.prizeWon.descripcion}`
                                : '-'}
                        </p>
                    </div>
                ))}
            </div>
        );
    };

    return (
        <div className="min-h-screen bg-gray-50 overflow-x-hidden py-4 px-4 sm:px-6">
            <div className="max-w-screen-lg mx-auto bg-white rounded-lg shadow-md p-4 sm:p-6">
                {campaign ? (
                    <div className="space-y-4">
                        <h1 className="text-2xl sm:text-3xl font-bold mb-2 text-gray-800">
                            Detalles de la Campaña: {campaign.nombre}
                        </h1>

                        {/* Descripción de la campaña */}
                        {campaign.descripcion && (
                            <p className="text-sm sm:text-base text-gray-700">
                                {campaign.descripcion}
                            </p>
                        )}

                        {/* Marca asociada */}
                        {campaign.marcaNombre && (
                            <p className="text-sm sm:text-base text-gray-700">
                                Promoción ofrecida por:{' '}
                                <strong>{campaign.marcaNombre}</strong>
                            </p>
                        )}

                        {/* Botones de acción */}
                        <div className="flex flex-col sm:flex-row gap-3 sm:gap-4 sm:items-center sm:justify-between mt-4">
                            <button
                                onClick={() => navigate('/game-loyalty/manage')}
                                className="bg-gray-500 hover:bg-gray-600 text-white px-4 py-2 rounded text-sm"
                            >
                                Volver a Gestión de Campañas
                            </button>
                            <button
                                onClick={downloadPDF}
                                className="bg-red-600 hover:bg-red-700 text-white px-4 py-2 rounded text-sm"
                            >
                                Descargar PDF de la campaña
                            </button>
                            <button
                                onClick={() => setIsEmbedModalOpen(true)}
                                className="bg-indigo-600 hover:bg-indigo-700 text-white px-4 py-2 rounded text-sm"
                            >
                                Obtener Código Embebible
                            </button>
                        </div>

                        {/* Modal para código embebible */}
                        {isEmbedModalOpen && (
                            <EmbedCodeModal
                                campaignId={campaignId}
                                onClose={() => setIsEmbedModalOpen(false)}
                            />
                        )}

                        {/* Enviar código de participación */}
                        <div className="p-4 bg-gray-50 border border-gray-200 rounded">
                            <h2 className="text-lg sm:text-xl font-semibold mb-2">
                                Enviar Código de Participación
                            </h2>
                            <p className="text-xs sm:text-sm text-gray-600 mb-2">
                                Introduzca el correo del cliente para enviarle un código de participación.
                            </p>
                            <SendParticipationCode campaignId={campaignId} />
                        </div>

                        {/* Estadísticas de la campaña */}
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <p className="text-sm sm:text-base text-gray-800">
                                    <strong>Ámbito:</strong> {campaign.scope}
                                </p>
                                <p className="text-sm sm:text-base text-gray-800">
                                    <strong>Participaciones Totales:</strong>{' '}
                                    {campaign.totalParticipations}
                                </p>
                            </div>
                            <div>
                                <p className="text-sm sm:text-base text-gray-800">
                                    <strong>Participaciones Registradas:</strong>{' '}
                                    {participations.length}
                                </p>
                                <p className="text-sm sm:text-base text-gray-800">
                                    <strong>Participaciones Canjeadas:</strong>{' '}
                                    {redeemedCount}
                                </p>
                            </div>
                        </div>

                        {/* Niveles de Premio */}
                        <h2 className="text-xl sm:text-2xl font-semibold mt-4">Niveles de Premio</h2>
                        <div className="space-y-2">
                            {campaign.prizeLevels.map((level, idx) => {
                                const awardedCount = participations.filter(
                                    (p) => p.prizeWon && p.prizeWon.nombre === level.nombre
                                ).length;
                                return (
                                    <div
                                        key={idx}
                                        className="p-3 bg-gray-100 border border-gray-200 rounded"
                                    >
                                        <p className="text-sm sm:text-base text-gray-800">
                                            <strong>{level.nombre}</strong> – {level.descripcion}
                                        </p>
                                        <p className="text-xs sm:text-sm text-gray-600 mt-1">
                                            Asignados: {awardedCount} / {level.cantidad}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>

                        {/* Participaciones (UI móvil: tarjetas en lista) */}
                        <h2 className="text-xl sm:text-2xl font-semibold mt-4">
                            Participaciones
                        </h2>
                        {renderParticipationCards()}
                    </div>
                ) : (
                    <p className="text-gray-600">Cargando detalles de la campaña...</p>
                )}
            </div>
        </div>
    );
}

export default CampaignDetailPage;
