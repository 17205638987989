// src/components/referralCampaign/CreateReferralCampaignModal.jsx
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { post } from '../../helpers/apiHelper';

const campaignTypes = [
    { value: 'TraeUnAmigo', label: 'Trae un amigo, ambos ganan' },
    { value: 'ProgramaEscalonado', label: 'Programa de recompensas escalonadas' },
    { value: 'ReferidosConTiempoLimite', label: 'Referidos con tiempo límite' },
    { value: 'AccesoExclusivo', label: 'Acceso exclusivo por referir' },
    { value: 'ContigoYConTusAmigos', label: 'Contigo y con tus amigos' },
];

const CreateReferralCampaignModal = ({ isOpen, onClose, onCampaignCreated, brands }) => {
    const initialFormState = {
        nombre: '',
        descripcion: '',
        marca: '',
        fechaInicio: '',
        fechaFin: '',
        tipo: 'TraeUnAmigo',
        // Recompensas para referidor y referido
        rewardReferidor: { rewardType: '', nombre: '', descripcion: '', porcentaje: '', puntos: '', currency: '' },
        rewardReferido: { rewardType: '', nombre: '', descripcion: '', porcentaje: '', puntos: '', currency: '' },
        // Para ProgramaEscalonado
        rewardTiersReferidor: [],
        // Para ReferidosConTiempoLimite
        bonusRewardReferidor: { rewardType: '', nombre: '', descripcion: '', porcentaje: '', puntos: '', currency: '' },
        bonusDeadline: '',
        // Para AccesoExclusivo
        umbralAccesoExclusivo: '',
        exclusiveReward: { rewardType: '', nombre: '', descripcion: '', porcentaje: '', puntos: '', currency: '' },
        // Para ContigoYConTusAmigos
        groupThreshold: '',
        groupReward: { rewardType: '', nombre: '', descripcion: '', porcentaje: '', puntos: '', currency: '' },
    };

    const [formData, setFormData] = useState(initialFormState);

    useEffect(() => {
        if (isOpen) {
            setFormData(initialFormState);
        }
    }, [isOpen]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleRewardChange = (field, subfield, value) => {
        setFormData(prev => ({
            ...prev,
            [field]: { ...prev[field], [subfield]: value }
        }));
    };

    // Manejo de niveles para ProgramaEscalonado
    const addRewardTier = () => {
        setFormData(prev => ({
            ...prev,
            rewardTiersReferidor: [...prev.rewardTiersReferidor, { threshold: '', rewardType: '', nombre: '', descripcion: '', porcentaje: '', puntos: '', currency: '' }]
        }));
    };

    const handleTierChange = (index, subfield, value) => {
        const updatedTiers = formData.rewardTiersReferidor.map((tier, i) => i === index ? { ...tier, [subfield]: value } : tier);
        setFormData(prev => ({ ...prev, rewardTiersReferidor: updatedTiers }));
    };

    const removeTier = (index) => {
        const updatedTiers = formData.rewardTiersReferidor.filter((_, i) => i !== index);
        setFormData(prev => ({ ...prev, rewardTiersReferidor: updatedTiers }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            await post('referral-campaign', formData);
            Swal.fire('Éxito', 'Campaña creada correctamente', 'success');
            onCampaignCreated();
            onClose();
        } catch (error) {
            console.error('Error al crear campaña:', error);
            Swal.fire('Error', error.message, 'error');
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
            <div className="bg-white rounded shadow-lg z-10 w-full max-w-3xl p-6 max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold">Crear Campaña de Referidos</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800 text-2xl">
                        &times;
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* Campos básicos */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Nombre de la campaña</label>
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Descripción</label>
                        <textarea
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Marca</label>
                        <select name="marca" value={formData.marca} onChange={handleInputChange} className="w-full border rounded px-3 py-2" required>
                            <option value="">Seleccione una marca</option>
                            {brands.map(brand => (
                                <option key={brand._id} value={brand._id}>{brand.nombre}</option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-4 grid grid-cols-1 sm:grid-cols-2 gap-4">
                        <div>
                            <label className="block mb-1 font-medium">Fecha de Inicio</label>
                            <input
                                type="date"
                                name="fechaInicio"
                                value={formData.fechaInicio}
                                onChange={handleInputChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                        <div>
                            <label className="block mb-1 font-medium">Fecha de Fin</label>
                            <input
                                type="date"
                                name="fechaFin"
                                value={formData.fechaFin}
                                onChange={handleInputChange}
                                className="w-full border rounded px-3 py-2"
                            />
                        </div>
                    </div>
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Tipo de Campaña</label>
                        <select name="tipo" value={formData.tipo} onChange={handleInputChange} className="w-full border rounded px-3 py-2">
                            {campaignTypes.map(type => (
                                <option key={type.value} value={type.value}>{type.label}</option>
                            ))}
                        </select>
                    </div>
                    {/* Configuración para Referidor */}
                    <div className="mb-4 border p-4 rounded">
                        <h3 className="text-lg font-semibold mb-2">Configuración para el Referidor</h3>
                        <div className="mb-2">
                            <label className="block text-sm">Tipo de Recompensa</label>
                            <input
                                type="text"
                                placeholder="PRODUCTO, DESCUENTO o ACUMULACION"
                                value={formData.rewardReferidor.rewardType}
                                onChange={(e) => handleRewardChange('rewardReferidor', 'rewardType', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Nombre</label>
                            <input
                                type="text"
                                placeholder="Nombre de la recompensa"
                                value={formData.rewardReferidor.nombre}
                                onChange={(e) => handleRewardChange('rewardReferidor', 'nombre', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Descripción</label>
                            <input
                                type="text"
                                placeholder="Descripción de la recompensa"
                                value={formData.rewardReferidor.descripcion}
                                onChange={(e) => handleRewardChange('rewardReferidor', 'descripcion', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Porcentaje (si aplica)</label>
                            <input
                                type="number"
                                placeholder="Porcentaje de descuento"
                                value={formData.rewardReferidor.porcentaje}
                                onChange={(e) => handleRewardChange('rewardReferidor', 'porcentaje', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Puntos (si aplica)</label>
                            <input
                                type="number"
                                placeholder="Puntos a acumular"
                                value={formData.rewardReferidor.puntos}
                                onChange={(e) => handleRewardChange('rewardReferidor', 'puntos', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Moneda</label>
                            <input
                                type="text"
                                placeholder="USD, EUR, PEN, CLP"
                                value={formData.rewardReferidor.currency}
                                onChange={(e) => handleRewardChange('rewardReferidor', 'currency', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                            />
                        </div>
                    </div>
                    {/* Configuración para Referido */}
                    <div className="mb-4 border p-4 rounded">
                        <h3 className="text-lg font-semibold mb-2">Configuración para el Referido</h3>
                        <div className="mb-2">
                            <label className="block text-sm">Tipo de Recompensa</label>
                            <input
                                type="text"
                                placeholder="PRODUCTO, DESCUENTO o ACUMULACION"
                                value={formData.rewardReferido.rewardType}
                                onChange={(e) => handleRewardChange('rewardReferido', 'rewardType', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Nombre</label>
                            <input
                                type="text"
                                placeholder="Nombre de la recompensa"
                                value={formData.rewardReferido.nombre}
                                onChange={(e) => handleRewardChange('rewardReferido', 'nombre', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Descripción</label>
                            <input
                                type="text"
                                placeholder="Descripción de la recompensa"
                                value={formData.rewardReferido.descripcion}
                                onChange={(e) => handleRewardChange('rewardReferido', 'descripcion', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                                required
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Porcentaje (si aplica)</label>
                            <input
                                type="number"
                                placeholder="Porcentaje de descuento"
                                value={formData.rewardReferido.porcentaje}
                                onChange={(e) => handleRewardChange('rewardReferido', 'porcentaje', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Puntos (si aplica)</label>
                            <input
                                type="number"
                                placeholder="Puntos a acumular"
                                value={formData.rewardReferido.puntos}
                                onChange={(e) => handleRewardChange('rewardReferido', 'puntos', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                            />
                        </div>
                        <div className="mb-2">
                            <label className="block text-sm">Moneda</label>
                            <input
                                type="text"
                                placeholder="USD, EUR, PEN, CLP"
                                value={formData.rewardReferido.currency}
                                onChange={(e) => handleRewardChange('rewardReferido', 'currency', e.target.value)}
                                className="w-full border rounded px-2 py-1"
                            />
                        </div>
                    </div>
                    {/* Campos específicos para cada tipo de campaña */}
                    {formData.tipo === 'ProgramaEscalonado' && (
                        <div className="mb-4 border p-4 rounded">
                            <h3 className="text-lg font-semibold mb-2">Niveles de Recompensa Escalonada</h3>
                            {formData.rewardTiersReferidor.map((tier, index) => (
                                <div key={index} className="mb-3 border p-2 rounded">
                                    <div className="mb-2">
                                        <label className="block text-sm">Umbral (número mínimo de referidos)</label>
                                        <input
                                            type="number"
                                            placeholder="Ej.: 3"
                                            value={tier.threshold}
                                            onChange={(e) => handleTierChange(index, 'threshold', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm">Tipo de Recompensa</label>
                                        <input
                                            type="text"
                                            placeholder="PRODUCTO, DESCUENTO o ACUMULACION"
                                            value={tier.rewardType}
                                            onChange={(e) => handleTierChange(index, 'rewardType', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm">Nombre</label>
                                        <input
                                            type="text"
                                            placeholder="Nombre de la recompensa"
                                            value={tier.nombre}
                                            onChange={(e) => handleTierChange(index, 'nombre', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm">Descripción</label>
                                        <input
                                            type="text"
                                            placeholder="Descripción de la recompensa"
                                            value={tier.descripcion}
                                            onChange={(e) => handleTierChange(index, 'descripcion', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                            required
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm">Porcentaje (si aplica)</label>
                                        <input
                                            type="number"
                                            placeholder="Porcentaje de descuento"
                                            value={tier.porcentaje}
                                            onChange={(e) => handleTierChange(index, 'porcentaje', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm">Puntos (si aplica)</label>
                                        <input
                                            type="number"
                                            placeholder="Puntos a acumular"
                                            value={tier.puntos}
                                            onChange={(e) => handleTierChange(index, 'puntos', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                        />
                                    </div>
                                    <div className="mb-2">
                                        <label className="block text-sm">Moneda</label>
                                        <input
                                            type="text"
                                            placeholder="USD, EUR, PEN, CLP"
                                            value={tier.currency}
                                            onChange={(e) => handleTierChange(index, 'currency', e.target.value)}
                                            className="w-full border rounded px-2 py-1"
                                        />
                                    </div>
                                    <button type="button" onClick={() => removeTier(index)} className="text-red-600 text-sm">
                                        Eliminar Nivel
                                    </button>
                                </div>
                            ))}
                            <button type="button" onClick={addRewardTier} className="bg-blue-500 text-white px-3 py-1 rounded">
                                Añadir Nivel
                            </button>
                        </div>
                    )}
                    {formData.tipo === 'ReferidosConTiempoLimite' && (
                        <div className="mb-4 border p-4 rounded">
                            <h3 className="text-lg font-semibold mb-2">Recompensa Bonus para Referidos en Tiempo Límite</h3>
                            <div className="mb-2">
                                <label className="block text-sm">Tipo de Recompensa Bonus</label>
                                <input
                                    type="text"
                                    placeholder="PRODUCTO, DESCUENTO o ACUMULACION"
                                    value={formData.bonusRewardReferidor.rewardType}
                                    onChange={(e) => handleRewardChange('bonusRewardReferidor', 'rewardType', e.target.value)}
                                    className="w-full border rounded px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-sm">Nombre de la Recompensa Bonus</label>
                                <input
                                    type="text"
                                    placeholder="Nombre de la recompensa bonus"
                                    value={formData.bonusRewardReferidor.nombre}
                                    onChange={(e) => handleRewardChange('bonusRewardReferidor', 'nombre', e.target.value)}
                                    className="w-full border rounded px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-sm">Descripción de la Recompensa Bonus</label>
                                <input
                                    type="text"
                                    placeholder="Descripción de la recompensa bonus"
                                    value={formData.bonusRewardReferidor.descripcion}
                                    onChange={(e) => handleRewardChange('bonusRewardReferidor', 'descripcion', e.target.value)}
                                    className="w-full border rounded px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-sm">Porcentaje Bonus (si aplica)</label>
                                <input
                                    type="number"
                                    placeholder="Porcentaje de descuento bonus"
                                    value={formData.bonusRewardReferidor.porcentaje}
                                    onChange={(e) => handleRewardChange('bonusRewardReferidor', 'porcentaje', e.target.value)}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-sm">Puntos Bonus (si aplica)</label>
                                <input
                                    type="number"
                                    placeholder="Puntos bonus a acumular"
                                    value={formData.bonusRewardReferidor.puntos}
                                    onChange={(e) => handleRewardChange('bonusRewardReferidor', 'puntos', e.target.value)}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-sm">Moneda Bonus</label>
                                <input
                                    type="text"
                                    placeholder="USD, EUR, PEN, CLP"
                                    value={formData.bonusRewardReferidor.currency}
                                    onChange={(e) => handleRewardChange('bonusRewardReferidor', 'currency', e.target.value)}
                                    className="w-full border rounded px-2 py-1"
                                />
                            </div>
                            <div className="mb-2">
                                <label className="block text-sm">Fecha Límite Bonus</label>
                                <input
                                    type="date"
                                    name="bonusDeadline"
                                    value={formData.bonusDeadline}
                                    onChange={handleInputChange}
                                    className="w-full border rounded px-2 py-1"
                                    required
                                />
                            </div>
                        </div>
                    )}
                    {formData.tipo === 'AccesoExclusivo' && (
                        <div className="mb-4 border p-4 rounded">
                            <h3 className="text-lg font-semibold mb-2">Configuración para Acceso Exclusivo</h3>
                            <div className="mb-2">
                                <label className="block text-sm">Umbral de Referidos</label>
                                <input
                                    type="number"
                                    name="umbralAccesoExclusivo"
                                    value={formData.umbralAccesoExclusivo}
                                    onChange={handleInputChange}
                                    className="w-full border rounded px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-2 border p-2 rounded">
                                <h4 className="text-md font-medium mb-1">Recompensa Exclusiva</h4>
                                <div className="mb-2">
                                    <label className="block text-sm">Tipo de Recompensa</label>
                                    <input
                                        type="text"
                                        placeholder="PRODUCTO, DESCUENTO o ACUMULACION"
                                        value={formData.exclusiveReward.rewardType}
                                        onChange={(e) => handleRewardChange('exclusiveReward', 'rewardType', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Nombre</label>
                                    <input
                                        type="text"
                                        placeholder="Nombre de la recompensa exclusiva"
                                        value={formData.exclusiveReward.nombre}
                                        onChange={(e) => handleRewardChange('exclusiveReward', 'nombre', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Descripción</label>
                                    <input
                                        type="text"
                                        placeholder="Descripción de la recompensa exclusiva"
                                        value={formData.exclusiveReward.descripcion}
                                        onChange={(e) => handleRewardChange('exclusiveReward', 'descripcion', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Porcentaje (si aplica)</label>
                                    <input
                                        type="number"
                                        placeholder="Porcentaje de descuento exclusivo"
                                        value={formData.exclusiveReward.porcentaje}
                                        onChange={(e) => handleRewardChange('exclusiveReward', 'porcentaje', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Puntos (si aplica)</label>
                                    <input
                                        type="number"
                                        placeholder="Puntos exclusivos a acumular"
                                        value={formData.exclusiveReward.puntos}
                                        onChange={(e) => handleRewardChange('exclusiveReward', 'puntos', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Moneda</label>
                                    <input
                                        type="text"
                                        placeholder="USD, EUR, PEN, CLP"
                                        value={formData.exclusiveReward.currency}
                                        onChange={(e) => handleRewardChange('exclusiveReward', 'currency', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    {formData.tipo === 'ContigoYConTusAmigos' && (
                        <div className="mb-4 border p-4 rounded">
                            <h3 className="text-lg font-semibold mb-2">Configuración para Compras Grupales</h3>
                            <div className="mb-2">
                                <label className="block text-sm">Número Mínimo de Amigos</label>
                                <input
                                    type="number"
                                    name="groupThreshold"
                                    value={formData.groupThreshold}
                                    onChange={handleInputChange}
                                    className="w-full border rounded px-2 py-1"
                                    required
                                />
                            </div>
                            <div className="mb-2 border p-2 rounded">
                                <h4 className="text-md font-medium mb-1">Recompensa Grupual</h4>
                                <div className="mb-2">
                                    <label className="block text-sm">Tipo de Recompensa</label>
                                    <input
                                        type="text"
                                        placeholder="PRODUCTO, DESCUENTO o ACUMULACION"
                                        value={formData.groupReward.rewardType}
                                        onChange={(e) => handleRewardChange('groupReward', 'rewardType', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Nombre</label>
                                    <input
                                        type="text"
                                        placeholder="Nombre de la recompensa grupual"
                                        value={formData.groupReward.nombre}
                                        onChange={(e) => handleRewardChange('groupReward', 'nombre', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Descripción</label>
                                    <input
                                        type="text"
                                        placeholder="Descripción de la recompensa grupual"
                                        value={formData.groupReward.descripcion}
                                        onChange={(e) => handleRewardChange('groupReward', 'descripcion', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Porcentaje (si aplica)</label>
                                    <input
                                        type="number"
                                        placeholder="Porcentaje de descuento grupual"
                                        value={formData.groupReward.porcentaje}
                                        onChange={(e) => handleRewardChange('groupReward', 'porcentaje', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Puntos (si aplica)</label>
                                    <input
                                        type="number"
                                        placeholder="Puntos a acumular grupual"
                                        value={formData.groupReward.puntos}
                                        onChange={(e) => handleRewardChange('groupReward', 'puntos', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm">Moneda</label>
                                    <input
                                        type="text"
                                        placeholder="USD, EUR, PEN, CLP"
                                        value={formData.groupReward.currency}
                                        onChange={(e) => handleRewardChange('groupReward', 'currency', e.target.value)}
                                        className="w-full border rounded px-2 py-1"
                                    />
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="flex flex-col sm:flex-row justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="mb-2 sm:mb-0 mr-0 sm:mr-4 px-4 py-2 border rounded"
                        >
                            Cancelar
                        </button>
                        <button type="submit" className="bg-green-600 text-white px-4 py-2 rounded">
                            Crear Campaña
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateReferralCampaignModal;
