// MarcasPage.jsx
import React, { useState, useEffect } from 'react';
import useAuthStore from '../../store/authStore';
import { get, post, del } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import ManagePremiosModal from '../../components/modals/ManagePremiosModal';

function MarcasPage() {
  const { user } = useAuthStore();
  const [marcas, setMarcas] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [showModalPremio, setShowModalPremio] = useState(false);
  const [selectedMarca, setSelectedMarca] = useState(null);
  const [newMarca, setNewMarca] = useState({
    nombre: '',
    rubro: '',
    tiendas: [],
    clientes: [],
    pointscondition: 'Condición para sumar puntos',
    puntos: 'PUNTOS',
    puntosTicket: 6,
  });

  useEffect(() => {
    if (user) {
      fetchMarcas();
    }
  }, [user]);

  const fetchMarcas = async () => {
    try {
      const response = await get('marcas');
      setMarcas(response);
    } catch (error) {
      console.error('Error fetching marcas:', error);
    }
  };

  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const handleShowModalPremio = () => setShowModalPremio(true);
  const handleCloseModalPremio = () => setShowModalPremio(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewMarca((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const marcaData = {
        ...newMarca,
        puntosTicket: parseFloat(newMarca.puntosTicket),
        owner: user.userId,
      };
      await post('marcas', marcaData);
      fetchMarcas();
      handleCloseModal();
      Swal.fire({
        title: 'Éxito',
        text: 'Marca creada correctamente',
        icon: 'success',
        confirmButtonText: 'Aceptar',
      });
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  const handleDelete = async (id) => {
    const result = await Swal.fire({
      title: '¿Estás seguro?',
      text: 'Esta acción no se puede deshacer',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });
    if (result.isConfirmed) {
      try {
        await del(`marcas/${id}`);
        fetchMarcas();
        Swal.fire({
          title: 'Eliminado',
          text: 'La marca ha sido eliminada.',
          icon: 'success',
          confirmButtonText: 'Aceptar',
        });
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      }
    }
  };

  const handleManagePremios = (marca) => {
    setSelectedMarca(marca);
    setShowModalPremio(true);
  };

  return (
    <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
      <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">Marcas</h1>
      <p className="text-lg md:text-xl mb-4">
        Aquí puedes gestionar tus marcas. Una marca tiene tiendas y clientes. Todas las tiendas dentro de la misma marca compartirán el modelo de fidelización. Si tienes diferentes modelos de negocio, debes crear diferentes marcas.
      </p>
      <button
        onClick={handleShowModal}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 mb-6"
      >
        Crear Marca
      </button>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {marcas.map((marca) => (
          <div key={marca._id} className="p-4 border rounded shadow-sm">
            <h2 className="text-xl md:text-2xl font-bold">{marca.nombre}</h2>
            <p className="mt-2">{`Número de tiendas: ${marca.tiendas.length}`}</p>
            <p>{`Número de clientes: ${marca.clientes.length}`}</p>
            <p>{`Condición de puntos: ${marca.pointscondition}`}</p>
            {marca.puntos === 'PUNTOS' && (
              <p>{`Puntos por ticket: ${marca.puntosTicket}`}</p>
            )}
            {marca.puntos === 'ACUMULACION' && (
              <button
                onClick={() => handleManagePremios(marca)}
                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 mt-4 w-full"
              >
                Gestionar Premios
              </button>
            )}
            <button
              onClick={() => handleDelete(marca._id)}
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 mt-4 w-full"
            >
              Eliminar
            </button>
          </div>
        ))}
      </div>

      {showModalPremio && (
        <ManagePremiosModal
          marca={selectedMarca}
          onClose={handleCloseModalPremio}
          onRefresh={fetchMarcas}
        />
      )}

      {showModal && (
        <div className="fixed z-10 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen p-4 text-center sm:p-0">
            {/* Fondo oscuro */}
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
              onClick={handleCloseModal}
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>
            {/* Contenido del modal */}
            <div
              className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:max-w-lg sm:w-full"
              role="dialog"
              aria-modal="true"
              aria-labelledby="modal-title"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="flex justify-between items-center pb-3">
                  <h3
                    className="text-lg md:text-xl font-medium leading-6 text-gray-900"
                    id="modal-title"
                  >
                    Crear Nueva Marca
                  </h3>
                  <button
                    onClick={handleCloseModal}
                    className="text-gray-400 hover:text-gray-600"
                  >
                    <svg
                      className="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <form onSubmit={handleSubmit}>
                  <div className="grid grid-cols-1 gap-4">
                    {/* Nombre de la Marca */}
                    <div>
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="nombre"
                      >
                        Nombre de la Marca
                      </label>
                      <input
                        type="text"
                        name="nombre"
                        value={newMarca.nombre}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    {/* Rubro de la Marca */}
                    <div>
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="nombre"
                      >
                        Rubro de la Marca
                      </label>
                      <input
                        type="text"
                        name="rubro"
                        value={newMarca.rubro}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    {/* Condición de Puntos */}
                    <div>
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="pointscondition"
                      >
                        Condición de Puntos
                      </label>
                      <input
                        type="text"
                        name="pointscondition"
                        value={newMarca.pointscondition}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      />
                    </div>

                    {/* Tipo de Puntos */}
                    <div>
                      <label
                        className="block text-gray-700 text-sm font-bold mb-2"
                        htmlFor="puntos"
                      >
                        Tipo de Puntos
                      </label>
                      <select
                        name="puntos"
                        value={newMarca.puntos}
                        onChange={handleChange}
                        required
                        className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                      >
                        <option value="PUNTOS">Puntos</option>
                        <option value="ACUMULACION">Acumulación</option>
                      </select>
                    </div>

                    {/* Puntos por Ticket (solo si se selecciona "PUNTOS") */}
                    {newMarca.puntos === 'PUNTOS' && (
                      <div>
                        <label
                          className="block text-gray-700 text-sm font-bold mb-2"
                          htmlFor="puntosTicket"
                        >
                          Puntos por Ticket
                        </label>
                        <input
                          type="number"
                          name="puntosTicket"
                          value={newMarca.puntosTicket}
                          onChange={handleChange}
                          min={1}
                          max={20}
                          required
                          className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                        />
                      </div>
                    )}
                  </div>
                  <div className="flex justify-end mt-4">
                    <button
                      type="button"
                      onClick={handleCloseModal}
                      className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
                    >
                      Cancelar
                    </button>
                    <button
                      type="submit"
                      className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                    >
                      Crear
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default MarcasPage;
