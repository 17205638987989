// src/pages/gameLoyalty/CampaignPublicEmbedPage.jsx
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function CampaignPublicEmbedPage() {
    const { campaignId } = useParams();
    const [campaign, setCampaign] = useState(null);
    const [formData, setFormData] = useState({ email: '', code: '' });
    const [result, setResult] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        fetchCampaign();
    }, [campaignId]);

    const fetchCampaign = async () => {
        try {
            const data = await get(`game-loyalty/${campaignId}`);
            setCampaign(data);
        } catch (error) {
            console.error('Error al obtener la campaña:', error);
            Swal.fire('Error', error.message, 'error');
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleRedeem = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await post(`game-loyalty/${campaignId}/redeem`, formData);
            setResult(response);

            if (response.prizeWon) {
                Swal.fire('¡Felicidades!', 'Has ganado un premio. Consulta los detalles abajo.', 'success');
            } else {
                Swal.fire('Participación registrada', 'Gracias por participar. ¡Suerte la próxima vez!', 'info');
            }
        } catch (error) {
            console.error('Error al canjear el código:', error);
            Swal.fire('Error', error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-6">
            {campaign ? (
                <div>
                    <h1 className="text-2xl font-bold mb-2">{campaign.nombre}</h1>
                    {campaign.descripcion && <p className="mb-6">{campaign.descripcion}</p>}

                    <form onSubmit={handleRedeem} className="bg-white shadow-md rounded p-4">
                        <h2 className="text-xl font-semibold mb-4">Validar tu Código</h2>
                        <div className="mb-4">
                            <label className="block mb-1">Correo Electrónico</label>
                            <input
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                                className="w-full border rounded px-3 py-2"
                                required
                            />
                        </div>
                        <div className="mb-4">
                            <label className="block mb-1">Código</label>
                            <input
                                type="text"
                                name="code"
                                value={formData.code}
                                onChange={handleInputChange}
                                className="w-full border rounded px-3 py-2"
                                required
                            />
                        </div>
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-blue-600 text-white px-4 py-2 rounded"
                        >
                            {loading ? 'Procesando...' : 'Validar Código'}
                        </button>
                    </form>

                    {result && (
                        <div className="mt-4 p-4 bg-green-100 border rounded">
                            <h3 className="text-lg font-bold">Resultado del Canje</h3>
                            {result.prizeWon ? (
                                <p>
                                    ¡Enhorabuena! Has ganado: <strong>{result.prizeWon.nombre}</strong> – {result.prizeWon.descripcion}
                                </p>
                            ) : (
                                <p>Gracias por participar. Por desgracia, no has ganado ningún premio.</p>
                            )}
                            <p>
                                Tu código: <strong>{result.participation.code}</strong>
                            </p>
                        </div>
                    )}
                </div>
            ) : (
                <p>Cargando detalles de la campaña...</p>
            )}
        </div>
    );
}

export default CampaignPublicEmbedPage;
