// src/components/feedback/CriticalFeedback.jsx
import React, { useMemo } from 'react';
import Swal from 'sweetalert2';

const formatDate = (dateString) => {
  const options = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  };
  return new Date(dateString).toLocaleString('es-ES', options);
};

const CriticalFeedback = ({ feedback }) => {
  const groupedFeedback = useMemo(() => {
    return feedback.reduce((acc, item) => {
      const key = item.email || item.name || 'Anónimo';
      if (!acc[key]) {
        acc[key] = {
          name: item.name || 'Anónimo',
          email: item.email || 'N/A',
          phone: item.phone || 'N/A',
          date: formatDate(item.date),
          responses: [],
        };
      }
      acc[key].responses.push({
        question: item.question,
        answer: item.answer,
      });
      return acc;
    }, {});
  }, [feedback]);

  const handleDetailsClick = (responses) => {
    const detailsHtml = responses
      .map(
        (res) => `
          <div style="margin-bottom: 8px;">
            <p><strong>Pregunta:</strong> ${res.question}</p>
            <p><strong>Respuesta:</strong> ${res.answer}</p>
          </div>
        `
      )
      .join('');
    Swal.fire({
      title: 'Respuestas con puntuación baja',
      html: detailsHtml,
      icon: 'info',
      confirmButtonText: 'Cerrar',
    });
  };

  if (!feedback || feedback.length === 0) {
    return null;
  }

  return (
    <div className="mt-8">
      <h2 className="text-xl md:text-2xl font-semibold mb-4">Feedback Crítico</h2>
      {/* ─────────────────────────────────────────────────
          Contenedor con overflow-x-auto para la tabla
         ───────────────────────────────────────────────── */}
      <div className="w-full overflow-x-auto">
        <table className="table-auto min-w-full mb-4 text-sm border-collapse">
          <thead>
            <tr className="bg-gray-100">
              {/* Se añaden break-words & whitespace-normal */}
              <th className="px-4 py-2 text-left break-words whitespace-normal">Fecha</th>
              <th className="px-4 py-2 text-left break-words whitespace-normal">Nombre</th>
              <th className="px-4 py-2 text-left break-words whitespace-normal">Email</th>
              <th className="px-4 py-2 text-left break-words whitespace-normal">Teléfono</th>
              <th className="px-4 py-2 text-left break-words whitespace-normal">Acción</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(groupedFeedback).map(([key, user], index) => (
              <tr key={index} className="border-b">
                <td className="px-4 py-2 break-words whitespace-normal">{user.date}</td>
                <td className="px-4 py-2 break-words whitespace-normal">{user.name}</td>
                <td className="px-4 py-2 break-words whitespace-normal">{user.email}</td>
                <td className="px-4 py-2 break-words whitespace-normal">{user.phone}</td>
                <td className="px-4 py-2 break-words whitespace-normal">
                  <button
                    onClick={() => handleDetailsClick(user.responses)}
                    className="cursor-pointer px-4 py-1 border border-transparent rounded bg-blue-500 text-white hover:bg-blue-600 text-xs"
                  >
                    Ver Detalles
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CriticalFeedback;
