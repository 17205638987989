// MessageCalendar.jsx
import React from 'react';

const MessageCalendar = ({ messages }) => {
    return (
        <div>
            {messages.map(msg => (
                <div key={msg.id}>{msg.content}</div>
            ))}
        </div>
    );
};

export default MessageCalendar;