import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const WorkerSendCode = ({ campaignId }) => {
    const [email, setEmail] = useState('');
    const [generatedCode, setGeneratedCode] = useState(null);
    const [loading, setLoading] = useState(false);

    const handleSendCode = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            // Llama al endpoint para registrar la participación y generar el código
            const response = await post(`game-loyalty/${campaignId}/participate`, { email });
            const code = response.code;
            setGeneratedCode(code);

            // Construir el enlace de redención
            const redemptionLink = `${window.location.origin}/game-loyalty/public/${campaignId}?code=${code}`;

            if (process.env.NODE_ENV === 'production') {
                // En producción, se envía el email al cliente usando el endpoint del módulo de mails
                await post('mail/send-participation-code', { email, code, link: redemptionLink });
                Swal.fire('Código enviado', 'El código de participación se ha enviado al correo del cliente.', 'success');
            } else {
                // En desarrollo, se muestra el contenido del email en la consola y en una alerta
                console.log(`[DEV] Enviar email a ${email} con código: ${code} y enlace: ${redemptionLink}`);
                Swal.fire('Código generado (Modo DEV)', `Código: ${code}\nEnlace: ${redemptionLink}`, 'info');
            }
        } catch (error) {
            console.error('Error al enviar el código:', error);
            Swal.fire('Error', error.message, 'error');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="p-4 bg-gray-50 rounded shadow">
            <h2 className="text-xl font-bold mb-4">Enviar Código de Participación</h2>
            <form onSubmit={handleSendCode}>
                <div className="mb-4">
                    <label className="block mb-1 font-medium">Correo del Cliente</label>
                    <input
                        type="email"
                        placeholder="Ingrese el correo..."
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="w-full border rounded px-3 py-2"
                        required
                    />
                </div>
                <button
                    type="submit"
                    disabled={loading}
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition-colors"
                >
                    {loading ? 'Enviando...' : 'Enviar Código'}
                </button>
            </form>
            {generatedCode && (
                <div className="mt-4 p-2 border rounded bg-white">
                    <p className="font-medium">Código Generado:</p>
                    <p className="text-lg">{generatedCode}</p>
                </div>
            )}
        </div>
    );
};

export default WorkerSendCode;
