// MessageDetailModal.jsx
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const MessageDetailModal = ({ open, onClose, message }) => {
    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
            <DialogTitle>Mensaje Detallado</DialogTitle>
            <DialogContent>
                <p><strong>Canal:</strong> {message.channel}</p>
                <p><strong>Contenido:</strong> {message.content}</p>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Cerrar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default MessageDetailModal;