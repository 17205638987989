// src/pages/statistics/CustomerStatisticsPage.jsx
import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useParams, useNavigate } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import { FaArrowLeft, FaChartLine, FaRetweet } from 'react-icons/fa';
import StatCard from '../../components/statistic/StatCard';
import { Tooltip } from 'react-tooltip';

const CustomerStatisticsPage = () => {
  const { clienteId } = useParams();
  const navigate = useNavigate();
  const [stats, setStats] = useState(null);
  const [cliente, setCliente] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (clienteId) {
      const fetchData = async () => {
        try {
          // Obtener estadísticas del cliente
          const statsResponse = await get(`customer-statistics/cliente/${clienteId}`);
          setStats(statsResponse);
          // Obtener información básica del cliente
          const clientResponse = await get(`clientes/${clienteId}`);
          setCliente(clientResponse);
        } catch (error) {
          setError('No hay suficientes datos para mostrar estadísticas.');
          console.error('Error fetching statistics:', error);
          Swal.fire({
            title: 'Error',
            text: error.message || 'No hay suficientes datos para mostrar estadísticas.',
            icon: 'error',
            confirmButtonText: 'Aceptar'
          });
        }
      };
      fetchData();
    }
  }, [clienteId]);

  const handleBack = () => {
    navigate(-1);
  };

  if (error) {
    return <div className="p-6 text-red-500">{error}</div>;
  }

  if (!stats) {
    return <div className="p-6">Cargando estadísticas...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      <div className="container mx-auto">
        <button
          onClick={handleBack}
          className="mb-4 flex items-center text-blue-600 hover:underline"
        >
          <FaArrowLeft className="mr-2" /> Volver
        </button>
        <h1 className="text-2xl md:text-4xl font-bold mb-4 text-center text-gray-800">
          Estadísticas del Cliente
        </h1>
        {cliente && (
          <div className="mb-6 text-center">
            <h2 className="text-xl font-semibold">{cliente.nombre} {cliente.apellido}</h2>
            <p className="text-gray-600">{cliente.email}</p>
          </div>
        )}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <StatCard
            icon={<FaChartLine />}
            title="Total de Puntos"
            value={stats.totalPuntos}
            tooltip="Total de puntos acumulados por el cliente."
          />
          <StatCard
            icon={<FaRetweet />}
            title="Total de Canjes"
            value={stats.totalCanjes}
            tooltip="Número total de puntos canjeados por el cliente."
          />
          <StatCard
            icon={<FaChartLine />}
            title="Valor Promedio de Transacción"
            value={stats.averageTransactionValue.toFixed(2)}
            tooltip="Promedio del valor de cada transacción realizada por el cliente."
          />
          <StatCard
            icon={<FaRetweet />}
            title="Frecuencia de Visitas Diaria"
            value={stats.visitFrequency.daily.toFixed(2)}
            tooltip="Promedio de visitas diarias del cliente."
          />
          <StatCard
            icon={<FaRetweet />}
            title="Frecuencia de Visitas Semanal"
            value={stats.visitFrequency.weekly.toFixed(2)}
            tooltip="Promedio de visitas semanales del cliente."
          />
          <StatCard
            icon={<FaRetweet />}
            title="Frecuencia de Visitas Mensual"
            value={stats.visitFrequency.monthly.toFixed(2)}
            tooltip="Promedio de visitas mensuales del cliente."
          />
          <StatCard
            icon={<FaRetweet />}
            title="Total de Visitas"
            value={stats.totalVisits}
            tooltip="Número total de visitas realizadas por el cliente."
          />
        </div>
        <Tooltip id="tooltip" />
      </div>
    </div>
  );
};

export default CustomerStatisticsPage;
