import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import ConsumedProductsModal from '../../components/modals/ConsumedProductsModal';
import DiscountAppliedModal from '../../components/modals/DiscountAppliedModal';


const HistoryPage = () => {
  const { clienteId } = useParams();
  const [history, setHistory] = useState([]);
  const [selectedTicketId, setSelectedTicketId] = useState('');
  const [tickets, setTickets] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showProductsModal, setShowProductsModal] = useState(false);
  const [showDiscountModal, setShowDiscountModal] = useState(false);
  const [consumedProducts, setConsumedProducts] = useState([]);
  const [appliedDiscounts, setAppliedDiscounts] = useState([]);
  const [finalAmount, setFinalAmount] = useState(0);

  const itemsPerPage = 10;
  const navigate = useNavigate();

  useEffect(() => {
    fetchTickets();
    fetchAllHistory();
  }, []);

  useEffect(() => {
    if (selectedTicketId) {
      fetchHistoryByTicket(selectedTicketId);
    } else {
      fetchAllHistory();
    }
  }, [selectedTicketId]);

  const fetchAllHistory = async () => {
    try {
      const response = await get(`tickets/cliente/${clienteId}/history`);
      setHistory(response);
    } catch (error) {
      console.error('Error fetching history:', error);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const fetchHistoryByTicket = async (ticketId) => {
    try {
      const response = await get(`tickets/${ticketId}/history`);
      setHistory(response);
    } catch (error) {
      console.error('Error fetching history:', error);
      Swal.fire({
        title: 'Error',
        text: error.message,
        icon: 'error',
        confirmButtonText: 'Aceptar'
      });
    }
  };

  const fetchTickets = async () => {
    try {
      const response = await get(`clientes/${clienteId}`);
      const populatedTickets = await Promise.all(response.tickets.map(async (ticketId, index) => {
        const ticket = await get(`tickets/${ticketId}`);
        ticket.displayName = `Tarjeta ${index + 1}`;
        return ticket;
      }));
      setTickets(populatedTickets);
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const handleTicketChange = (event) => {
    setSelectedTicketId(event.target.value);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const handleViewConsumedProducts = (entry) => {
    if (entry.productos && entry.productos.length > 0) {
      setConsumedProducts(entry.productos);
    } else {
      setConsumedProducts([]);
    }
    setShowProductsModal(true);
  };

  const handleViewDiscounts = (entry) => {
    if (entry.descuentosAplicados && entry.descuentosAplicados.length > 0) {
      setAppliedDiscounts(entry.descuentosAplicados);

      let total = entry.monto;
      entry.descuentosAplicados.forEach((desc) => {
        total -= desc.cantidadMonetaria || 0;
        total -= (total * (desc.porcentaje || 0)) / 100;
      });

      setFinalAmount(Math.max(total, 0));
      setShowDiscountModal(true);
    }
  };

  // Paginación
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = history.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(history.length / itemsPerPage);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md md:ml-64">
      <h1 className="text-4xl font-bold mb-4">Historial de Consumiciones</h1>
      <div className="mb-4">
        <label className="block mb-2 text-lg font-medium text-gray-700">Seleccionar Ticket</label>
        <select value={selectedTicketId} onChange={handleTicketChange} className="block w-full p-2 border border-gray-300 rounded">
          <option value="">Todos los tickets</option>
          {tickets.map((ticket, index) => (
            <option key={ticket._id} value={ticket._id}>
              {ticket.displayName}
            </option>
          ))}
        </select>
      </div>
      <ul className="divide-y divide-gray-200">
        {currentItems.map((entry, index) => (
          <li key={index} className="py-4">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-lg font-semibold text-gray-900">
                  Fecha: <span className="font-normal">{formatDate(entry.fecha)}</span>
                </p>
                <p className="text-lg font-semibold">Monto: <span className="font-normal">${entry.monto.toFixed(2)}</span></p>
                {entry.descuentosAplicados.length > 0 && (
                  <p className="text-lg font-semibold text-green-600">
                    Monto con Descuento:
                    <span className="font-normal"> ${entry.montoConDescuento.toFixed(2)}</span>  {/* ✅ CORRECTO */}
                  </p>
                )}
                <p className="text-lg font-semibold text-gray-900">
                  Puntos: <span className="font-normal">{entry.puntos}</span>
                </p>
                <p className="text-lg font-semibold text-gray-900">
                  Vendedor: <span className="font-normal">
                    {entry.trabajador
                      ? `${entry.trabajador.nombre} ${entry.trabajador.apellido}`
                      : (entry.usuario ? `${entry.usuario.nombre} ${entry.usuario.apellido}` : 'Propietario')}
                  </span>
                </p>
              </div>
              <div>
                <p className="text-sm text-gray-500">
                  {new Date(entry.fecha).toLocaleTimeString()}
                </p>
                {/* Botón para ver productos consumidos */}
                <button
                  className={`mt-2 px-3 py-1 rounded ${entry.productos && entry.productos.length > 0
                    ? 'bg-blue-500 hover:bg-blue-700 text-white'
                    : 'bg-gray-300 cursor-not-allowed text-gray-700'
                    }`}
                  onClick={() => handleViewConsumedProducts(entry)}
                  disabled={!entry.productos || entry.productos.length === 0}
                >
                  {entry.productos && entry.productos.length > 0 ? 'Ver Productos' : 'Sin Productos'}
                </button>
                {entry.descuentosAplicados && entry.descuentosAplicados.length > 0 && (
                  <button
                    onClick={() => handleViewDiscounts(entry)}
                    className="bg-yellow-500 text-white px-3 py-1 rounded hover:bg-yellow-700"
                  >
                    Ver Descuentos
                  </button>
                )}

              </div>
            </div>
          </li>
        ))}
      </ul>
      <div className="flex justify-between items-center mt-6">
        <button
          className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700"
          onClick={handleBack}
        >
          Volver
        </button>
        <div className="flex space-x-1">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`px-3 py-1 rounded ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-300'}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
      {
        showProductsModal && (
          <ConsumedProductsModal
            productos={consumedProducts}
            onClose={() => setShowProductsModal(false)}
          />
        )
      }
      {showDiscountModal && <DiscountAppliedModal montoFinal={finalAmount} descuentosAplicados={appliedDiscounts} onClose={() => setShowDiscountModal(false)} />}
    </div>
  );
};


export default HistoryPage;
