// src/pages/statistics/StatisticsDashboard.jsx
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Outlet } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const StatisticsDashboard = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedMarca, setSelectedMarca] = useState(null);
  const [selectedTiendaId, setSelectedTiendaId] = useState('');
  const [selectedClienteId, setSelectedClienteId] = useState('');

  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const response = await get('marcas');
        if (response.length > 0) {
          const firstMarca = response[0];
          setSelectedMarca(firstMarca);
          if (firstMarca.tiendas && firstMarca.tiendas.length > 0) {
            setSelectedTiendaId(firstMarca.tiendas[0]);
          }
          if (firstMarca.clientes && firstMarca.clientes.length > 0) {
            setSelectedClienteId(firstMarca.clientes[0]);
          }
          // Si la ruta es /statistics, redirige a la vista de marca por defecto
          if (location.pathname === '/statistics' || location.pathname === '/statistics/') {
            navigate(`/statistics/marca/${firstMarca._id}`);
          }
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        Swal.fire({
          title: 'Error',
          text: 'Error al obtener datos iniciales. ' + error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar'
        });
      }
    };

    fetchInitialData();
  }, [location.pathname, navigate]);

  if (!selectedMarca) {
    return <div className="p-6 text-center">Cargando datos...</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 md:p-6">
      <div className="container mx-auto">
        <header className="mb-8 text-center">
          <h1 className="text-4xl md:text-6xl font-bold text-gray-800">
            Dashboard de Estadísticas
          </h1>
          <p className="text-lg text-gray-600 mt-2">
            Visualiza los indicadores clave de tu negocio para tomar decisiones estratégicas.
          </p>
        </header>
        <main>
          <Outlet context={{ selectedMarca, selectedTiendaId, selectedClienteId }} />
        </main>
      </div>
    </div>
  );
};

export default StatisticsDashboard;
