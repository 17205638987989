// src/components/CustomDatePicker.jsx
import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const CustomDatePicker = ({ name, value, onChange, placeholderText }) => {
    // Convertir el valor a Date, si existe
    const selectedDate = value ? new Date(value) : null;

    return (
        <DatePicker
            selected={selectedDate}
            onChange={(date) => {
                // Actualiza el valor en formato ISO (yyyy-mm-dd)
                onChange({
                    target: { name, value: date ? date.toISOString().split('T')[0] : '' },
                });
            }}
            dateFormat="dd/MM/yyyy"
            className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
            placeholderText={placeholderText}
            isClearable
        />
    );
};

export default CustomDatePicker;
