import React, { useState, useEffect } from 'react';
import { get, post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import useAuthStore from '../../store/authStore';

const WhatsAppConfigForm = () => {
    const { user } = useAuthStore();
    const [brands, setBrands] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState('');
    const [fromPhone, setFromPhone] = useState('');
    const [loading, setLoading] = useState(false);
    const [message, setMessage] = useState(null);

    // Cargar las marcas del usuario
    useEffect(() => {
        const fetchBrands = async () => {
            try {
                const userId = user?.userId; // Obtener userId del authStore
                if (!userId) return;
                const data = await get(`marcas/user/${userId}`);
                setBrands(data);
                if (data && data.length > 0) {
                    // Si solo hay una marca, la selecciona automáticamente
                    if (data.length === 1) {
                        setSelectedBrand(data[0]._id);
                    } else {
                        // Si hay más de una, se preselecciona la primera
                        setSelectedBrand(data[0]._id);
                    }
                }
            } catch (error) {
                console.error("Error al obtener marcas:", error);
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudieron cargar tus marcas. Intenta nuevamente.',
                    icon: 'error'
                });
            }
        };
        fetchBrands();
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage(null);

        // Construir el objeto de configuración
        const configData = {
            marcaId: selectedBrand,
            fromPhone,
            // Se podrían agregar templates si se requieren
        };

        try {
            const response = await post('whatsapp/configure', configData);
            setMessage('¡Configuración guardada correctamente!');
            Swal.fire({
                title: 'Éxito',
                text: 'La configuración de WhatsApp se ha guardado correctamente.',
                icon: 'success',
            });
        } catch (error) {
            console.error('Error configurando WhatsApp:', error);
            setMessage(error.message || 'Error al configurar WhatsApp');
            Swal.fire({
                title: 'Error',
                text: error.message || 'Error al configurar WhatsApp',
                icon: 'error',
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="max-w-xl mx-auto p-6 bg-white shadow-md rounded-md">
            <h2 className="text-3xl font-bold text-gray-800 mb-6 text-center">
                Configurar WhatsApp
            </h2>
            <p className="mb-6 text-gray-600 text-center">
                Sigue este asistente paso a paso para configurar tu integración de WhatsApp.
                La plataforma gestionará las credenciales globales; solo debes seleccionar tu marca y especificar el número desde el cual enviarás los mensajes.
            </p>
            {message && (
                <div className="mb-4 p-2 text-center bg-green-100 rounded">
                    {message}
                </div>
            )}
            <form onSubmit={handleSubmit} className="space-y-6">
                {/* Paso 1: Seleccionar Marca (se muestra solo si el usuario tiene más de una marca) */}
                {brands.length > 1 && (
                    <div>
                        <h3 className="text-xl font-semibold text-gray-700 mb-2">Paso 1: Selecciona tu Marca</h3>
                        <label htmlFor="brandSelect" className="block text-sm font-medium text-gray-700">
                            Marca
                        </label>
                        <select
                            id="brandSelect"
                            value={selectedBrand}
                            onChange={(e) => setSelectedBrand(e.target.value)}
                            className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                            required
                        >
                            {brands.map((brand) => (
                                <option key={brand._id} value={brand._id}>
                                    {brand.nombre}
                                </option>
                            ))}
                        </select>
                        <p className="text-xs text-gray-500 mt-1">
                            Selecciona la marca para la cual deseas configurar WhatsApp.
                        </p>
                    </div>
                )}
                {/* Paso 2: Ingresar el Número de WhatsApp */}
                <div>
                    <h3 className="text-xl font-semibold text-gray-700 mb-2">Paso 2: Ingresa tu Número de WhatsApp</h3>
                    <label htmlFor="fromPhone" className="block text-sm font-medium text-gray-700">
                        Número de WhatsApp (From)
                    </label>
                    <input
                        type="text"
                        id="fromPhone"
                        value={fromPhone}
                        onChange={(e) => setFromPhone(e.target.value)}
                        className="mt-1 block w-full border border-gray-300 rounded-md p-2"
                        placeholder="Ej: +34123456789"
                        pattern="^\+(34|51|56)[0-9]{7,10}$"
                        title="Ingresa el número en formato: +34XXXXXXXXX (España), +51XXXXXXXXX (Perú) o +56XXXXXXXXX (Chile)"
                        required
                    />
                    <p className="text-xs text-gray-500 mt-1">
                        El número debe estar en formato internacional.
                    </p>
                </div>
                <button
                    type="submit"
                    className="w-full bg-blue-600 text-white py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-400"
                    disabled={loading}
                >
                    {loading ? 'Configurando...' : 'Guardar Configuración'}
                </button>
            </form>
        </div>
    );
};

export default WhatsAppConfigForm;
