// src/pages/SurveyResults.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { get } from '../../helpers/apiHelper';

import LikertChart from '../../components/feedback/LikertChart';
import OpenTextResponses from '../../components/feedback/OpenTextResponses';
import CriticalFeedback from '../../components/feedback/CriticalFeedback';

function SurveyResults() {
    const { surveyId } = useParams();
    const [allData, setAllData] = useState([]);
    const [chartData, setChartData] = useState(null);
    const [openTextResponses, setOpenTextResponses] = useState([]);
    const [criticalFeedback, setCriticalFeedback] = useState([]);
    const [totalResponses, setTotalResponses] = useState(0);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [filter, setFilter] = useState('all'); // 'all', 'today', 'week', 'month', 'year'

    useEffect(() => {
        fetchSurveyData();
    }, [surveyId]);

    useEffect(() => {
        if (allData.length) {
            applyFilter();
        }
    }, [filter]);

    const fetchSurveyData = async () => {
        setLoading(true);
        try {
            const data = await get(`survey-responses/${surveyId}`);
            setAllData(data);
            applyFilter(data);
        } catch (err) {
            console.error('Error fetching survey data:', err);
            setError('No se pudo cargar la información de la encuesta');
            setLoading(false);
        }
    };

    const applyFilter = (data = allData) => {
        const filteredData = data.filter((d) => {
            const createdAt = moment(d.createdAt);
            switch (filter) {
                case 'today':
                    return createdAt.isSame(moment(), 'day');
                case 'week':
                    return createdAt.isSame(moment(), 'week');
                case 'month':
                    return createdAt.isSame(moment(), 'month');
                case 'year':
                    return createdAt.isSame(moment(), 'year');
                default:
                    return true; // 'all'
            }
        });
        processAndSetData(filteredData);
    };

    const processAndSetData = (data) => {
        const { chartData, openTextResponses, criticalFeedback } = processDataForChart(data);
        setChartData(chartData);
        setOpenTextResponses(openTextResponses);
        setCriticalFeedback(criticalFeedback);
        setTotalResponses(data.length);
        setLoading(false);
    };

    const processDataForChart = (data) => {
        const answerSums = {};
        const answerCounts = {};
        const openTexts = [];
        const critical = [];

        data.forEach((response) => {
            response.survey.questions.forEach((question, index) => {
                const answer = parseInt(response.answers[index], 10);
                if (question.type === 'likert') {
                    answerSums[index] = (answerSums[index] || 0) + answer;
                    answerCounts[index] = (answerCounts[index] || 0) + 1;

                    // Respuestas “críticas” <= 2
                    if (answer <= 2) {
                        critical.push({
                            name: response.name || 'Anónimo',
                            email: response.email || 'N/A',
                            phone: response.phone || 'N/A',
                            question: question.question,
                            answer: answer,
                            date: response.createdAt,
                        });
                    }
                } else if (question.type === 'openEnded') {
                    openTexts.push({ question: question.question, answer: response.answers[index] });
                }
            });
        });

        if (!data.length) {
            return {
                chartData: null,
                openTextResponses: [],
                criticalFeedback: [],
            };
        }

        const labels = Object.keys(answerSums).map(
            (index) => data[0].survey.questions[index].question
        );
        const datasets = [
            {
                label: 'Calificación Media',
                data: Object.keys(answerSums).map(
                    (i) => answerSums[i] / answerCounts[i]
                ),
                backgroundColor: 'rgba(75,192,192,0.2)',
                borderColor: 'rgba(75,192,192,1)',
                borderWidth: 1,
            },
        ];

        return {
            chartData: { labels, datasets },
            openTextResponses: openTexts,
            criticalFeedback: critical,
        };
    };

    if (loading) {
        return <div className="p-4">Cargando datos de la encuesta...</div>;
    }

    if (error) {
        return <div className="p-4 text-red-600">Error: {error}</div>;
    }

    return (
        // Contenedor raíz sin container ni mx-auto que fuerce ancho
        <div className="min-h-screen bg-gray-100 overflow-x-hidden p-4 sm:p-6">
            <h1 className="text-2xl md:text-3xl font-bold text-center mb-4">
                Resultados de la Encuesta
            </h1>

            {/* Botones de Filtro */}
            <div className="flex flex-wrap justify-center gap-2 my-4">
                {['today', 'week', 'month', 'year', 'all'].map((timeFrame) => (
                    <button
                        key={timeFrame}
                        onClick={() => setFilter(timeFrame)}
                        className={`px-3 py-1 rounded ${filter === timeFrame
                                ? 'bg-blue-700 text-white'
                                : 'bg-blue-500 text-white hover:bg-blue-600'
                            }`}
                    >
                        {timeFrame === 'today'
                            ? 'Hoy'
                            : timeFrame === 'week'
                                ? 'Esta Semana'
                                : timeFrame === 'month'
                                    ? 'Este Mes'
                                    : timeFrame === 'year'
                                        ? 'Este Año'
                                        : 'Todos'}
                    </button>
                ))}
            </div>

            {/* Total de Respuestas */}
            <div className="text-lg bg-gray-200 p-3 rounded-lg shadow text-center mb-4">
                Total de Respuestas: {totalResponses}
            </div>

            {/* Gráfico Likert */}
            <div className="w-full overflow-x-auto">
                <LikertChart data={chartData} />
            </div>

            {/* Respuestas Abiertas */}
            <OpenTextResponses responses={openTextResponses} />

            {/* Feedback Crítico */}
            <CriticalFeedback feedback={criticalFeedback} />
        </div>
    );
}

export default SurveyResults;
