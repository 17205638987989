// src/components/common/Header.jsx
import React, { useState, useEffect } from 'react';
import { differenceInCalendarDays } from 'date-fns';
import useAuthStore from '../../store/authStore';
import { useNavigate, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faSignOutAlt,
  faUser,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { get } from '../../helpers/apiHelper';

function Header({ toggleSidebar }) {
  const { user, logout } = useAuthStore();
  const navigate = useNavigate();
  const [trialDaysLeft, setTrialDaysLeft] = useState(null);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (user) {
      const fetchUserData = async () => {
        try {
          const response = await get(`users/${user.userId}`);
          if (response) {
            const userData = response;
            setIsSubscribed(userData.hasPaid);
            if (!userData.hasPaid) {
              const trialEndDate = new Date(userData.trialStarted);
              trialEndDate.setDate(trialEndDate.getDate() + 30);
              const daysLeft = differenceInCalendarDays(trialEndDate, new Date());
              setTrialDaysLeft(daysLeft);
            }
          } else {
            console.error('User data not found:', response);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      };
      fetchUserData();
    }
  }, [user]);

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  const handleNavigateToSubscribe = () => {
    navigate('/subscribe');
  };

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <header className="bg-gradient-to-r from-indigo-500 to-blue-400 text-white relative z-50">
      <div className="max-w-7xl mx-auto px-4 py-4 flex justify-between items-center">
        {/* Logo a la izquierda */}
        <h1 className="text-2xl font-bold">
          <Link to="/" className="hover:underline">
            PetitPrime
          </Link>
        </h1>

        {/* Navegación de escritorio */}
        <nav className="hidden md:flex items-center space-x-6">
          {user ? (
            <>
              {!isSubscribed && trialDaysLeft !== null && (
                <div
                  onClick={handleNavigateToSubscribe}
                  className="text-sm cursor-pointer"
                >
                  {trialDaysLeft > 0 ? `Trial ends in ${trialDaysLeft} days` : 'Trial ended'}
                </div>
              )}
              {!isSubscribed && (
                <button
                  onClick={handleNavigateToSubscribe}
                  className="py-2 px-4 bg-yellow-500 hover:bg-yellow-700 rounded-full text-lg transition duration-300"
                >
                  Suscríbete Ahora
                </button>
              )}
              <Link
                to="/settings"
                className="p-2 bg-blue-600 hover:bg-blue-800 rounded-full transition duration-300"
              >
                <FontAwesomeIcon icon={faCog} />
              </Link>
              <button
                onClick={handleLogout}
                className="p-2 bg-red-600 hover:bg-red-800 rounded-full transition duration-300"
              >
                <FontAwesomeIcon icon={faSignOutAlt} />
              </button>
            </>
          ) : (
            <>
              <Link to="/features" className="hover:underline">
                Características
              </Link>
              <Link to="/about" className="hover:underline">
                Acerca de
              </Link>
              <Link to="/blog" className="hover:underline">
                Blog
              </Link>
              <Link to="/pricing" className="hover:underline">
                Precios
              </Link>
              <button
                onClick={() => navigate('/login')}
                className="py-2 px-4 bg-green-600 hover:bg-green-800 rounded-full text-lg transition duration-300"
              >
                Iniciar sesión
              </button>
            </>
          )}
        </nav>

        {/* Botón de menú móvil (icono de persona) en la parte derecha */}
        <div className="md:hidden">
          <button
            onClick={toggleMobileMenu}
            className="focus:outline-none"
            aria-label="Toggle navigation"
          >
            {mobileMenuOpen ? (
              <FontAwesomeIcon icon={faTimes} className="text-2xl" />
            ) : (
              <FontAwesomeIcon icon={faUser} className="text-2xl" />
            )}
          </button>
        </div>
      </div>

      {/* Menú móvil */}
      {mobileMenuOpen && (
        <nav className="md:hidden bg-gradient-to-r from-indigo-500 to-blue-400 px-4 pb-4">
          {user ? (
            <div className="flex flex-col space-y-2">
              {!isSubscribed && trialDaysLeft !== null && (
                <div
                  onClick={() => { handleNavigateToSubscribe(); setMobileMenuOpen(false); }}
                  className="text-sm cursor-pointer"
                >
                  {trialDaysLeft > 0 ? `Trial ends in ${trialDaysLeft} days` : 'Trial ended'}
                </div>
              )}
              {!isSubscribed && (
                <button
                  onClick={() => { handleNavigateToSubscribe(); setMobileMenuOpen(false); }}
                  className="py-2 px-4 bg-yellow-500 hover:bg-yellow-700 rounded-full text-lg transition duration-300"
                >
                  Suscríbete Ahora
                </button>
              )}
              <Link
                onClick={() => setMobileMenuOpen(false)}
                to="/settings"
                className="flex items-center p-2 bg-blue-600 hover:bg-blue-800 rounded-full transition duration-300"
              >
                <FontAwesomeIcon icon={faCog} className="mr-2" />
                <span>Settings</span>
              </Link>
              <button
                onClick={() => { handleLogout(); setMobileMenuOpen(false); }}
                className="flex items-center p-2 bg-red-600 hover:bg-red-800 rounded-full transition duration-300"
              >
                <FontAwesomeIcon icon={faSignOutAlt} className="mr-2" />
                <span>Logout</span>
              </button>
            </div>
          ) : (
            <div className="flex flex-col space-y-2">
              <Link onClick={() => setMobileMenuOpen(false)} to="/features" className="hover:underline">
                Características
              </Link>
              <Link onClick={() => setMobileMenuOpen(false)} to="/about" className="hover:underline">
                Acerca de
              </Link>
              <Link onClick={() => setMobileMenuOpen(false)} to="/blog" className="hover:underline">
                Blog
              </Link>
              <Link onClick={() => setMobileMenuOpen(false)} to="/pricing" className="hover:underline">
                Precios
              </Link>
              <button
                onClick={() => { navigate('/login'); setMobileMenuOpen(false); }}
                className="py-2 px-4 bg-green-600 hover:bg-green-800 rounded-full text-lg transition duration-300"
              >
                Iniciar sesión
              </button>
            </div>
          )}
        </nav>
      )}
    </header>
  );
}

export default Header;
