// src/pages/communication/CommunicationMetricsPage.jsx
import React, { useState, useEffect } from 'react';
import MetricsCards from '../../components/communication/MetricsCards';
import EngagementChart from '../../components/communication/EngagementChart';
import ResponseTimeChart from '../../components/communication/ResponseTimeChart';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import BackToCommunication from '../../components/communication/BackToCommunication';

function CommunicationMetricsPage() {
    const [metrics, setMetrics] = useState(null);

    useEffect(() => {
        fetchMetrics();
    }, []);

    const fetchMetrics = async () => {
        try {
            const response = await get('communication/metrics');
            setMetrics(response);
        } catch (error) {
            Swal.fire('Error', error.message, 'error');
        }
    };

    return (
        <div>
            <BackToCommunication />
            <h1 className="text-2xl font-bold mb-4">Métricas de Comunicación</h1>
            {metrics && (
                <>
                    <MetricsCards metrics={metrics} />
                    <EngagementChart data={metrics.engagementData} />
                    <ResponseTimeChart data={metrics.responseTimeData} />
                </>
            )}
        </div>
    );
}

export default CommunicationMetricsPage;