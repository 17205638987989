// ScheduleMessageForm.jsx
import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';

const ScheduleMessageForm = () => {
    const [schedule, setSchedule] = useState({
        message: '', sendAt: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        await post('communication/schedule', schedule);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="Mensaje" onChange={(e) => setSchedule({ ...schedule, message: e.target.value })} />
            <button type="submit">Programar</button>
        </form>
    );
};

export default ScheduleMessageForm;