import React, { useEffect } from 'react';
import { FaTimes } from 'react-icons/fa';
import { patch } from '../../helpers/apiHelper';

const AlertModal = ({ alerts, onClose }) => {
    useEffect(() => {
        if (!alerts || alerts.length === 0) return;
        const markAlertsAsRead = async () => {
            for (const alert of alerts) {
                if (alert.tipo === "Cambio de Categoría" && !alert.leida) {
                    try {
                        // Actualiza la alerta a leída
                        await patch(`alertas/${alert._id}`, { leida: true });
                    } catch (error) {
                        console.error("Error marcando alerta como leída:", error);
                    }
                }
            }
        };
        markAlertsAsRead();
    }, [alerts]);

    if (!alerts || alerts.length === 0) return null;

    return (
        <div
            className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
            onClick={onClose} // Cierra al hacer clic fuera del modal
        >
            <div
                className="bg-white p-6 rounded-lg shadow-lg w-11/12 max-w-lg relative"
                onClick={(e) => e.stopPropagation()} // Evita cerrar al hacer clic dentro del modal
            >
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-800">Alertas Activas</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-800 focus:outline-none"
                        aria-label="Cerrar modal"
                    >
                        <FaTimes size={20} />
                    </button>
                </div>

                <div className="max-h-64 overflow-y-auto">
                    <ul className="space-y-4">
                        {alerts.map((alert) => (
                            <li
                                key={alert._id}
                                className="p-4 bg-gray-100 rounded-lg shadow-sm hover:bg-gray-200 transition duration-200"
                            >
                                <p className="text-gray-700 font-medium">{alert.mensaje}</p>
                            </li>
                        ))}
                    </ul>
                </div>

                <button
                    className="bg-blue-500 text-white px-6 py-2 rounded mt-4 w-full hover:bg-blue-600 transition duration-200"
                    onClick={onClose}
                >
                    Cerrar
                </button>
            </div>
        </div>
    );
};

export default AlertModal;
