import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const SendParticipationCode = ({ campaignId }) => {
    const [email, setEmail] = useState('');

    const handleSendCode = async () => {
        try {
            // Genera la participación y obtiene el código
            const participationResponse = await post(`game-loyalty/${campaignId}/participate`, { email });
            const code = participationResponse.code;
            // Construimos un enlace de redención que dirija a la página pública de canje, opcionalmente con el código como query parameter
            const redemptionLink = `${window.location.origin}/game-loyalty/public/${campaignId}?code=${code}`;

            if (process.env.NODE_ENV === 'production') {
                // En producción, se llama al endpoint del módulo de mails para enviar el email al cliente
                await post('mail/send-participation-code', { email, code, link: redemptionLink });
                Swal.fire('Código enviado', 'El código de participación se ha enviado al correo del cliente.', 'success');
            } else {
                // En desarrollo, se muestra el contenido del email en la consola para poder visualizarlo
                console.log(`[DEV] Enviar email a ${email} con código: ${code} y enlace: ${redemptionLink}`);
                Swal.fire('Código generado (Modo DEV)', `Código: ${code}\nEnlace: ${redemptionLink}`, 'info');
            }
        } catch (error) {
            console.error('Error al generar el código:', error);
            Swal.fire('Error', error.message, 'error');
        }
    };

    return (
        <div>
            <input
                type="email"
                placeholder="Ingrese el correo del cliente"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="border rounded px-2 py-1"
            />
            <button onClick={handleSendCode} className="bg-blue-500 text-white px-4 py-2 rounded ml-2">
                Enviar Código
            </button>
        </div>
    );
};

export default SendParticipationCode;
