// src/components/statistic/StatCard.jsx
import React from 'react';
import { FaInfoCircle } from 'react-icons/fa';

const StatCard = ({ icon, title, value, tooltip }) => (
  <div
    className="bg-white p-6 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300 cursor-default"
    data-tooltip-id="tooltip"
    data-tooltip-content={tooltip}
  >
    <div className="flex items-center mb-4">
      <div className="text-blue-600 text-3xl">{icon}</div>
      <div className="ml-4">
        <span className="text-xl font-semibold text-gray-800">{title}</span>
      </div>
      <FaInfoCircle className="ml-2 text-gray-400 text-xl" />
    </div>
    <div className="text-2xl font-bold text-gray-900">
      {value !== undefined ? value : 'N/D'}
    </div>
  </div>
);

export default StatCard;
