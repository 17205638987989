import React, { useState, useMemo } from 'react';
import CreatableSelect from 'react-select/creatable';
import { components as selectComponents } from 'react-select';
import { SketchPicker } from 'react-color';
import CreateTagModal from './CreateTagModal';

// Opciones por defecto para etiquetas estándar con colores fijos
const defaultTagOptions = [
  { value: 'VIP', label: 'VIP', color: 'green' },
  { value: 'Frecuentes', label: 'Frecuentes', color: 'orange' },
  { value: 'Inactivos', label: 'Inactivos', color: 'red' },
  { value: 'Sin Compras', label: 'Sin Compras', color: 'gray' },
];

// Estilos personalizados para react-select
const customSelectStyles = {
  container: (provided) => ({ ...provided, width: '100%' }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.color || '#777',
  }),
  multiValueLabel: (styles) => ({ ...styles, color: 'white' }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};

// Componente personalizado para MultiValue que permite editar el color al hacer clic
const MultiValue = (props) => {
  const { data } = props;
  const onLabelClick = (event) => {
    event.stopPropagation();
    if (props.selectProps.onEditColor) {
      props.selectProps.onEditColor(data);
    }
  };
  return (
    <selectComponents.MultiValue {...props}>
      <div onClick={onLabelClick} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
        <div
          style={{
            width: 10,
            height: 10,
            backgroundColor: data.color || '#777',
            borderRadius: '50%',
            marginRight: 4,
          }}
        ></div>
        <selectComponents.MultiValueLabel {...props} />
      </div>
    </selectComponents.MultiValue>
  );
};

const EditClienteModal = ({ cliente, onClose, onSave, tagOptions = defaultTagOptions }) => {
  // Aseguramos que geographic_info y segments tengan valores por defecto para evitar errores
  const initialCliente = {
    ...cliente,
    geographic_info: cliente.geographic_info || { country: '', state: '', city: '', postal_code: '' },
    segments: Array.isArray(cliente.segments)
      ? cliente.segments.map((tag) =>
        typeof tag === 'object'
          ? tag
          : { value: tag, label: tag, color: '#777' }
      )
      : [],
  };

  const [editingCliente, setEditingCliente] = useState(initialCliente);
  const [colorPickerTag, setColorPickerTag] = useState(null);
  const [showCreateTagModal, setShowCreateTagModal] = useState(false);
  const [newTagDefaultName, setNewTagDefaultName] = useState('');

  // Calculamos las opciones disponibles para el select (sin duplicados, ignorando mayúsculas)
  const mergedTagOptions = useMemo(() => {
    const map = new Map();
    // Primero agregamos las opciones por defecto
    tagOptions.forEach((tag) => {
      map.set(tag.value.toLowerCase(), tag);
    });
    // Luego agregamos las etiquetas que ya tiene el cliente, sin duplicar
    editingCliente.segments.forEach((tag) => {
      if (!map.has(tag.value.toLowerCase())) {
        map.set(tag.value.toLowerCase(), tag);
      }
    });
    return Array.from(map.values());
  }, [tagOptions, editingCliente.segments]);

  // Manejador para inputs simples y anidados (por ejemplo, geographic_info)
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('geographic_info')) {
      const [group, key] = name.split('.');
      setEditingCliente((prev) => ({
        ...prev,
        [group]: { ...prev[group], [key]: value },
      }));
    } else {
      setEditingCliente((prev) => ({ ...prev, [name]: value }));
    }
  };

  // Manejador para cambios en el CreatableSelect de etiquetas
  const handleSegmentsChange = (selectedOptions, actionMeta) => {
    if (actionMeta.action === 'create-option') {
      // Verificar si ya existe una etiqueta con ese nombre (sin duplicados)
      const exists = editingCliente.segments.some(
        (tag) => tag.value.toLowerCase() === actionMeta.option.value.toLowerCase()
      );
      if (exists) return;
      setNewTagDefaultName(actionMeta.option.value);
      setShowCreateTagModal(true);
    } else {
      const tags = selectedOptions || [];
      setEditingCliente((prev) => ({ ...prev, segments: tags }));
    }
  };

  // Función para mostrar el color picker al hacer clic en una etiqueta
  const handleEditColor = (tagData) => {
    setColorPickerTag(tagData);
  };

  // Actualiza el color de la etiqueta seleccionada mediante el SketchPicker
  const handleColorChangeComplete = (color) => {
    setEditingCliente((prev) => ({
      ...prev,
      segments: prev.segments.map((tag) =>
        tag.value === colorPickerTag.value ? { ...tag, color: color.hex } : tag
      ),
    }));
    setColorPickerTag(null);
  };

  // Al guardar, limpiamos las propiedades extra para enviar solo { value, label, color }
  const handleSave = () => {
    const cleanSegments = editingCliente.segments.map(({ value, label, color }) => ({ value, label, color }));
    const { codigoReferido, ...clienteToSave } = editingCliente;
    onSave({ ...clienteToSave, segments: cleanSegments });
  };

  const currentTags = editingCliente.segments;

  return (
    <div className="fixed inset-0 z-50 overflow-y-auto">
      <div className="flex items-center justify-center min-h-screen px-4 py-6">
        <div className="fixed inset-0 bg-gray-500 opacity-75" aria-hidden="true"></div>
        <div className="bg-white rounded-lg shadow-xl transform transition-all w-full max-w-lg mx-auto overflow-y-auto">
          <div className="px-6 py-4">
            <h2 className="text-2xl font-bold text-center mb-4">Editar Cliente</h2>
            <form>
              {/* Campos de texto para nombre, apellido, email, etc. */}
              <div className="mb-4">
                <label htmlFor="nombre" className="block text-gray-700 text-sm font-bold mb-1">Nombre</label>
                <input type="text" name="nombre" value={editingCliente.nombre} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="apellido" className="block text-gray-700 text-sm font-bold mb-1">Apellido</label>
                <input type="text" name="apellido" value={editingCliente.apellido} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className="block text-gray-700 text-sm font-bold mb-1">Email</label>
                <input type="email" name="email" value={editingCliente.email} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="telefono" className="block text-gray-700 text-sm font-bold mb-1">Teléfono</label>
                <input type="text" name="telefono" value={editingCliente.telefono} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="fechaCumpleanos" className="block text-gray-700 text-sm font-bold mb-1">Fecha de Nacimiento</label>
                <input type="date" name="fechaCumpleanos" value={editingCliente.fechaCumpleanos} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              {/* Dirección */}
              <div className="mb-4">
                <label htmlFor="geographic_info.country" className="block text-gray-700 text-sm font-bold mb-1">País</label>
                <input type="text" name="geographic_info.country" value={editingCliente.geographic_info.country} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="geographic_info.state" className="block text-gray-700 text-sm font-bold mb-1">Estado/Región</label>
                <input type="text" name="geographic_info.state" value={editingCliente.geographic_info.state} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="geographic_info.city" className="block text-gray-700 text-sm font-bold mb-1">Ciudad</label>
                <input type="text" name="geographic_info.city" value={editingCliente.geographic_info.city} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              <div className="mb-4">
                <label htmlFor="geographic_info.postal_code" className="block text-gray-700 text-sm font-bold mb-1">Código Postal</label>
                <input type="text" name="geographic_info.postal_code" value={editingCliente.geographic_info.postal_code} onChange={handleChange} className="w-full p-2 border border-gray-300 rounded" />
              </div>
              {/* Campo de Etiquetas */}
              <div className="mb-4">
                <label htmlFor="segments" className="block text-gray-700 text-sm font-bold mb-1">Etiquetas (selecciona o crea)</label>
                <CreatableSelect
                  isMulti
                  isClearable={false}
                  options={mergedTagOptions}
                  value={currentTags}
                  onChange={handleSegmentsChange}
                  components={{ MultiValue }}
                  styles={customSelectStyles}
                  placeholder="Seleccione o cree etiquetas..."
                  onCreateOption={(inputValue) => {
                    // Evitar duplicados en el mismo usuario (comparación insensible a mayúsculas)
                    const exists = editingCliente.segments.some(
                      (tag) => tag.value.toLowerCase() === inputValue.toLowerCase()
                    );
                    if (exists) return;
                    setNewTagDefaultName(inputValue);
                    setShowCreateTagModal(true);
                  }}
                  onEditColor={handleEditColor}
                />
              </div>
            </form>
          </div>
          <div className="px-6 py-4 bg-gray-100 flex justify-end">
            <button className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2" onClick={onClose}>
              Cancelar
            </button>
            <button className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700" onClick={handleSave}>
              Guardar
            </button>
          </div>
        </div>
        {showCreateTagModal && (
          <CreateTagModal
            initialName={newTagDefaultName}
            onCreate={(newTag) => {
              // Evitar duplicados: si ya existe, no se añade
              const exists = editingCliente.segments.some(
                (tag) => tag.value.toLowerCase() === newTag.value.toLowerCase()
              );
              if (!exists) {
                setEditingCliente((prev) => ({ ...prev, segments: [...prev.segments, newTag] }));
              }
              setShowCreateTagModal(false);
            }}
            onClose={() => setShowCreateTagModal(false)}
          />
        )}
        {colorPickerTag && (
          <div className="absolute" style={{ top: 100, left: 100 }}>
            <SketchPicker color={colorPickerTag.color} onChangeComplete={handleColorChangeComplete} />
          </div>
        )}
      </div>
    </div>
  );
};

export default EditClienteModal;
