import React from "react";

const BotonAccion = ({ onClick, color, text, disabled }) => (
    <button
        onClick={onClick}
        disabled={disabled}
        className={`${color} text-white px-4 py-2 rounded-lg w-full sm:w-auto hover:opacity-90 ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
    >
        {text}
    </button>
);

export default BotonAccion;
