// src/components/dashboards/MiniChart.jsx
import React, { useEffect, useState } from 'react';
import { get } from '../../helpers/apiHelper';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from 'recharts';
import { addDays } from 'date-fns';

const MiniChart = ({ marcaId, from, to, interval }) => {
    const [data, setData] = useState([]);

    useEffect(() => {
        fetchChartData();
    }, [marcaId, from, to, interval]);

    const fetchChartData = async () => {
        try {
            // Petición al endpoint que devuelve la serie completa de nuevos clientes
            const res = await get(
                `statistics/nuevos-clientes/${marcaId}/timeseries?from=${from}&to=${to}`
            );
            // Res se espera en formato: [{ date: "YYYY-MM-DD", count: X }, ... ]
            // Mapeamos a un formato que use la clave "nuevos" (puedes cambiar el nombre si prefieres)
            const chartData = res.map((item) => ({
                date: item.date,
                nuevos: item.count,
            }));
            setData(chartData);
        } catch (error) {
            console.error('Error fetching chart data:', error);
        }
    };

    return (
        <div className="bg-white p-4 rounded shadow">
            <h3 className="text-lg font-bold">Evolución de Nuevos Clientes</h3>
            {data.length === 0 ? (
                <div className="text-center text-gray-500 py-20">
                    No hay datos para mostrar. Registra nuevos clientes.
                </div>
            ) : (
                <ResponsiveContainer width="100%" height={200}>
                    <BarChart data={data}>
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="date" tick={{ fontSize: 10 }} />
                        <YAxis />
                        <Tooltip />
                        <Bar dataKey="nuevos" fill="#8884d8" />
                    </BarChart>
                </ResponsiveContainer>
            )}
        </div>
    );
};

export default MiniChart;
