import React, { useState } from 'react';

const CreateTagModal = ({ initialName, onCreate, onClose }) => {
    const [tagName, setTagName] = useState(initialName || '');
    const [selectedColor, setSelectedColor] = useState('#777');
    const colorOptions = [
        { color: '#FF0000', label: 'Rojo' },
        { color: '#00FF00', label: 'Verde' },
        { color: '#0000FF', label: 'Azul' },
        { color: '#FFA500', label: 'Naranja' },
        { color: '#800080', label: 'Morado' },
        { color: '#808080', label: 'Gris' },
    ];

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-gray-800 bg-opacity-50">
            <div className="bg-white rounded-lg shadow-xl w-11/12 max-w-sm p-4">
                <h3 className="text-xl font-bold mb-4">Crear Etiqueta Personalizada</h3>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-1">Nombre de la etiqueta</label>
                    <input
                        type="text"
                        value={tagName}
                        onChange={(e) => setTagName(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded"
                    />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700 text-sm font-bold mb-1">Selecciona un color</label>
                    <div className="flex flex-wrap">
                        {colorOptions.map((option) => (
                            <button
                                key={option.color}
                                type="button"
                                onClick={() => setSelectedColor(option.color)}
                                style={{ backgroundColor: option.color }}
                                className={`w-8 h-8 m-1 rounded-full ${selectedColor === option.color ? 'ring-2 ring-offset-2 ring-black' : ''}`}
                            ></button>
                        ))}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded mr-2">
                        Cancelar
                    </button>
                    <button onClick={() => onCreate({ value: tagName, label: tagName, color: selectedColor })} className="bg-blue-500 text-white px-4 py-2 rounded">
                        Guardar
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CreateTagModal;
