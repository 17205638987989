import React, { useState, useEffect } from 'react';
import { post, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const CrearClienteForm = ({ marcaId, tiendas, onClose, onClienteCreated }) => {
    // Estados para marcas y tiendas disponibles (en caso de no recibirse por props)
    const [marcas, setMarcas] = useState([]);
    const [tiendasDisponibles, setTiendasDisponibles] = useState(tiendas || []);

    // Estados para teléfono: prefijo y número
    const [phonePrefix, setPhonePrefix] = useState('+34'); // Por defecto: España
    const [customPrefix, setCustomPrefix] = useState('');

    // Estado para el código de referido ingresado por el usuario
    const [codigoReferido, setCodigoReferido] = useState('');

    // Estado inicial del cliente
    const [cliente, setCliente] = useState({
        nombre: '',
        apellido: '',
        email: '',
        // El campo "telefono" se completará al enviar con el prefijo concatenado al número
        telefono: '',
        fechaCumpleanos: '',
        marca: marcaId || '',
        // Si se pasó tiendas y sólo hay una, se asigna automáticamente
        tienda: (tiendas && tiendas.length === 1) ? tiendas[0]._id : '',
        geographic_info: {
            country: '',
            state: '',
            city: '',
            postal_code: ''
        },
        // Este campo guardará el ID del cliente que refirió al nuevo cliente (si aplica)
        referidoPor: ''
    });

    // Cargar marcas y tiendas si no se recibieron por props
    useEffect(() => {
        const loadData = async () => {
            try {
                if (!marcaId) {
                    const fetchedMarcas = await get('marcas');
                    setMarcas(fetchedMarcas);
                    if (fetchedMarcas.length === 1) {
                        setCliente(prev => ({ ...prev, marca: fetchedMarcas[0]._id }));
                        const fetchedTiendas = await get(`tiendas?marcaId=${fetchedMarcas[0]._id}`);
                        setTiendasDisponibles(fetchedTiendas);
                        if (fetchedTiendas.length === 1) {
                            setCliente(prev => ({ ...prev, tienda: fetchedTiendas[0]._id }));
                        }
                    }
                } else {
                    if (tiendasDisponibles.length === 0) {
                        const fetchedTiendas = await get(`tiendas?marcaId=${marcaId}`);
                        setTiendasDisponibles(fetchedTiendas);
                        if (fetchedTiendas.length === 1) {
                            setCliente(prev => ({ ...prev, tienda: fetchedTiendas[0]._id }));
                        }
                    }
                }
            } catch (error) {
                console.error('Error al cargar marcas o tiendas:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudieron cargar las marcas o tiendas. Intenta nuevamente.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        };
        loadData();
    }, [marcaId, tiendasDisponibles.length]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('geographic_info')) {
            const [group, key] = name.split('.');
            setCliente(prev => ({
                ...prev,
                [group]: { ...prev[group], [key]: value }
            }));
        } else {
            setCliente(prev => ({ ...prev, [name]: value }));
        }
    };

    // Maneja el cambio en el input de código de referido
    const handleCodigoReferidoChange = (e) => {
        setCodigoReferido(e.target.value);
    };

    const handlePrefixChange = (e) => {
        setPhonePrefix(e.target.value);
        if (e.target.value !== 'otros') {
            setCustomPrefix('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validar que se proporcione email y teléfono (teléfono obligatorio)
        if (!cliente.email || !cliente.telefono) {
            Swal.fire({
                title: 'Error',
                text: 'Debe proporcionar al menos un email y un teléfono',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        // Determinar el prefijo final y concatenarlo con el número
        const finalPrefix = phonePrefix === 'otros' ? customPrefix : phonePrefix;
        const telefonoCompleto = `${finalPrefix}${cliente.telefono}`;
        const clienteToSend = { ...cliente, telefono: telefonoCompleto };

        // Si se ingresó un código de referido, validar que exista y pertenezca a un cliente de la misma marca
        if (codigoReferido.trim() !== '') {
            // Limpiar el código: quitar espacios y convertir a minúsculas
            const cleanedCode = codigoReferido.trim().toLowerCase().replace(/\s+/g, '');
            try {
                // Suponemos que existe un endpoint que valida el código de referido:
                // GET /clientes/referral?code=cleanedCode&marcaId=...
                const refResponse = await get(`clientes/referral?code=${cleanedCode}&marcaId=${clienteToSend.marca}`);
                if (!refResponse || !refResponse._id) {
                    Swal.fire({
                        title: 'Error',
                        text: 'El código de referido no es válido o no pertenece a un cliente de tu marca',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                    return;
                }
                // Si es válido, asignar el id del cliente referido al nuevo cliente
                clienteToSend.referidoPor = refResponse._id;
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al validar el código de referido',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                return;
            }
        }

        try {
            await post('clientes', clienteToSend);
            Swal.fire({
                title: 'Éxito',
                text: 'Cliente creado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            onClienteCreated();
            onClose();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center p-4">
            {/* Fondo oscuro */}
            <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
            {/* Contenedor del modal */}
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-lg sm:w-3/4 lg:w-1/2 z-20">
                <div className="px-6 py-5">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold">Crear Cliente</h3>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            ✕
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 gap-4">
                            {/* Campos obligatorios */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Nombre*</label>
                                <input
                                    type="text"
                                    name="nombre"
                                    value={cliente.nombre}
                                    onChange={handleChange}
                                    required
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Apellido*</label>
                                <input
                                    type="text"
                                    name="apellido"
                                    value={cliente.apellido}
                                    onChange={handleChange}
                                    required
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Email*</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={cliente.email}
                                    onChange={handleChange}
                                    required
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            {/* Teléfono: prefijo + número */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Teléfono*</label>
                                <div className="flex">
                                    <select
                                        name="phonePrefix"
                                        value={phonePrefix}
                                        onChange={handlePrefixChange}
                                        className="shadow border rounded-l w-32 py-2 px-3 text-sm"
                                    >
                                        <option value="+34">España (+34)</option>
                                        <option value="+51">Perú (+51)</option>
                                        <option value="+56">Chile (+56)</option>
                                        <option value="otros">Otros</option>
                                    </select>
                                    {phonePrefix === 'otros' && (
                                        <input
                                            type="text"
                                            name="customPrefix"
                                            value={customPrefix}
                                            onChange={(e) => setCustomPrefix(e.target.value)}
                                            placeholder="Prefijo"
                                            className="shadow border rounded-r w-24 py-2 px-3 text-sm"
                                        />
                                    )}
                                    <input
                                        type="text"
                                        name="telefono"
                                        value={cliente.telefono}
                                        onChange={handleChange}
                                        required
                                        placeholder="Número"
                                        className="shadow border rounded-r w-full py-2 px-3"
                                    />
                                </div>
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Fecha de Cumpleaños</label>
                                <input
                                    type="date"
                                    name="fechaCumpleanos"
                                    value={cliente.fechaCumpleanos}
                                    onChange={handleChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            {/* Código de referido */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Código de referido (opcional)</label>
                                <input
                                    type="text"
                                    name="codigoReferido"
                                    value={codigoReferido}
                                    onChange={handleCodigoReferidoChange}
                                    placeholder="Ingresa el código de referido"
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            {/* Select de Marca: se muestra si no se recibió marcaId por props */}
                            {!marcaId && marcas.length > 0 && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Marca</label>
                                    <select
                                        name="marca"
                                        value={cliente.marca}
                                        onChange={handleChange}
                                        className="shadow border rounded w-full py-2 px-3"
                                    >
                                        {marcas.map(m => (
                                            <option key={m._id} value={m._id}>
                                                {m.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {/* Select de Tienda */}
                            {(!cliente.tienda || (tiendasDisponibles && tiendasDisponibles.length > 1)) && tiendasDisponibles.length > 0 && (
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">Tienda</label>
                                    <select
                                        name="tienda"
                                        value={cliente.tienda}
                                        onChange={handleChange}
                                        className="shadow border rounded w-full py-2 px-3"
                                    >
                                        {tiendasDisponibles.map(t => (
                                            <option key={t._id} value={t._id}>
                                                {t.nombre}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            )}
                            {/* Campos opcionales: Datos geográficos */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">País</label>
                                <input
                                    type="text"
                                    name="geographic_info.country"
                                    value={cliente.geographic_info.country}
                                    onChange={handleChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Estado/Región</label>
                                <input
                                    type="text"
                                    name="geographic_info.state"
                                    value={cliente.geographic_info.state}
                                    onChange={handleChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Ciudad</label>
                                <input
                                    type="text"
                                    name="geographic_info.city"
                                    value={cliente.geographic_info.city}
                                    onChange={handleChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Código Postal</label>
                                <input
                                    type="text"
                                    name="geographic_info.postal_code"
                                    value={cliente.geographic_info.postal_code}
                                    onChange={handleChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                />
                            </div>
                        </div>
                        <div className="flex justify-end mt-4">
                            <button
                                type="button"
                                onClick={onClose}
                                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
                            >
                                Cancelar
                            </button>
                            <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                                Crear
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default CrearClienteForm;
