// src/components/feedback/LikertChart.jsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Registrar módulos
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const LikertChart = ({ data }) => {
  if (!data || !data.labels || !data.datasets) {
    return <p className="p-4">No hay datos para mostrar.</p>;
  }

  const options = {
    responsive: true,
    // ─────────────────────────────────────────────────
    // IMPORTANTE: Para no forzar ancho en móviles
    // ─────────────────────────────────────────────────
    maintainAspectRatio: false,
    scales: {
      x: {
        ticks: {
          // Truncamos labels excesivamente largas
          callback: function (value) {
            const label = this.getLabelForValue(value);
            return label.length > 40 ? label.substring(0, 37) + '...' : label;
          },
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Puntuación Media',
          font: { size: 14 },
        },
      },
    },
    plugins: {
      legend: { position: 'top' },
      title: {
        display: true,
        text: 'Promedio de Respuestas por Pregunta',
      },
    },
  };

  // Lista completa de preguntas
  const questionList = data.labels.map((label, index) => <li key={index}>{label}</li>);

  return (
    <div className="my-8">
      {/* 
        Div contenedor con altura fija en móvil y más alto en desktop.
        w-full => no excede el ancho del contenedor.
        'overflow-x-auto' si fuera necesario, pero en Chart.js no suele hacer falta 
        tras 'maintainAspectRatio: false'.
      */}
      <div className="relative w-full h-64 md:h-96">
        <Bar data={data} options={options} />
      </div>
      <div className="mt-4">
        <h3 className="text-lg font-semibold">Lista Completa de Preguntas:</h3>
        <ol className="list-decimal ml-4">{questionList}</ol>
      </div>
    </div>
  );
};

export default LikertChart;
