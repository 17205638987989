// src/components/modals/CreateStoreModal.jsx
import React, { useState } from 'react';
import Swal from 'sweetalert2';

const CreateStoreModal = ({ isOpen, onClose, onSubmit, marcas }) => {
    const [formData, setFormData] = useState({
        via: '',
        calle: '',
        numero: '',
        poblacion: '',
        pais: '',
        region: '',
        nombre: '',
        descripcion: '',
        marca: marcas && marcas.length > 0 ? marcas[0]._id : '',
    });

    if (!isOpen) return null;

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({ ...prev, [name]: value }));
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        // Validaciones básicas
        if (!formData.nombre || !formData.descripcion) {
            Swal.fire('Error', 'Por favor, complete los campos requeridos.', 'error');
            return;
        }
        onSubmit(formData);
    };

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50 p-4">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-lg p-6 overflow-y-auto">
                <h2 className="text-2xl font-bold text-center mb-6">Crear Tienda</h2>
                <form onSubmit={handleFormSubmit} className="space-y-4">
                    <div>
                        <label className="block text-gray-800 font-semibold mb-1" htmlFor="nombre">
                            Nombre de la Tienda
                        </label>
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            placeholder="Nombre de tu tienda"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-800 font-semibold mb-1" htmlFor="descripcion">
                            Descripción
                        </label>
                        <textarea
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            placeholder="Describe brevemente tu tienda"
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-800 font-semibold mb-1" htmlFor="via">
                            Vía
                        </label>
                        <input
                            type="text"
                            name="via"
                            value={formData.via}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            placeholder="Ej. Avenida, Calle, etc."
                            required
                        />
                    </div>
                    <div>
                        <label className="block text-gray-800 font-semibold mb-1" htmlFor="calle">
                            Nombre de la Calle
                        </label>
                        <input
                            type="text"
                            name="calle"
                            value={formData.calle}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            placeholder="Ej. Gran Vía"
                            required
                        />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-gray-800 font-semibold mb-1" htmlFor="numero">
                                Número
                            </label>
                            <input
                                type="text"
                                name="numero"
                                value={formData.numero}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                placeholder="Ej. 123"
                                required
                            />
                        </div>
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                        <div>
                            <label className="block text-gray-800 font-semibold mb-1" htmlFor="pais">
                                País
                            </label>
                            <select
                                name="pais"
                                value={formData.pais}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                required
                            >
                                <option value="" disabled>
                                    Selecciona país
                                </option>
                                <option value="España">España</option>
                                <option value="Chile">Chile</option>
                                <option value="Peru">Perú</option>
                            </select>
                        </div>
                        <div>
                            <label className="block text-gray-800 font-semibold mb-1" htmlFor="region">
                                Región
                            </label>
                            <input
                                type="text"
                                name="region"
                                value={formData.region}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                placeholder="Ej. Catalunya"
                                required
                            />
                        </div>
                        <div>
                            <label className="block text-gray-800 font-semibold mb-1" htmlFor="poblacion">
                                Ciudad
                            </label>
                            <input
                                type="text"
                                name="poblacion"
                                value={formData.poblacion}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                placeholder="Ej. Sabadell"
                                required
                            />
                        </div>
                    </div>

                    <div>
                        <label className="block text-gray-800 font-semibold mb-1" htmlFor="marca">
                            Marca
                        </label>
                        <select
                            name="marca"
                            value={formData.marca}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        >
                            {marcas.map((marca) => (
                                <option key={marca._id} value={marca._id}>
                                    {marca.nombre}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="flex justify-end mt-6">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-3 hover:bg-gray-600 transition"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition"
                        >
                            Crear Tienda
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateStoreModal;
