// src/pages/common/Dashboard.jsx
import React, { useEffect, useState } from 'react';
import { get, patch, post } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import { FaBolt, FaUserPlus, FaRedoAlt, FaSearch, FaSpinner } from 'react-icons/fa';
import { AiFillGift, AiOutlineBulb } from 'react-icons/ai';
import MiniChart from '../../components/dashboards/MiniChart';
import PredefinedCampaignModal from '../../components/gameLoyalty/PredefinedCampaignModal';
import StrategyReviewModal from '../../components/modals/StrategyReviewModal';

const Dashboard = () => {
  const [marcasStats, setMarcasStats] = useState([]);
  const [showCampaignModal, setShowCampaignModal] = useState(false);
  const [fechaClaveProxima, setFechaClaveProxima] = useState(null);
  const [ultimaEstrategia, setUltimaEstrategia] = useState(null);
  const [strategyForReview, setStrategyForReview] = useState(null);
  const [showStrategyModal, setShowStrategyModal] = useState(false);
  const [strategyLoading, setStrategyLoading] = useState(false);
  const [selectedObjectiveFechaClave, setSelectedObjectiveFechaClave] = useState('');
  const [selectedMarcaId, setSelectedMarcaId] = useState('');
  const [selectedObjectiveSinFecha, setSelectedObjectiveSinFecha] = useState('');
  const [selectedMarcaIdSinFecha, setSelectedMarcaIdSinFecha] = useState('');
  const [campaignLoading, setCampaignLoading] = useState(false);
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const userId = user.userId;

  const multipleMarcas = marcasStats.length > 1;
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const firstDay = new Date(year, month, 1).toISOString().split('T')[0];
  const lastDay = new Date(year, month + 1, 0).toISOString().split('T')[0];

  useEffect(() => {
    fetchAllStatsAtOnce();
  }, []);

  const fetchAllStatsAtOnce = async () => {
    try {
      const responseMarcas = await get('marcas');
      if (!responseMarcas || responseMarcas.length === 0) {
        setMarcasStats([]);
        return;
      }
      const marcaIds = responseMarcas.map((m) => m._id).join(',');
      const combinedData = await get(`statistics/combined?marcaIds=${marcaIds}`);
      const mergedMarcas = responseMarcas.map((marca) => {
        const stats = combinedData.find((item) => item.marcaId === marca._id);
        return {
          ...marca,
          basicStats: stats?.basicStats,
          fidelStats: stats?.fidelStats,
        };
      });
      setMarcasStats(mergedMarcas);
    } catch (error) {
      console.error('Error al obtener estadísticas combinadas:', error);
      Swal.fire({
        title: 'Ocurrió un error',
        text: 'No se pudieron cargar las estadísticas. Intenta nuevamente más tarde.',
        icon: 'error',
        confirmButtonText: 'Aceptar',
      });
    }
  };

  useEffect(() => {
    if (userId) {
      fetchUltimaEstrategia();
    }
  }, [userId]);

  const fetchUltimaEstrategia = async () => {
    try {
      const ultima = await get('asistente-configuracion/ultima');
      setUltimaEstrategia(ultima);
    } catch (error) {
      console.error('Error al obtener la última estrategia:', error);
    }
  };

  useEffect(() => {
    fetchFechaClaveProxima();
  }, []);

  const fetchFechaClaveProxima = async () => {
    try {
      const proximas = await get('fechas-clave/proximas?dias=30');
      const proximasMismoPais = proximas.filter(fc => fc.pais === 'España');
      if (proximasMismoPais.length > 0) {
        setFechaClaveProxima(proximasMismoPais[0]);
      }
    } catch (error) {
      console.error('Error al obtener fechas próximas:', error);
    }
  };

  const openCampaignModal = () => setShowCampaignModal(true);
  const closeCampaignModal = () => setShowCampaignModal(false);

  const getDiasFaltan = (fechaStr) => {
    const hoy = new Date();
    const fechaEv = new Date(fechaStr);
    const diff = fechaEv.getTime() - hoy.getTime();
    const dias = Math.ceil(diff / (1000 * 3600 * 24));
    return dias;
  };

  // Función auxiliar para extraer el _id como cadena
  function getStrategyId(strategy) {
    if (!strategy || !strategy._id) return null;
    if (typeof strategy._id === 'object' && strategy._id.$oid) return strategy._id.$oid;
    return String(strategy._id);
  }

  // Función para verificar si existe una campaña pendiente y preguntar al usuario qué hacer
  async function checkPendingCampaign(marcaId) {
    try {
      const pending = await get(`estrategias/ultima?marcaId=${marcaId}`);
      if (pending && pending._id && pending.estado === 'pendiente') {
        setStrategyForReview(pending);
        setShowStrategyModal(true);
        return true;
      }
      return false;
    } catch (error) {
      console.error('Error verificando campaña pendiente:', error);
      return false;
    }
  }

  async function crearCampaniaConFechaClave(fechaClaveId) {
    if (!selectedObjectiveFechaClave) {
      Swal.fire({
        title: 'Falta objetivo',
        text: 'Selecciona el objetivo que deseas alcanzar',
        icon: 'warning'
      });
      return;
    }
    const marcaToUse = multipleMarcas ? selectedMarcaId : marcasStats[0]._id;
    // Si hay campaña pendiente se abre el modal de revisión y se evita crear una nueva
    const pendingUsed = await checkPendingCampaign(marcaToUse);
    if (pendingUsed) return;

    const body = {
      userId,
      objective: selectedObjectiveFechaClave,
      fechaClaveId,
      marcaId: multipleMarcas ? selectedMarcaId : marcasStats[0]._id,
    };
    try {
      setCampaignLoading(true);
      const result = await post('agents/full-chain', body);
      if (result.ok) {
        setStrategyForReview(result);
        setShowStrategyModal(true);
      } else {
        Swal.fire({
          title: 'Error',
          text: result.message,
          icon: 'error'
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudo crear la campaña.',
        icon: 'error'
      });
    } finally {
      setCampaignLoading(false);
    }
  }

  async function crearCampaniaSinFechaClave() {
    if (!selectedObjectiveSinFecha) {
      Swal.fire({
        title: 'Falta objetivo',
        text: 'Selecciona el objetivo que deseas alcanzar',
        icon: 'warning'
      });
      return;
    }
    const marcaToUse = multipleMarcas ? selectedMarcaIdSinFecha : marcasStats[0]._id;
    const pendingUsed = await checkPendingCampaign(marcaToUse);
    if (pendingUsed) return;

    const body = {
      userId,
      objective: selectedObjectiveSinFecha,
      fechaClaveId: null, // Campaña sin fecha clave
      marcaId: multipleMarcas ? selectedMarcaIdSinFecha : marcasStats[0]._id,
    };
    try {
      setCampaignLoading(true);
      const result = await post('agents/full-chain', body);
      if (result.ok) {
        setStrategyForReview(result);
        setShowStrategyModal(true);
      } else {
        Swal.fire({
          title: 'Error',
          text: result.message,
          icon: 'error'
        });
      }
    } catch (error) {
      console.error(error);
      Swal.fire({
        title: 'Error',
        text: 'No se pudo crear la campaña.',
        icon: 'error'
      });
    } finally {
      setCampaignLoading(false);
    }
  }

  async function handleAcceptStrategy() {
    const strategyId = getStrategyId(strategyForReview);
    if (!strategyId) {
      console.error('No se encontró un strategyId válido');
      return;
    }
    try {
      // Actualiza el estado de la estrategia a "aprobada"
      const updated = await patch(`estrategias/${strategyId}/estado`, { estado: 'aprobada' });
      const finalResult = await post('agents/create-in-system', {
        userId,
        finalStrategy: updated,
      });
      if (finalResult.message) {
        Swal.fire({ title: 'Campaña creada', text: finalResult.message, icon: 'success' });
      }
      setShowStrategyModal(false);
      setStrategyForReview(null);
    } catch (error) {
      console.error(error);
      Swal.fire({ title: 'Error', text: 'No se pudo crear la campaña.', icon: 'error' });
    }
  }

  async function handleRejectStrategy() {
    const strategyId = getStrategyId(strategyForReview);
    if (!strategyId) {
      console.error('No se encontró un strategyId válido');
      return;
    }
    try {
      await patch(`estrategias/${strategyId}/estado`, { estado: 'rechazada' });
      Swal.fire({ title: 'Campaña rechazada', text: 'La campaña pendiente ha sido rechazada.', icon: 'info' });
      setShowStrategyModal(false);
      setStrategyForReview(null);
    } catch (error) {
      console.error(error);
      Swal.fire({ title: 'Error', text: 'No se pudo rechazar la campaña.', icon: 'error' });
    }
  }

  async function handleCancelStrategy() {
    setShowStrategyModal(false);
    setStrategyForReview(null);
  }

  const LoadingModal = () => (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-white p-8 rounded-lg shadow-lg flex flex-col items-center">
        <FaSpinner className="animate-spin text-blue-500" size={48} />
        <p className="mt-4 text-blue-500 font-semibold">
          Procesando campaña, por favor espera...
        </p>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen bg-gray-100 p-6">
      <div className="container mx-auto">
        {/* Encabezado del Dashboard */}
        <header className="mb-6">
          <h1 className="text-2xl md:text-4xl font-bold text-gray-800">
            Bienvenido a tu Panel de Fidelización
          </h1>
          <p className="text-gray-600 mt-2">Tu visión general y acciones recomendadas</p>
        </header>
        {ultimaEstrategia && (
          <section className="bg-gradient-to-r from-blue-100 to-blue-50 p-8 rounded-lg shadow-lg mb-8">
            <div className="flex items-center mb-4">
              <AiOutlineBulb className="text-3xl text-blue-600 mr-2" />
              <h2 className="text-2xl font-bold text-blue-800">Estrategia Activa</h2>
            </div>
            <div className="mb-3">
              <p className="text-gray-600">{ultimaEstrategia.descripcion}</p>
            </div>
            {ultimaEstrategia.fechaClave && (
              <div>
                <h3 className="text-lg font-semibold text-gray-700">Fecha Clave</h3>
                <p className="text-gray-600">
                  {new Date(ultimaEstrategia.fechaClave.fecha).toLocaleDateString()}
                </p>
              </div>
            )}
          </section>
        )}
        {/* Renderización de tarjetas por cada marca */}
        {marcasStats.map((marca) => (
          <section key={marca._id} className="bg-white p-6 rounded-md shadow-md mb-6">
            <div className="flex flex-col md:flex-row md:items-center justify-between">
              <div>
                <h2 className="text-xl md:text-2xl font-semibold text-gray-700">
                  {marca.displayName} - {marca.nombre || 'Sin Nombre'}
                </h2>
                <p className="text-3xl font-bold text-blue-600 mt-2">
                  Nuevos clientes este mes: {marca.fidelStats?.nuevosClientesMes ?? '--'}
                </p>
                <p className="text-sm text-gray-500 mt-2">
                  Total de clientes: {marca.basicStats?.totalClientes} <br />
                  Clientes inactivos recuperados: {marca.fidelStats?.clientesInactivosRecuperados ?? '--'}
                </p>
              </div>
              <div className="mt-4 md:mt-0">
                <p className="text-gray-700 mb-2 font-semibold">Acción recomendada:</p>
                <span className="inline-block bg-blue-100 text-blue-800 px-4 py-2 rounded">
                  {marca.fidelStats?.nuevosClientesMes < marca.fidelStats?.umbralBajoFidelizados
                    ? marca.fidelStats?.recomendacionSiBajo
                    : marca.fidelStats?.nuevosClientesMes < marca.fidelStats?.umbralMedioFidelizados
                      ? marca.fidelStats?.recomendacionSiMedio
                      : marca.fidelStats?.recomendacionSiAlto}
                </span>
              </div>
            </div>
            <MiniChart marcaId={marca._id} from={firstDay} to={lastDay} interval="daily" />
          </section>
        ))}

        {/* Sección: Campaña basada en Fecha Clave */}
        <section className="mb-6">
          {fechaClaveProxima && (
            <div className="bg-white p-4 rounded shadow mb-6">
              <h2 className="text-xl font-semibold text-gray-700 mb-2">
                Próxima Fecha Clave: {fechaClaveProxima.nombre}
              </h2>
              <p>
                Faltan <strong>{getDiasFaltan(fechaClaveProxima.fecha)}</strong> días para {fechaClaveProxima.nombre}.
              </p>
              <div className="mt-4">
                <label className="block text-gray-700 font-semibold mb-1">
                  ¿Qué objetivo deseas alcanzar con la campaña para esta fecha?
                </label>
                <select
                  className="border p-2 w-full"
                  value={selectedObjectiveFechaClave}
                  onChange={(e) => setSelectedObjectiveFechaClave(e.target.value)}
                >
                  <option value="">Selecciona un objetivo</option>
                  <option value="Aumentar el número de ventas">Aumentar el número de ventas</option>
                  <option value="Aumentar el número de nuevos clientes en el negocio">
                    Aumentar el número de nuevos clientes en el negocio
                  </option>
                  <option value="Buscar la fidelización de los clientes con el negocio">
                    Buscar la fidelización de los clientes con el negocio
                  </option>
                </select>
              </div>
              {multipleMarcas && (
                <div className="mt-4">
                  <label className="block text-gray-700 font-semibold mb-1">
                    Selecciona la marca para aplicar la campaña
                  </label>
                  <select
                    className="border p-2 w-full"
                    value={selectedMarcaId}
                    onChange={(e) => setSelectedMarcaId(e.target.value)}
                  >
                    <option value="">Selecciona una marca</option>
                    {marcasStats.map((marca) => (
                      <option key={marca._id} value={marca._id}>
                        {marca.nombre}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="mt-4">
                {campaignLoading ? (
                  <LoadingModal />
                ) : (
                  <button
                    className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    onClick={() => crearCampaniaConFechaClave(fechaClaveProxima._id)}
                  >
                    Crear Campaña para {fechaClaveProxima.nombre}
                  </button>
                )}
              </div>
            </div>
          )}
        </section>

        {/* Sección: Campaña sin Fecha Clave */}
        <section className="mb-6">
          <div className="bg-white p-4 rounded shadow mb-6">
            <h2 className="text-xl font-semibold text-gray-700 mb-2">
              Campaña sin fecha clave
            </h2>
            <div className="mt-4">
              <label className="block text-gray-700 font-semibold mb-1">
                ¿Qué objetivo deseas alcanzar con esta campaña?
              </label>
              <select
                className="border p-2 w-full"
                value={selectedObjectiveSinFecha}
                onChange={(e) => setSelectedObjectiveSinFecha(e.target.value)}
              >
                <option value="">Selecciona un objetivo</option>
                <option value="Aumentar el número de ventas">Aumentar el número de ventas</option>
                <option value="Aumentar el número de nuevos clientes en el negocio">
                  Aumentar el número de nuevos clientes en el negocio
                </option>
                <option value="Buscar la fidelización de los clientes con el negocio">
                  Buscar la fidelización de los clientes con el negocio
                </option>
              </select>
            </div>
            {multipleMarcas && (
              <div className="mt-4">
                <label className="block text-gray-700 font-semibold mb-1">
                  Selecciona la marca para la campaña
                </label>
                <select
                  className="border p-2 w-full"
                  value={selectedMarcaIdSinFecha}
                  onChange={(e) => setSelectedMarcaIdSinFecha(e.target.value)}
                >
                  <option value="">Selecciona una marca</option>
                  {marcasStats.map((marca) => (
                    <option key={marca._id} value={marca._id}>
                      {marca.nombre}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div className="mt-4">
              {campaignLoading ? (
                <LoadingModal />
              ) : (
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                  onClick={crearCampaniaSinFechaClave}
                >
                  Crear Campaña sin fecha clave
                </button>
              )}
            </div>
          </div>
        </section>

        {/* Atajos rápidos y otras secciones existentes */}
        <section className="mb-6">
          <h2 className="text-xl font-semibold text-gray-800 mb-4">Atajos rápidos</h2>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <button
              onClick={() => navigate('/rewards')}
              className="bg-indigo-500 text-white p-4 rounded flex items-center justify-center flex-col hover:bg-indigo-600"
            >
              <AiFillGift size={24} />
              <span className="mt-2">Crear Promoción</span>
            </button>
            <button
              onClick={() => navigate('/clientes')}
              className="bg-green-500 text-white p-4 rounded flex items-center justify-center flex-col hover:bg-green-600"
            >
              <FaSearch size={24} />
              <span className="mt-2">Revisar Clientes</span>
            </button>
            <button
              onClick={() => navigate('/modulo-en-proceso')}
              className="bg-purple-500 text-white p-4 rounded flex items-center justify-center flex-col hover:bg-purple-600"
            >
              <FaBolt size={24} />
              <span className="mt-2">Enviar Email</span>
            </button>
            <button
              onClick={openCampaignModal}
              className="bg-yellow-500 text-white p-4 rounded flex items-center justify-center flex-col hover:bg-yellow-600"
            >
              <FaRedoAlt size={24} />
              <span className="mt-2">Crear Campaña Rápida</span>
            </button>
          </div>
        </section>
      </div>
      {showCampaignModal && (
        <PredefinedCampaignModal
          onClose={closeCampaignModal}
          onCampaignCreated={() => {
            // Actualiza datos del Dashboard si es necesario
          }}
          userMarcaId={marcasStats.length === 1 ? marcasStats[0]._id : ''}
          userTiendaId={
            marcasStats.length === 1 &&
              marcasStats[0].tiendas &&
              marcasStats[0].tiendas.length === 1
              ? marcasStats[0].tiendas[0]._id
              : ''
          }
        />
      )}
      <StrategyReviewModal
        isOpen={showStrategyModal}
        strategy={strategyForReview || { descripcion: '' }}
        loading={strategyLoading}
        onAccept={handleAcceptStrategy}
        onReject={handleRejectStrategy}
        onCancel={handleCancelStrategy}
        isPending={strategyForReview && strategyForReview?.estado === 'pendiente'}
      />
    </div>
  );
};

export default Dashboard;
