import React, { useState, useEffect } from 'react';
import { post, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import useAuthStore from '../../store/authStore';

const initialPrizeLevel = { nombre: '', descripcion: '', cantidad: 0 };

const CreateCampaignModal = ({ isOpen, onClose, onCampaignCreated }) => {
    const [formData, setFormData] = useState({
        nombre: '',
        descripcion: '',
        scope: 'marca', // "marca" o "tienda"
        marca: '',
        tienda: '',
        totalParticipations: 1000,
        participationCondition: '',
        eligibility: 'todos', // "todos" o "clientes_marca"
        prizeLevels: [{ ...initialPrizeLevel }],
    });

    // Estado para almacenar las marcas del usuario (se espera que incluyan un array "tiendas", que en este caso son IDs)
    const [userMarcas, setUserMarcas] = useState([]);
    // Estado para almacenar la marca seleccionada (para cuando el ámbito sea "tienda")
    const [selectedMarcaForTienda, setSelectedMarcaForTienda] = useState(null);
    // Estado para almacenar los detalles de las tiendas (con nombre, etc.) de la marca seleccionada
    const [tiendasDetalles, setTiendasDetalles] = useState([]);

    const { user } = useAuthStore();

    useEffect(() => {
        if (isOpen && user?.userId) {
            const fetchUserMarcas = async () => {
                try {
                    const marcas = await get(`marcas/user/${user.userId}`);
                    setUserMarcas(marcas);
                } catch (error) {
                    console.error('Error al obtener marcas:', error);
                    Swal.fire('Error', 'Error al obtener las marcas: ' + error.message, 'error');
                }
            };
            fetchUserMarcas();
        }
    }, [isOpen, user]);

    // Cuando se actualiza la marca seleccionada y el ámbito es "tienda",
    // se buscan los detalles de cada tienda (por ejemplo, su nombre)
    useEffect(() => {
        if (formData.scope === 'tienda' && selectedMarcaForTienda && selectedMarcaForTienda.tiendas) {
            const fetchTiendasDetalles = async () => {
                try {
                    // Se asume que selectedMarcaForTienda.tiendas es un array de IDs
                    const tiendaPromises = selectedMarcaForTienda.tiendas.map((tiendaId) =>
                        get(`tiendas/${tiendaId}`)
                    );
                    const detalles = await Promise.all(tiendaPromises);
                    setTiendasDetalles(detalles);
                } catch (error) {
                    console.error('Error al obtener detalles de tiendas:', error);
                    Swal.fire('Error', 'Error al obtener detalles de las tiendas: ' + error.message, 'error');
                }
            };
            fetchTiendasDetalles();
        } else {
            setTiendasDetalles([]);
        }
    }, [selectedMarcaForTienda, formData.scope]);

    if (!isOpen) return null;

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: name === 'totalParticipations' ? Number(value) : value,
        }));
    };

    const handlePrizeLevelChange = (index, e) => {
        const { name, value } = e.target;
        const updatedPrizeLevels = formData.prizeLevels.map((level, idx) =>
            idx === index ? { ...level, [name]: name === 'cantidad' ? Number(value) : value } : level
        );
        setFormData((prev) => ({ ...prev, prizeLevels: updatedPrizeLevels }));
    };

    const addPrizeLevel = () => {
        setFormData((prev) => ({
            ...prev,
            prizeLevels: [...prev.prizeLevels, { ...initialPrizeLevel }],
        }));
    };

    const removePrizeLevel = (index) => {
        setFormData((prev) => ({
            ...prev,
            prizeLevels: prev.prizeLevels.filter((_, idx) => idx !== index),
        }));
    };

    // Al cambiar la marca, se actualiza la marca en el formulario y se busca la marca completa
    const handleMarcaChange = (e) => {
        const marcaId = e.target.value;
        setFormData((prev) => ({
            ...prev,
            marca: marcaId,
            // Si el ámbito es "tienda", reiniciamos el campo tienda
            tienda: prev.scope === 'tienda' ? '' : prev.tienda,
        }));
        const marcaSeleccionada = userMarcas.find((m) => m._id === marcaId);
        setSelectedMarcaForTienda(marcaSeleccionada);
    };

    const handleTiendaChange = (e) => {
        const tiendaId = e.target.value;
        setFormData((prev) => ({ ...prev, tienda: tiendaId }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const payload = {
                ...formData,
                totalParticipations: Number(formData.totalParticipations) // Forzamos la conversión
            };
            await post('game-loyalty', payload);
            Swal.fire('Éxito', 'Campaña creada correctamente', 'success');
            setFormData({
                nombre: '',
                descripcion: '',
                scope: 'marca',
                marca: '',
                tienda: '',
                totalParticipations: 1000,
                participationCondition: '',
                eligibility: 'todos',
                prizeLevels: [{ ...initialPrizeLevel }],
            });
            onCampaignCreated();
            onClose();
        } catch (error) {
            console.error('Error al crear campaña:', error);
            Swal.fire('Error', error.message, 'error');
        }
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
            {/* Backdrop */}
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
            {/* Contenedor del modal con scroll vertical */}
            <div className="bg-white rounded shadow-lg z-10 w-full max-w-2xl p-6 max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold">Crear Nueva Campaña</h2>
                    <button onClick={onClose} className="text-gray-600 hover:text-gray-800 text-2xl">
                        &times;
                    </button>
                </div>
                <form onSubmit={handleSubmit}>
                    {/* Nombre */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Nombre</label>
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    {/* Descripción */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Descripción</label>
                        <textarea
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                        />
                    </div>
                    {/* Ámbito */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Ámbito</label>
                        <select
                            name="scope"
                            value={formData.scope}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                        >
                            <option key="opcion-marca" value="marca">
                                Marca
                            </option>
                            <option key="opcion-tienda" value="tienda">
                                Tienda
                            </option>
                        </select>
                    </div>
                    {/* Selección de Marca y, si corresponde, de Tienda */}
                    {formData.scope === 'marca' && (
                        <div className="mb-4">
                            <label className="block mb-1 font-medium">Marca</label>
                            <select
                                name="marca"
                                value={formData.marca}
                                onChange={handleMarcaChange}
                                className="w-full border rounded px-3 py-2"
                                required
                            >
                                <option key="marca-default" value="">
                                    Seleccione una marca
                                </option>
                                {userMarcas.map((m) => (
                                    <option key={m._id} value={m._id}>
                                        {m.nombre}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )}
                    {formData.scope === 'tienda' && (
                        <>
                            <div className="mb-4">
                                <label className="block mb-1 font-medium">Marca</label>
                                <select
                                    name="marca"
                                    value={formData.marca}
                                    onChange={handleMarcaChange}
                                    className="w-full border rounded px-3 py-2"
                                    required
                                >
                                    <option key="marca-default" value="">
                                        Seleccione una marca
                                    </option>
                                    {userMarcas.map((m) => (
                                        <option key={m._id} value={m._id}>
                                            {m.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block mb-1 font-medium">Tienda</label>
                                <select
                                    name="tienda"
                                    value={formData.tienda}
                                    onChange={handleTiendaChange}
                                    className="w-full border rounded px-3 py-2"
                                    required
                                >
                                    {tiendasDetalles.length > 0 ? (
                                        <>
                                            <option key="tienda-default" value="">
                                                Seleccione una tienda
                                            </option>
                                            {tiendasDetalles.map((t) => (
                                                <option key={t._id} value={t._id}>
                                                    {t.nombre}
                                                </option>
                                            ))}
                                        </>
                                    ) : (
                                        <option key="tienda-none" value="">
                                            {selectedMarcaForTienda ? 'No hay tiendas asociadas a esta marca' : 'Seleccione una marca primero'}
                                        </option>
                                    )}
                                </select>
                            </div>
                        </>
                    )}
                    {/* Participaciones Totales */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Participaciones Totales</label>
                        <input
                            type="number"
                            name="totalParticipations"
                            value={formData.totalParticipations}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                            required
                        />
                    </div>
                    {/* Condición de Participación */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Condición de Participación</label>
                        <input
                            type="text"
                            name="participationCondition"
                            value={formData.participationCondition}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                            placeholder="Ej.: una por compra"
                            required
                        />
                    </div>
                    {/* Elegibilidad */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Elegibilidad</label>
                        <select
                            name="eligibility"
                            value={formData.eligibility}
                            onChange={handleInputChange}
                            className="w-full border rounded px-3 py-2"
                        >
                            <option key="elegibilidad-todos" value="todos">
                                Todos
                            </option>
                            <option key="elegibilidad-clientes" value="clientes_marca">
                                Clientes de la Marca
                            </option>
                        </select>
                    </div>
                    {/* Niveles de Premio */}
                    <div className="mb-4">
                        <label className="block mb-1 font-medium">Niveles de Premio</label>
                        {formData.prizeLevels.map((level, index) => (
                            <div key={`nivel-${index}`} className="mb-2 border p-2 rounded">
                                <div className="mb-2">
                                    <label className="block text-sm font-medium">Nombre</label>
                                    <input
                                        type="text"
                                        name="nombre"
                                        value={level.nombre}
                                        onChange={(e) => handlePrizeLevelChange(index, e)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium">Descripción</label>
                                    <input
                                        type="text"
                                        name="descripcion"
                                        value={level.descripcion}
                                        onChange={(e) => handlePrizeLevelChange(index, e)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                <div className="mb-2">
                                    <label className="block text-sm font-medium">Cantidad</label>
                                    <input
                                        type="number"
                                        name="cantidad"
                                        value={level.cantidad}
                                        onChange={(e) => handlePrizeLevelChange(index, e)}
                                        className="w-full border rounded px-2 py-1"
                                        required
                                    />
                                </div>
                                {formData.prizeLevels.length > 1 && (
                                    <button
                                        type="button"
                                        onClick={() => removePrizeLevel(index)}
                                        className="text-red-500 text-sm"
                                    >
                                        Eliminar Nivel de Premio
                                    </button>
                                )}
                            </div>
                        ))}
                        <button
                            type="button"
                            onClick={addPrizeLevel}
                            className="bg-blue-500 text-white px-3 py-1 rounded"
                        >
                            Añadir Nivel de Premio
                        </button>
                    </div>
                    {/* Botones de acción */}
                    <div className="flex flex-col sm:flex-row justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="mb-2 sm:mb-0 mr-0 sm:mr-4 px-4 py-2 border rounded"
                        >
                            Cancelar
                        </button>
                        <button type="submit" className="bg-green-600 text-white px-4 py-2 rounded">
                            Crear Campaña
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default CreateCampaignModal;
