// src/pages/blog/Blog.jsx
import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const posts = [
    {
        slug: "automatizacion-programas-fidelizacion",
        title: "Automatización de Programas de Fidelización: Casos de Éxito y Estrategias Innovadoras",
        description: "Descubre cómo la automatización transforma la relación con tus clientes, optimizando procesos y generando experiencias inolvidables."
    },
    {
        slug: "plataforma-omnicanal-de-fidelizacion",
        title: "Plataforma Omnicanal de Fidelización: Conecta con tus Clientes en Cada Paso",
        description: "Integra diversos canales para ofrecer una experiencia personalizada y coherente que fortalezca la lealtad de tus clientes."
    },
    {
        slug: "programa-fidelizacion-digital-para-comercios",
        title: "Programa de Fidelización Digital para Comercios: Estrategias que Conquistan Clientes",
        description: "Implementa un programa digital que revolucione la experiencia de tus clientes y aumente las ventas en tu comercio."
    },
    {
        slug: "sistema-fidelizacion-integracion-pos-crm",
        title: "Sistema de Fidelización con Integración POS y CRM: La Sinergia Perfecta para tu Negocio",
        description: "Revoluciona la gestión de clientes integrando POS y CRM, y potencia las estrategias de fidelización con datos precisos."
    },
    {
        slug: "software-fidelizacion-pymes",
        title: "Software de Fidelización para Pymes: Potencia tu Negocio",
        description: "Descubre cómo un software de fidelización puede transformar la relación con tus clientes y acelerar el crecimiento de tu pyme."
    }
];

const Blog = () => {
    return (
        <div className="container mx-auto py-12 px-4">
            <Helmet>
                <title>Blog de Fidelización Digital | PetitPrime</title>
                <meta name="description" content="Descubre las últimas tendencias y estrategias en fidelización de clientes, software de lealtad y programas de fidelización digital para pymes y comercios." />
            </Helmet>
            <h1 className="text-4xl font-bold mb-8 text-center text-gray-800">Blog de Fidelización Digital</h1>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                {posts.map((post) => (
                    <div key={post.slug} className="bg-white rounded-lg shadow-lg p-6 transform hover:scale-105 transition-transform duration-300">
                        <h2 className="text-2xl font-semibold mb-3 text-blue-600">
                            <Link to={`/blog/${post.slug}`} className="hover:underline">{post.title}</Link>
                        </h2>
                        <p className="text-gray-700 mb-4">{post.description}</p>
                        <Link to={`/blog/${post.slug}`} className="inline-block text-blue-500 font-bold hover:underline">Leer más &rarr;</Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Blog;
