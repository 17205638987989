// src/pages/cliente/ClientesPage.jsx
import React, { useState, useEffect } from 'react';
import { get, del } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import useAuthStore from '../../store/authStore';
import ClientesSearchAndCreate from '../../components/clients/ClientesSearchAndCreate';
import ClientesTable from '../../components/clients/ClientesTable';
import CrearClienteModal from '../../components/clients/CrearClienteModal';
import CustomerSegmentationFilter from '../../components/clients/CustomerSegmentationFilter';
import { useNavigate } from 'react-router-dom';

function ClientesPage() {
    const { user } = useAuthStore();
    const [clientes, setClientes] = useState([]);
    const [filteredClientes, setFilteredClientes] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    // Opciones por defecto (estándar) con colores fijos
    const [tagOptions, setTagOptions] = useState([
        { value: 'VIP', label: 'VIP', color: 'green' },
        { value: 'Frecuentes', label: 'Frecuentes', color: 'orange' },
        { value: 'Inactivos', label: 'Inactivos', color: 'red' },
    ]);
    const navigate = useNavigate();

    // Función para obtener clientes; recibe un parámetro opcional "tag"
    const fetchClientes = async (tag = 'All') => {
        try {
            let response;
            if (tag === 'All') {
                response = await get('clientes/byUser');
            } else {
                // El backend espera una cadena con etiquetas separadas por comas
                response = await get(`clientes/filter-by-tag?tag=${tag}`);
            }
            setClientes(response);
            setFilteredClientes(response);
            updateTagOptions(response);
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    // Extrae etiquetas únicas de los clientes y las fusiona con las opciones predeterminadas
    const updateTagOptions = (clientesData) => {
        const tagsSet = new Set();
        clientesData.forEach((cliente) => {
            if (cliente.segments && Array.isArray(cliente.segments)) {
                cliente.segments.forEach((tag) => {
                    tagsSet.add(typeof tag === 'object' ? tag.value : tag);
                });
            }
        });
        const defaultOptions = [
            { value: 'VIP', label: 'VIP', color: 'green' },
            { value: 'Frecuentes', label: 'Frecuentes', color: 'orange' },
            { value: 'Inactivos', label: 'Inactivos', color: 'red' },
        ];
        const customOptions = Array.from(tagsSet).map((tag) => ({ value: tag, label: tag }));
        const mergedOptions = [...defaultOptions];
        customOptions.forEach((opt) => {
            if (!mergedOptions.some((o) => o.value === opt.value)) {
                mergedOptions.push(opt);
            }
        });
        setTagOptions(mergedOptions);
    };

    useEffect(() => {
        if (user) {
            fetchClientes();
        }
    }, [user]);

    // Recibe una cadena (con etiquetas separadas por comas)
    const handleSegmentFilter = (tag) => {
        fetchClientes(tag);
    };

    const handleSearch = (term) => {
        setSearchTerm(term);
        const lowerTerm = term.toLowerCase();
        const results = clientes.filter(
            (cliente) =>
                (cliente.nombre && cliente.nombre.toLowerCase().includes(lowerTerm)) ||
                (cliente.email && cliente.email.toLowerCase().includes(lowerTerm)) ||
                (cliente.telefono && cliente.telefono.toString().toLowerCase().includes(lowerTerm))
        );
        setFilteredClientes(results);
    };

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        // Se agrega overflow-x-hidden al contenedor principal para evitar scroll horizontal
        <div className="w-full min-w-0 overflow-x-hidden">
            <div className="container mx-auto px-4">
                <div className="bg-white p-4 md:p-6 rounded-lg shadow-md">
                    <h1 className="text-2xl md:text-4xl font-bold mb-2 md:mb-4">Clientes</h1>
                    <p className="text-lg md:text-xl mb-4">Aquí puedes gestionar tus clientes.</p>

                    {/* Filtro de segmentación */}
                    <CustomerSegmentationFilter onFilter={handleSegmentFilter} options={tagOptions} />

                    {/* Buscador y botón para crear */}
                    <ClientesSearchAndCreate
                        onCreate={openModal}
                        searchTerm={searchTerm}
                        setSearchTerm={handleSearch}
                    />

                    {/* Tabla de clientes con scroll horizontal controlado */}
                    <div className="overflow-x-auto">
                        <ClientesTable
                            clientes={filteredClientes}
                            onDelete={async (id) => {
                                const result = await Swal.fire({
                                    title: '¿Estás seguro?',
                                    text: 'Esta acción no se puede deshacer',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: 'Sí, eliminar',
                                    cancelButtonText: 'Cancelar',
                                });
                                if (result.isConfirmed) {
                                    await del(`clientes/${id}`);
                                    fetchClientes('All');
                                    Swal.fire({
                                        title: 'Eliminado',
                                        text: 'El cliente ha sido eliminado.',
                                        icon: 'success',
                                        confirmButtonText: 'Aceptar',
                                    });
                                }
                            }}
                            fetchClientes={() => fetchClientes('All')}
                        />
                    </div>

                    {/* Modal de creación de cliente */}
                    <CrearClienteModal
                        isOpen={isModalOpen}
                        onClose={closeModal}
                        onClienteCreated={() => fetchClientes('All')}
                    />
                </div>
            </div>
        </div>
    );
}

export default ClientesPage;
