// src/pages/communication/InboxPage.jsx
import React, { useState, useEffect } from 'react';
import InboxTable from '../../components/communication/InboxTable';
import FilterMessages from '../../components/communication/FilterMessages';
import MessageDetailModal from '../../components/communication/MessageDetailModal';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import BackToCommunication from '../../components/communication/BackToCommunication';

function InboxPage() {
    const [messages, setMessages] = useState([]);
    const [selectedMessage, setSelectedMessage] = useState(null);
    const [filters, setFilters] = useState({});

    useEffect(() => {
        fetchMessages();
    }, [filters]);

    const fetchMessages = async () => {
        try {
            const response = await get('communication/user-messages');
            setMessages(response);
        } catch (error) {
            Swal.fire('Error', error.message, 'error');
        }
    };

    return (
        <div>
            <BackToCommunication />
            <h1 className="text-2xl font-bold mb-4">Bandeja de Entrada</h1>
            <FilterMessages onFilter={setFilters} />
            <InboxTable messages={messages} onSelectMessage={setSelectedMessage} />
            {selectedMessage && <MessageDetailModal message={selectedMessage} onClose={() => setSelectedMessage(null)} />}
        </div>
    );
}

export default InboxPage;