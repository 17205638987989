// src/pages/cliente/TicketView.jsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

// CSS inline para animaciones de confeti
const confettiStyles = {
  position: 'absolute',
  width: '0.5rem',
  height: '0.5rem',
  borderRadius: '9999px',
  animation: 'confettiFall 2.5s ease forwards',
};

const Confetti = () => {
  const particles = 15;
  const colors = ['bg-pink-500', 'bg-blue-500', 'bg-green-500', 'bg-yellow-500', 'bg-purple-500'];

  const confettiElements = Array.from({ length: particles }, (_, i) => {
    const left = Math.random() * 100;
    const delay = Math.random() * 0.5;
    const color = colors[Math.floor(Math.random() * colors.length)];
    const size = 6 + Math.random() * 8;

    return (
      <div
        key={i}
        className={`confetti absolute ${color}`}
        style={{
          ...confettiStyles,
          top: '0%',
          left: `${left}%`,
          animationDelay: `${delay}s`,
          width: `${size}px`,
          height: `${size}px`,
        }}
      />
    );
  });

  return (
    <div className="pointer-events-none absolute inset-0 overflow-hidden" style={{ zIndex: 999 }}>
      {confettiElements}
      {/* Keyframes inline para no modificar tailwind.config */}
      <style>{`
        @keyframes confettiFall {
          0% {
            transform: translateY(0) rotate(0deg);
            opacity: 1;
          }
          100% {
            transform: translateY(150vh) rotate(720deg);
            opacity: 0;
          }
        }
      `}</style>
    </div>
  );
};

const TicketView = () => {
  const { ticketUrl } = useParams();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);

  // Renderiza las “estrellas” de puntos
  const renderStars = (currentPoints, totalPoints) => {
    const stars = [];
    for (let i = 0; i < totalPoints; i++) {
      stars.push(
        <span
          key={i}
          className={
            i < currentPoints
              ? 'text-yellow-400 text-2xl sm:text-3xl transition-transform duration-300 hover:scale-125'
              : 'text-gray-300 text-2xl sm:text-3xl'
          }
        >
          &#9733;
        </span>
      );
    }
    return <div className="flex gap-1 justify-center mt-2">{stars}</div>;
  };

  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await get(`tickets/url/${ticketUrl}`);

        setTicket(response);
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: error.message,
          icon: 'error',
          confirmButtonText: 'Aceptar',
        });
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [ticketUrl]);

  if (loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-xl text-gray-700 animate-pulse">Cargando ticket...</div>
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100">
        <div className="text-xl text-red-600">Ticket no encontrado</div>
      </div>
    );
  }

  // Datos de puntos
  const accumulatedPoints = ticket.puntos || 0;
  const totalPointsNeeded = ticket.marca?.puntosTicket || 0;
  const missingPoints = totalPointsNeeded - accumulatedPoints;
  const isPointsType = ticket.tipoFidelizacion === 'PUNTOS';
  const isComplete = missingPoints <= 0;

  // Preparar enlace de Google Maps
  const fullAddress = `${ticket.tienda?.via || ''} ${ticket.tienda?.calle || ''} ${ticket.tienda?.numero || ''
    }, ${ticket.tienda?.poblacion || ''}`.trim();
  const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(
    fullAddress
  )}`;

  console.log(ticket);


  return (
    <div className="min-h-screen bg-gray-50 py-6 px-4 sm:px-8">
      <div className="max-w-screen-md mx-auto relative">
        {/* Si la tarjeta está lista para canjear => confeti */}
        {isPointsType && isComplete && <Confetti />}

        <div className="bg-white rounded-lg shadow-lg p-5 sm:p-8 relative z-10">
          <h1 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-6">
            Tarjeta de Puntos
          </h1>

          {/* Tarjeta de fidelización */}
          {isPointsType ? (
            <div
              className={`rounded-md p-6 mb-8 text-center transition-all duration-500 ${isComplete
                ? 'bg-gradient-to-r from-green-400 to-green-600 text-white scale-105 shadow-xl'
                : 'bg-gradient-to-r from-yellow-100 to-yellow-200 text-gray-700'
                }`}
            >
              <h2 className="text-lg sm:text-xl font-semibold mb-2">
                {ticket.marca?.nombre || 'Marca'}
              </h2>
              <div className="text-sm sm:text-base">
                {accumulatedPoints}/{totalPointsNeeded} Puntos
              </div>
              {/* Estrellas */}
              {renderStars(accumulatedPoints, totalPointsNeeded)}

              {isComplete ? (
                <div className="mt-3 font-bold text-xl sm:text-2xl animate-bounce">
                  ¡Felicidades! Ya puedes canjear tu recompensa
                </div>
              ) : (
                <div className="mt-3 text-base sm:text-lg">
                  Te faltan{' '}
                  <span className="font-semibold">
                    {missingPoints} punto{missingPoints === 1 ? '' : 's'}
                  </span>{' '}
                  para conseguir tu premio
                </div>
              )}
            </div>
          ) : (
            /* Si no es “PUNTOS”, vista simplificada */
            <div className="bg-gradient-to-r from-slate-100 to-slate-200 rounded-md p-6 mb-8 text-center shadow-md">
              <h2 className="text-lg sm:text-xl font-semibold mb-2">
                Tipo de Fidelización: {ticket.tipoFidelizacion}
              </h2>
              <p className="text-sm sm:text-base">
                Puntos Acumulados: <strong>{accumulatedPoints}</strong>
              </p>
            </div>
          )}

          {/* Información del Cliente / Tienda / Otros */}
          <div className="grid gap-6 md:grid-cols-2">
            {/* Cliente */}
            <div className="bg-gray-100 rounded-md p-4 shadow-sm">
              <h2 className="text-lg font-semibold mb-3 text-gray-800">Cliente</h2>
              <p className="text-sm sm:text-base text-gray-700 mb-1">
                <strong>Nombre:</strong> {ticket.cliente?.nombre} {ticket.cliente?.apellido}
              </p>
              <p className="text-sm sm:text-base text-gray-700 mb-1">
                <strong>Email:</strong> {ticket.cliente?.email}
              </p>
              <p className="text-sm sm:text-base text-gray-700">
                <strong>Código de Referido:</strong> {ticket.cliente?.codigoReferido || 'N/A'}
              </p>
            </div>

            {/* Tienda */}
            <div className="bg-gray-100 rounded-md p-4 shadow-sm">
              <h2 className="text-lg font-semibold mb-3 text-gray-800">Tienda</h2>
              <p className="text-sm sm:text-base text-gray-700 mb-1">
                <strong>Nombre:</strong> {ticket.tienda?.nombre}
              </p>
              <p className="text-sm sm:text-base text-gray-700 mb-2">
                <strong>Ubicación:</strong> {fullAddress || 'N/A'}
              </p>
              {fullAddress && (
                <a
                  href={mapsUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-block bg-blue-500 text-white text-xs sm:text-sm py-1 px-2 rounded hover:bg-blue-600 transition-colors"
                >
                  Ver ruta en Google Maps
                </a>
              )}
            </div>

            {/* Marca (solo si no es de tipo PUNTOS) */}
            {!isPointsType && (
              <div className="bg-gray-100 rounded-md p-4 shadow-sm md:col-span-2">
                <h2 className="text-lg font-semibold mb-3 text-gray-800">Marca</h2>
                <p className="text-sm sm:text-base text-gray-700">
                  <strong>Nombre:</strong> {ticket.marca?.nombre}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TicketView;
