import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
    FaInbox,
    FaCog,
    FaChartLine,
    FaPaperPlane,
    FaCalendarAlt
} from 'react-icons/fa';

const CommunicationDashboard = () => {
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-gray-100 p-6">
            <div className="container mx-auto">
                {/* Header */}
                <header className="mb-8 text-center">
                    <h1 className="text-4xl font-bold text-gray-800">Centro de Comunicación</h1>
                    <p className="text-xl text-gray-600 mt-2">
                        Gestiona tus mensajes, métricas y configuración de email.
                    </p>
                </header>

                {/* Sección de Comunicación */}
                <section className="mb-10">
                    <h2 className="text-3xl font-bold text-gray-800 mb-4">Opciones de Comunicación</h2>
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-2xl font-bold text-gray-700 flex items-center">
                                <FaInbox className="text-blue-500 mr-2" /> Bandeja de Entrada
                            </h3>
                            <p className="text-gray-600 my-4">Consulta y responde mensajes de tus clientes.</p>
                            <button onClick={() => navigate('/communication/inbox')} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                                Ver Mensajes
                            </button>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-2xl font-bold text-gray-700 flex items-center">
                                <FaCog className="text-green-500 mr-2" /> Configuración de Email
                            </h3>
                            <p className="text-gray-600 my-4">Configura tu SMTP y DKIM para envíos personalizados.</p>
                            <button onClick={() => navigate('/communication/config')} className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700">
                                Configurar Email
                            </button>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-2xl font-bold text-gray-700 flex items-center">
                                <FaChartLine className="text-indigo-500 mr-2" /> Métricas
                            </h3>
                            <p className="text-gray-600 my-4">Visualiza estadísticas sobre tus comunicaciones.</p>
                            <button onClick={() => navigate('/communication/metrics')} className="bg-indigo-500 text-white px-4 py-2 rounded hover:bg-indigo-700">
                                Ver Métricas
                            </button>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-2xl font-bold text-gray-700 flex items-center">
                                <FaPaperPlane className="text-teal-500 mr-2" /> Enviar Mensaje
                            </h3>
                            <p className="text-gray-600 my-4">Envía mensajes segmentados a tus clientes.</p>
                            <button onClick={() => navigate('/communication/send')} className="bg-teal-500 text-white px-4 py-2 rounded hover:bg-teal-700">
                                Enviar Mensaje
                            </button>
                        </div>

                        <div className="bg-white p-6 rounded-lg shadow-md hover:shadow-xl transition-shadow duration-300">
                            <h3 className="text-2xl font-bold text-gray-700 flex items-center">
                                <FaCalendarAlt className="text-yellow-500 mr-2" /> Programar Mensajes
                            </h3>
                            <p className="text-gray-600 my-4">Organiza envíos de mensajes en una agenda.</p>
                            <button onClick={() => navigate('/communication/schedule')} className="bg-yellow-500 text-white px-4 py-2 rounded hover:bg-yellow-700">
                                Ver Calendario
                            </button>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default CommunicationDashboard;
