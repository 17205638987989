// SMTPConfigForm.jsx
import React, { useState } from 'react';
import { post } from '../../helpers/apiHelper';

const SMTPConfigForm = () => {
    const [form, setForm] = useState({
        smtpHost: '', smtpPort: '', smtpUser: '', smtpPassword: ''
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        await post('email-config', form);
    };

    return (
        <form onSubmit={handleSubmit}>
            <input type="text" placeholder="SMTP Host" onChange={(e) => setForm({ ...form, smtpHost: e.target.value })} />
            <button type="submit">Guardar</button>
        </form>
    );
};

export default SMTPConfigForm;