// SegmentSelector.jsx
import React from 'react';

const SegmentSelector = ({ segments, onSelect }) => {
    return (
        <select onChange={(e) => onSelect(e.target.value)}>
            {segments.map(segment => (
                <option key={segment} value={segment}>{segment}</option>
            ))}
        </select>
    );
};

export default SegmentSelector;