import React, { useEffect, useState, useMemo } from "react";
import { get, post } from "../../helpers/apiHelper";
import Pregunta from "./Pregunta";
import ConfiguracionFinal from "./ConfiguracionFinal";
import BotonAccion from "./BotonAccion";
import aryaImg from "./arya.png";
import { FaTimes } from "react-icons/fa";
import Swal from "sweetalert2";

const AsistenteConfiguracionModal = ({ isOpen, onClose, userId }) => {
    const [preguntas, setPreguntas] = useState([]);
    const [respuestas, setRespuestas] = useState({});
    const [estrategia, setEstrategia] = useState(null); // Estrategia generada por IA
    const [estrategiaConfirmada, setEstrategiaConfirmada] = useState(false);

    // Aquí guardaremos la estrategia FINAL que ya podría estar modificada
    const [estrategiaFinal, setEstrategiaFinal] = useState(null);

    const [currentStep, setCurrentStep] = useState(-1);

    // Datos adicionales
    const [userPais, setUserPais] = useState("");
    const [nombreMarca, setNombreMarca] = useState("");
    const [numTiendas, setNumTiendas] = useState(1);
    const [tiendas, setTiendas] = useState([]);

    // Control de ejecución
    const [ejecutando, setEjecutando] = useState(false);
    const [mensajeEjecucion, setMensajeEjecucion] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [configPrev, setConfigPrev] = useState(null);

    // Calcula las preguntas filtradas basadas en condicionales
    const preguntasFiltradas = useMemo(() => {
        if (!preguntas || preguntas.length === 0) return [];
        return preguntas.filter((pregunta) => {
            if (!pregunta.condicional) return true;
            const { key, value } = pregunta.condicional;
            return respuestas[key] === value;
        });
    }, [preguntas, respuestas]);

    useEffect(() => {
        document.body.style.overflow = isOpen ? "hidden" : "auto";
        return () => {
            document.body.style.overflow = "auto";
        };
    }, [isOpen]);

    useEffect(() => {
        if (userId && isOpen) {
            get("asistente-configuracion/preguntas")
                .then((res) => setPreguntas(res))
                .catch((error) => console.error("Error al obtener preguntas:", error));
        }
    }, [userId, isOpen]);

    // Cargar configuración previa (última estrategia) para mostrarla en el dashboard
    useEffect(() => {
        if (userId) {
            get("asistente-configuracion/ultima")
                .then((data) => setConfigPrev(data))
                .catch((error) =>
                    console.warn("No se encontró configuración previa para el usuario.", error)
                );
        }
    }, [userId]);

    useEffect(() => {
        if (userId) {
            get(`users/${userId}`)
                .then((user) => setUserPais(user.pais))
                .catch((error) => console.error("Error al obtener la ubicación del usuario:", error));
        }
    }, [userId]);

    const getPhonePrefix = (pais) => {
        switch (pais) {
            case "España":
                return "+34";
            case "Perú":
                return "+51";
            case "Chile":
                return "+56";
            default:
                return "";
        }
    };

    const handlePhoneChange = (idx, userInput) => {
        const prefix = getPhonePrefix(tiendas[idx].pais);
        if (!userInput.startsWith(prefix)) {
            const sinPrefijo = userInput.replace(/^(\+\d{2,3})/, "");
            userInput = prefix + sinPrefijo;
        }
        handleTiendaChange(idx, "telefono", userInput);
    };

    const handleSiguiente = () => {
        if (currentStep < preguntasFiltradas.length - 1) {
            setCurrentStep((prev) => prev + 1);
        }
    };

    const handleAnterior = () => {
        setCurrentStep((prev) => (prev > 0 ? prev - 1 : prev));
    };

    const handleRespuesta = (key, value) => {
        setRespuestas((prev) => ({ ...prev, [key]: value }));
    };

    // 1. Generar estrategia IA
    const generarEstrategia = async () => {
        try {
            setIsLoading(true);
            const payload = {
                ...respuestas,
                pais: userPais,
            };
            console.log("Payload:", payload);

            const data = await post("asistente-configuracion/estrategia", payload);
            setEstrategia(data); // Se guarda la estrategia que viene del backend
            setCurrentStep(preguntasFiltradas.length);
        } catch (error) {
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error generando la estrategia.",
            });
        } finally {
            setIsLoading(false);
        }
    };

    // 2. Cuando confirmamos la estrategia en "ConfiguracionFinal"
    const handleConfirmarEstrategia = (estrategiaModificada) => {
        // Guardamos esa estrategia final, ya modificada
        setEstrategiaFinal(estrategiaModificada);
        setEstrategiaConfirmada(true);
        setCurrentStep((prev) => prev + 1);
    };

    const handleNumTiendas = (value) => {
        const n = parseInt(value, 10) || 1;
        setNumTiendas(n);
    };

    const generarTiendas = () => {
        const inicialTiendas = [];
        for (let i = 0; i < numTiendas; i++) {
            inicialTiendas.push({
                via: "Calle",
                calle: "",
                numero: "",
                poblacion: "",
                pais: "España",
                region: "",
                nombre: "",
                descripcion: "",
                telefono: "",
                email: "",
                web: "",
                facebook: "",
                instagram: "",
                workers: [],
            });
        }
        setTiendas(inicialTiendas);
    };

    const handleNextMarcaTiendas = () => {
        if (!nombreMarca.trim()) {
            Swal.fire({
                icon: "warning",
                title: "¡Atención!",
                text: "Debes ingresar el nombre de la marca.",
            });
            return;
        }
        if (numTiendas < 1) {
            Swal.fire({
                icon: "warning",
                title: "¡Atención!",
                text: "El número de tiendas debe ser al menos 1.",
            });
            return;
        }
        generarTiendas();
        setCurrentStep((prev) => prev + 1);
    };

    const handleNextTiendas = () => {
        for (let i = 0; i < tiendas.length; i++) {
            const t = tiendas[i];
            if (
                !t.nombre.trim() ||
                !t.calle.trim() ||
                !t.numero.trim() ||
                !t.poblacion.trim() ||
                !t.region.trim() ||
                !t.email.trim() ||
                !t.telefono.trim()
            ) {
                Swal.fire({
                    icon: "warning",
                    title: "¡Atención!",
                    text: `Completa los campos obligatorios de la Tienda #${i + 1}.`,
                });
                return;
            }
        }
        setCurrentStep((prev) => prev + 1);
    };

    const handleTiendaChange = (idx, field, value) => {
        const copia = [...tiendas];
        copia[idx][field] = value;
        setTiendas(copia);
    };

    const handleTrabajadorChange = (idx, email) => {
        if (!email.trim()) return;
        const copia = [...tiendas];
        if (!copia[idx].workers) {
            copia[idx].workers = [];
        }
        copia[idx].workers.push(email.trim());
        setTiendas(copia);
    };

    // 3. Llamada final al backend
    const ejecutarEstrategiaFinal = async () => {
        setEjecutando(true);
        setMensajeEjecucion("Lo estoy preparando todo para ti, en un momento termino...");

        // Aquí agregamos la estrategia final que el usuario modificó
        const finalData = {
            tipoNegocio: respuestas.tipoNegocio,
            objetivo: respuestas.objetivo,
            incentivo: respuestas.incentivo,
            metodoAcumulacion: respuestas.metodoAcumulacion,
            campaniaPromocional: respuestas.campaniaPromocional,
            notificaciones: respuestas.notificaciones,
            nombreMarca,
            tiendas,
            // Incluir la estrategia final (modificada) en la data
            estrategia: estrategiaFinal,
        };

        try {
            await post("asistente-configuracion/final", finalData);
            setEjecutando(false);
            setMensajeEjecucion("");
            setCurrentStep("finished");
        } catch (error) {
            setEjecutando(false);
            setMensajeEjecucion("");
            Swal.fire({
                icon: "error",
                title: "Error",
                text: "Error ejecutando la estrategia final: " + (error.message || "Desconocido"),
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-60 backdrop-blur-lg p-4">
            <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-xl relative max-h-[85vh] overflow-y-auto">
                {/* Header */}
                <div className="flex justify-between items-center mb-4">
                    <h2 className="text-2xl font-semibold text-gray-800">
                        {currentStep === -1 ? "Bienvenido al Asistente" : "Configuración"}
                    </h2>
                </div>

                {/* Contenido Principal */}
                <div className="flex flex-col items-center">
                    {/* Presentación */}
                    {currentStep === -1 && (
                        <>
                            <img src={aryaImg} alt="Arya" className="w-20 h-20 mb-2" />
                            <p className="text-center text-gray-600 mb-4">
                                Hola, soy Arya, tu asistente virtual. Te ayudaré a configurar tu programa de fidelización.
                            </p>
                            <BotonAccion onClick={handleSiguiente} color="bg-blue-500" text="Comenzar" />
                        </>
                    )}

                    {/* Paso de preguntas */}
                    {typeof currentStep === "number" && currentStep >= 0 && currentStep < preguntasFiltradas.length && (
                        <>
                            {isLoading ? (
                                <div className="my-4 flex flex-col items-center">
                                    <img src={aryaImg} alt="Arya" className="w-20 h-20 mb-2" />
                                    <p className="text-md font-semibold text-gray-600">
                                        Generando la mejor estrategia para ti...
                                    </p>
                                </div>
                            ) : (
                                <div className="w-full">
                                    <div className="flex flex-col items-center">
                                        <img src={aryaImg} alt="Arya" className="w-20 h-20 mb-2" />
                                        <Pregunta
                                            key={preguntasFiltradas[currentStep].key}
                                            pregunta={preguntasFiltradas[currentStep].pregunta}
                                            opciones={preguntasFiltradas[currentStep].opciones}
                                            seleccion={respuestas[preguntasFiltradas[currentStep].key] || ""}
                                            onSeleccion={(value) =>
                                                handleRespuesta(preguntasFiltradas[currentStep].key, value)
                                            }
                                        />
                                    </div>
                                    <div className="flex w-full mt-4">
                                        {currentStep > 0 && (
                                            <BotonAccion onClick={handleAnterior} color="bg-gray-500" text="Anterior" />
                                        )}
                                        <div className="ml-auto">
                                            {currentStep < preguntasFiltradas.length - 1 ? (
                                                <BotonAccion
                                                    onClick={handleSiguiente}
                                                    color="bg-blue-500"
                                                    text="Siguiente"
                                                    disabled={!respuestas[preguntasFiltradas[currentStep].key]}
                                                />
                                            ) : (
                                                <BotonAccion
                                                    onClick={generarEstrategia}
                                                    color="bg-blue-500"
                                                    text="Generar Estrategia"
                                                    disabled={!respuestas[preguntasFiltradas[currentStep].key]}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}

                    {/* Mostrar estrategia generada + permitir edición */}
                    {currentStep === preguntasFiltradas.length && estrategia && !estrategiaConfirmada && (
                        <ConfiguracionFinal
                            estrategia={estrategia}
                            respuestas={respuestas}
                            // Aquí es donde recibimos la estrategia final modificada por el usuario
                            onGuardar={(estrategiaModificada) => handleConfirmarEstrategia(estrategiaModificada)}
                        />
                    )}

                    {/* Paso de marca y nº tiendas */}
                    {currentStep === preguntasFiltradas.length + 1 && estrategiaConfirmada && !ejecutando && (
                        <div className="w-full">
                            <img src={aryaImg} alt="Arya" className="w-16 h-16 mx-auto mb-4" />
                            <h3 className="text-xl font-semibold text-gray-800">📌 ¿Cómo se llama tu marca?</h3>
                            <input
                                className="border p-2 w-full mb-4 text-center"
                                placeholder="Ej: Mi Cafetería"
                                value={nombreMarca}
                                onChange={(e) => setNombreMarca(e.target.value)}
                            />
                            <h3 className="text-xl font-semibold text-gray-800">🏪 ¿Cuántas tiendas?</h3>
                            <input
                                type="number"
                                className="border p-2 w-full mb-4 text-center"
                                min={1}
                                value={numTiendas}
                                onChange={(e) => handleNumTiendas(e.target.value)}
                            />
                            <div className="flex justify-between mt-4">
                                <BotonAccion onClick={handleAnterior} color="bg-gray-500" text="Anterior" />
                                <BotonAccion onClick={handleNextMarcaTiendas} color="bg-blue-500" text="Siguiente" />
                            </div>
                        </div>
                    )}

                    {/* Datos de tiendas */}
                    {currentStep === preguntasFiltradas.length + 2 && !ejecutando && (
                        <div className="w-full">
                            <img src={aryaImg} alt="Arya" className="w-16 h-16 mx-auto mb-2" />
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">Datos de Tus Tiendas</h3>
                            {tiendas.map((t, idx) => (
                                <div key={idx} className="border rounded p-3 mb-2">
                                    <h4 className="font-bold mb-2">Tienda #{idx + 1}</h4>
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Nombre de la tienda *"
                                        value={t.nombre}
                                        onChange={(e) => handleTiendaChange(idx, "nombre", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Calle (ej: Av. Principal) *"
                                        value={t.calle}
                                        onChange={(e) => handleTiendaChange(idx, "calle", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Número *"
                                        value={t.numero}
                                        onChange={(e) => handleTiendaChange(idx, "numero", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Población *"
                                        value={t.poblacion}
                                        onChange={(e) => handleTiendaChange(idx, "poblacion", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Región/Provincia *"
                                        value={t.region}
                                        onChange={(e) => handleTiendaChange(idx, "region", e.target.value)}
                                    />
                                    <select
                                        className="border p-1 w-full mb-1"
                                        value={t.pais}
                                        onChange={(e) => handleTiendaChange(idx, "pais", e.target.value)}
                                    >
                                        <option value="España">España</option>
                                        <option value="Perú">Perú</option>
                                        <option value="Chile">Chile</option>
                                    </select>
                                    <input
                                        type="tel"
                                        className="border p-1 w-full mb-1"
                                        placeholder="Teléfono *"
                                        value={t.telefono}
                                        onChange={(e) => handlePhoneChange(idx, e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Email *"
                                        value={t.email}
                                        onChange={(e) => handleTiendaChange(idx, "email", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Web"
                                        value={t.web}
                                        onChange={(e) => handleTiendaChange(idx, "web", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Facebook"
                                        value={t.facebook}
                                        onChange={(e) => handleTiendaChange(idx, "facebook", e.target.value)}
                                    />
                                    <input
                                        className="border p-1 w-full mb-1"
                                        placeholder="Instagram"
                                        value={t.instagram}
                                        onChange={(e) => handleTiendaChange(idx, "instagram", e.target.value)}
                                    />
                                </div>
                            ))}
                            <div className="flex justify-between mt-4">
                                <BotonAccion onClick={handleAnterior} color="bg-gray-500" text="Anterior" />
                                <BotonAccion onClick={handleNextTiendas} color="bg-blue-500" text="Siguiente" />
                            </div>
                        </div>
                    )}

                    {/* Agregar trabajadores */}
                    {currentStep === preguntasFiltradas.length + 3 && !ejecutando && (
                        <div className="w-full">
                            <img src={aryaImg} alt="Arya" className="w-16 h-16 mx-auto mb-2" />
                            <h3 className="text-xl font-semibold text-gray-800 mb-2">¿Quieres añadir trabajadores?</h3>
                            <p className="text-gray-600 mb-4 text-sm">
                                (Opcional) Introduce el email del trabajador y presiona "Agregar"
                            </p>
                            {tiendas.map((t, idx) => (
                                <div key={idx} className="border rounded p-3 mb-2">
                                    <h4 className="font-bold mb-2">
                                        Trabajadores Tienda #{idx + 1} - {t.nombre || "Sin nombre"}
                                    </h4>
                                    <WorkerAdder onAdd={(email) => handleTrabajadorChange(idx, email)} />
                                    {t.workers && t.workers.length > 0 && (
                                        <ul className="mt-2 text-sm list-disc list-inside text-gray-600">
                                            {t.workers.map((w, i) => (
                                                <li key={i}>{w}</li>
                                            ))}
                                        </ul>
                                    )}
                                </div>
                            ))}
                            <div className="flex justify-between mt-4">
                                <BotonAccion onClick={handleAnterior} color="bg-gray-500" text="Anterior" />
                                <BotonAccion
                                    onClick={!ejecutando ? ejecutarEstrategiaFinal : undefined}
                                    disabled={ejecutando}
                                    color="bg-green-500"
                                    text={ejecutando ? "Procesando..." : "✅ Ejecutar Estrategia"}
                                />
                            </div>
                        </div>
                    )}

                    {/* Mensaje de ejecución */}
                    {ejecutando && (
                        <div className="my-4 flex flex-col items-center">
                            <img src={aryaImg} alt="Arya" className="w-20 h-20 mb-2" />
                            <p className="text-lg text-gray-600">{mensajeEjecucion}</p>
                        </div>
                    )}

                    {/* Pantalla final */}
                    {currentStep === "finished" && !ejecutando && (
                        <div className="my-4 flex flex-col items-center">
                            <img src={aryaImg} alt="Arya" className="w-20 h-20 mb-2" />
                            <p className="text-lg text-gray-600 mb-4">
                                Todo configurado, hora de fidelizar clientes.
                            </p>
                            <button
                                className="bg-green-500 text-white px-4 py-2 rounded-lg hover:opacity-90"
                                onClick={onClose}
                            >
                                OK
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

/** Pequeño componente para añadir emails de trabajadores */
const WorkerAdder = ({ onAdd }) => {
    const [email, setEmail] = useState("");
    return (
        <div className="flex items-center gap-2">
            <input
                className="border p-1 flex-1"
                placeholder="Email trabajador"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
            />
            <BotonAccion
                onClick={() => {
                    if (email.trim()) {
                        onAdd(email.trim());
                        setEmail("");
                    }
                }}
                color="bg-blue-500"
                text="Agregar"
            />
        </div>
    );
};

export default AsistenteConfiguracionModal;
