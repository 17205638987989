// src/components/dashboards/DashboardCustomizable.jsx
import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { get, patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import SegmentationWidget from './SegmentationWidget';

const DashboardCustomizable = ({ userId }) => {
    // Valores por defecto para layout y widgets (incluyendo el de segmentación)
    const defaultLayout = [
        { i: 'segmentation', x: 0, y: 0, w: 6, h: 6 },
        { i: 'widget2', x: 6, y: 0, w: 6, h: 6 },
    ];

    const defaultWidgets = [
        { i: 'segmentation', title: 'Segmentación de Clientes', content: 'SEGMENTATION' },
        { i: 'widget2', title: 'Widget 2', content: 'Contenido del Widget 2' },
    ];

    const [layout, setLayout] = useState([]);
    const [widgets, setWidgets] = useState([]);
    const [configLoaded, setConfigLoaded] = useState(false);

    useEffect(() => {
        const fetchConfig = async () => {
            try {
                const response = await get(`dashboard-config/${userId}`);
                const config = response.config || {};
                const newLayout =
                    config.layout && config.layout.length > 0 ? config.layout : defaultLayout;
                const newWidgets =
                    config.widgets && config.widgets.length > 0 ? config.widgets : defaultWidgets;
                setLayout(newLayout);
                setWidgets(newWidgets);
            } catch (error) {
                console.error('Error fetching dashboard config:', error);
                setLayout(defaultLayout);
                setWidgets(defaultWidgets);
            } finally {
                setConfigLoaded(true);
            }
        };

        fetchConfig();
    }, [userId]);

    const onLayoutChange = (newLayout) => {
        setLayout(newLayout);
    };

    const saveConfig = async () => {
        try {
            const config = { layout, widgets };
            await patch(`dashboard-config/${userId}`, { config });
            Swal.fire('Éxito', 'Configuración del dashboard guardada.', 'success');
        } catch (error) {
            Swal.fire('Error', 'No se pudo guardar la configuración.', 'error');
        }
    };

    if (!configLoaded) return <div>Cargando dashboard...</div>;

    return (
        <div>
            <button onClick={saveConfig} className="bg-blue-500 text-white px-4 py-2 rounded mb-4">
                Guardar Configuración
            </button>
            <GridLayout
                className="layout"
                layout={layout}
                cols={12}
                rowHeight={30}
                width={1200}
                onLayoutChange={onLayoutChange}
            >
                {widgets.map((widget) => {
                    const widgetLayout = layout.find((l) => l.i === widget.i) || { i: widget.i, x: 0, y: 0, w: 4, h: 4 };
                    return (
                        <div key={widget.i} data-grid={widgetLayout}>
                            <div className="bg-white p-4 shadow rounded">
                                <h3 className="text-lg font-bold mb-2">{widget.title}</h3>
                                <div>
                                    {widget.i === 'segmentation' ? (
                                        <SegmentationWidget />
                                    ) : (
                                        <p>{widget.content}</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </GridLayout>
        </div>
    );
};

export default DashboardCustomizable;
