// src/components/gameLoyalty/EmbedCodeModal.jsx
import React from 'react';

const EmbedCodeModal = ({ campaignId, onClose }) => {
    // Ahora la URL apunta a la nueva ruta embebida
    const embedCode = `<iframe src="${window.location.origin}/game-loyalty/embed/${campaignId}" style="width: 100%; height: 600px; border: none;"></iframe>`;

    const handleCopy = () => {
        navigator.clipboard.writeText(embedCode);
        alert("¡Código copiado!");
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white p-6 rounded shadow-lg max-w-lg w-full">
                <h2 className="text-xl font-bold mb-4">Código embebible para tu web</h2>
                <textarea
                    readOnly
                    value={embedCode}
                    className="w-full p-2 border rounded mb-4"
                    rows={4}
                />
                <div className="flex justify-end">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded mr-2"
                    >
                        Cerrar
                    </button>
                    <button
                        onClick={handleCopy}
                        className="bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Copiar Código
                    </button>
                </div>
            </div>
        </div>
    );
};

export default EmbedCodeModal;
