// src/components/modals/SumarPuntosModal.jsx
import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

const SumarPuntosModal = ({ onClose }) => {
    const navigate = useNavigate();
    const [marcas, setMarcas] = useState([]);
    const [tiendas, setTiendas] = useState([]);
    const [selectedMarca, setSelectedMarca] = useState('');
    const [selectedTienda, setSelectedTienda] = useState('');

    useEffect(() => {
        const loadMarcas = async () => {
            try {
                const fetchedMarcas = await get('marcas');
                setMarcas(fetchedMarcas);
                if (fetchedMarcas.length === 1) {
                    setSelectedMarca(fetchedMarcas[0]._id);
                    await loadTiendas(fetchedMarcas[0]._id);
                }
            } catch (error) {
                console.error('Error al cargar marcas:', error);
                Swal.fire({
                    title: 'Error',
                    text: 'No se pudieron cargar las marcas.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        };

        loadMarcas();
    }, []);

    const loadTiendas = async (marcaId) => {
        try {
            const fetchedTiendas = await get(`tiendas?marcaId=${marcaId}`);
            setTiendas(fetchedTiendas);
            if (fetchedTiendas.length === 1) {
                setSelectedTienda(fetchedTiendas[0]._id);
                // Si hay solo una tienda, redirigir inmediatamente
                navigate(`/tiendas/${fetchedTiendas[0]._id}/clientes`);
                onClose();
            }
        } catch (error) {
            console.error('Error al cargar tiendas:', error);
            Swal.fire({
                title: 'Error',
                text: 'No se pudieron cargar las tiendas.',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleMarcaChange = async (e) => {
        const marcaId = e.target.value;
        setSelectedMarca(marcaId);
        setSelectedTienda('');
        await loadTiendas(marcaId);
    };

    const handleTiendaChange = (e) => {
        setSelectedTienda(e.target.value);
    };

    const handleContinue = () => {
        if (!selectedMarca || !selectedTienda) {
            Swal.fire({
                title: 'Error',
                text: 'Debe seleccionar una marca y una tienda.',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        navigate(`/tiendas/${selectedTienda}/clientes`);
        onClose();
    };

    return (
        <div className="fixed z-50 inset-0 overflow-y-auto flex items-center justify-center p-4">
            <div className="fixed inset-0 bg-gray-500 opacity-75" onClick={onClose}></div>
            <div className="bg-white rounded-lg overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:w-3/4 lg:w-1/2 z-20">
                <div className="px-6 py-5">
                    <div className="flex justify-between items-center mb-4">
                        <h3 className="text-xl font-bold">Sumar Puntos</h3>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            ✕
                        </button>
                    </div>
                    <div className="grid grid-cols-1 gap-4">
                        {/* Mostrar select de marcas si hay más de una */}
                        {marcas.length > 1 && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Marca</label>
                                <select
                                    value={selectedMarca}
                                    onChange={handleMarcaChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                >
                                    <option value="">Seleccione una marca</option>
                                    {marcas.map((m) => (
                                        <option key={m._id} value={m._id}>
                                            {m.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                        {/* Mostrar select de tiendas si hay más de una opción */}
                        {tiendas.length > 1 && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Tienda</label>
                                <select
                                    value={selectedTienda}
                                    onChange={handleTiendaChange}
                                    className="shadow border rounded w-full py-2 px-3"
                                >
                                    <option value="">Seleccione una tienda</option>
                                    {tiendas.map((t) => (
                                        <option key={t._id} value={t._id}>
                                            {t.nombre}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        )}
                    </div>
                    {tiendas.length > 0 && (
                        <div className="flex justify-end mt-4">
                            <button
                                onClick={handleContinue}
                                className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600 transition-colors"
                            >
                                Continuar
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SumarPuntosModal;
