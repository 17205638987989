import React, { useEffect, useState } from 'react';
import { get, patch } from '../../helpers/apiHelper';
import useAuthStore from '../../store/authStore';
import SendRewardButton from '../../components/rewards/SendRewardButton';

const AlertPage = () => {
    const [alerts, setAlerts] = useState([]);
    const [marcas, setMarcas] = useState([]);
    const { user } = useAuthStore();

    useEffect(() => {
        const fetchMarcasAndAlerts = async () => {
            if (!user?.userId) return;

            try {
                // Obtener las marcas asociadas al usuario
                const responseMarcas = await get(`marcas/user/${user.userId}`);
                setMarcas(responseMarcas);

                // Si hay marcas, obtener alertas para cada una
                if (responseMarcas.length > 0) {
                    const alertPromises = responseMarcas.map((marca) =>
                        get(`alertas/marca/${marca._id}`)
                    );
                    const allAlerts = (await Promise.all(alertPromises)).flat();
                    setAlerts(allAlerts);
                }
            } catch (error) {
                console.error('Error al obtener marcas o alertas:', error);
            }
        };

        fetchMarcasAndAlerts();
    }, [user]);

    // const markAsRead = async (alertId) => {
    //     try {
    //         await patch(`alertas/${alertId}`);
    //         setAlerts((prevAlerts) => prevAlerts.filter((alert) => alert._id !== alertId));
    //     } catch (error) {
    //         console.error('Error al marcar la alerta como leída:', error);
    //     }
    // };


    return (
        <div className="container mx-auto p-4">
            <h1 className="text-2xl font-bold mb-4">Alertas Activas</h1>
            {alerts.length === 0 ? (
                <p>No hay alertas activas asociadas a tus marcas.</p>
            ) : (
                <ul>
                    {alerts.map((alert) => (
                        <li key={alert._id} className="mb-4 p-4 border border-gray-300 rounded shadow-sm">
                            <p className="text-lg font-medium">{alert.mensaje}</p>
                            <p className="text-sm text-gray-600">Marca: {marcas.find(m => m._id === alert.marca)?.nombre || 'Desconocida'}</p>
                            <SendRewardButton clienteId={alert.cliente} />
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default AlertPage;
