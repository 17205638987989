// src/pages/communication/SchedulePage.jsx
import React from 'react';
import MessageCalendar from '../../components/communication/MessageCalendar';
import ScheduleMessageForm from '../../components/communication/ScheduleMessageForm';
import BackToCommunication from '../../components/communication/BackToCommunication';

function SchedulePage() {
    return (
        <div>
            <BackToCommunication />
            <h1 className="text-2xl font-bold mb-4">Programar Mensajes</h1>
            <MessageCalendar />
            <ScheduleMessageForm />
        </div>
    );
}

export default SchedulePage;