// src/pages/blog/BlogPost.jsx
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useParams, Link } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import matter from 'gray-matter';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';

const BlogPost = () => {
    const { slug } = useParams();
    const [content, setContent] = useState('');
    const [meta, setMeta] = useState({ title: 'Blog Post', description: 'Descripción del post' });

    useEffect(() => {
        import(`../../posts/${slug}.md`)
            .then((module) => fetch(module.default))
            .then((res) => res.text())
            .then((text) => {
                // Usa gray-matter para extraer frontmatter y contenido
                const { data, content: mdContent } = matter(text);
                setContent(mdContent);
                setMeta({
                    title: data.title || 'Blog Post',
                    description: data.description || 'Descripción del post',
                });
            })
            .catch((err) => {
                console.error('Error al cargar el post:', err);
                setContent('# Post no encontrado');
            });
    }, [slug]);

    return (
        <div className="container mx-auto py-12 px-4">
            <Helmet>
                <title>{meta.title} | PetitPrime Blog</title>
                <meta name="description" content={meta.description || meta.title} />
            </Helmet>
            <article className="max-w-4xl mx-auto bg-white p-8 rounded-lg shadow-md">
                <h1 className="text-4xl font-bold mb-6 text-center text-blue-600">{meta.title}</h1>
                <div className="prose prose-lg max-w-full text-gray-800">
                    <ReactMarkdown remarkPlugins={[remarkGfm, remarkBreaks]}>
                        {content}
                    </ReactMarkdown>
                </div>
                <div className="mt-8 text-center">
                    <Link
                        to="/blog"
                        className="inline-block px-6 py-3 bg-blue-500 text-white font-bold rounded-full hover:bg-blue-600 transition-colors duration-300"
                    >
                        &larr; Volver al Blog
                    </Link>
                </div>
            </article>
        </div>
    );
};

export default BlogPost;
