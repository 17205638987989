// src/components/modals/CrearProductoModal.jsx
import React, { useState, useEffect } from 'react';
import { post, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function CrearProductoModal({ isOpen, onClose, onProductoCreated }) {
    const [nombre, setNombre] = useState('');
    const [cantidad, setCantidad] = useState(0);
    const [precio, setPrecio] = useState(0);
    const [descripcion, setDescripcion] = useState('');
    const [tiendas, setTiendas] = useState([]);
    const [selectedTiendas, setSelectedTiendas] = useState([]);

    useEffect(() => {
        if (isOpen) {
            fetchTiendas();
        }
    }, [isOpen]);

    const fetchTiendas = async () => {
        try {
            const response = await get('tiendas');

            setTiendas(response);
        } catch (error) {
            console.error('Error fetching tiendas:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const handleCreate = async () => {
        if (selectedTiendas.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'El producto debe estar asociado a al menos una tienda.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
            return;
        }
        try {
            await post('productos', { nombre, cantidad: Number(cantidad), precio: Number(precio), descripcion, tiendas: selectedTiendas });
            Swal.fire({
                title: 'Producto Creado',
                text: 'El producto ha sido creado exitosamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            onProductoCreated();
            onClose();
        } catch (error) {
            console.error('Error creating producto:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    const toggleTiendaSelection = (tiendaId) => {
        setSelectedTiendas((prevSelected) =>
            prevSelected.includes(tiendaId)
                ? prevSelected.filter((id) => id !== tiendaId)
                : [...prevSelected, tiendaId]
        );
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
            <div className="bg-white p-6 rounded-lg w-1/3">
                <h2 className="text-2xl font-bold mb-4">Crear Producto</h2>
                <label className="block mb-2">Nombre</label>
                <input
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                />
                {/* <label className="block mb-2">Cantidad</label>
                <input
                    type="number"
                    value={cantidad}
                    onChange={(e) => setCantidad(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                /> */}
                <label className="block mb-2">Precio</label>
                <input
                    type="text" // Cambiar a "text" para permitir el ingreso de coma
                    value={precio}
                    onChange={(e) => {
                        const input = e.target.value.replace(',', '.'); // Reemplazar coma por punto
                        if (/^\d*\.?\d*$/.test(input)) { // Validar formato numérico
                            setPrecio(input); // Actualizar el estado con el nuevo valor
                        }
                    }}
                    onBlur={() => {
                        // Convertir el valor a número real en el estado al perder el foco
                        if (precio) {
                            setPrecio(parseFloat(precio) || 0); // Asegurar un número válido
                        }
                    }}
                    className="w-full mb-4 p-2 border rounded"
                />

                <label className="block mb-2">Descripción</label>
                <textarea
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                ></textarea>
                <label className="block mb-2">Tiendas</label>
                <div className="flex flex-wrap gap-2 mb-4">
                    {tiendas.map((tienda) => (
                        <button
                            key={tienda._id}
                            onClick={() => toggleTiendaSelection(tienda._id)}
                            className={`px-4 py-2 rounded ${selectedTiendas.includes(tienda._id) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'}`}
                        >
                            {tienda.nombre}
                        </button>
                    ))}
                </div>
                <div className="flex justify-end space-x-4">
                    <button onClick={onClose} className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700">
                        Cancelar
                    </button>
                    <button onClick={handleCreate} className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700">
                        Crear
                    </button>
                </div>
            </div>
        </div>
    );
}

export default CrearProductoModal;