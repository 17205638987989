// src/components/modals/EditProductoModal.jsx

import React, { useState, useEffect } from 'react';
import { put, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

function EditProductoModal({ isOpen, producto, onClose, onProductoUpdated }) {
    const [nombre, setNombre] = useState(producto.nombre);
    const [cantidad, setCantidad] = useState(producto.cantidad);
    const [precio, setPrecio] = useState(producto.precio);
    const [descripcion, setDescripcion] = useState(producto.descripcion);
    const [tiendas, setTiendas] = useState([]);
    const [selectedTiendas, setSelectedTiendas] = useState(
        producto.tiendas.map((tienda) => tienda._id)
    );

    useEffect(() => {
        if (isOpen && producto) {
            setNombre(producto.nombre);
            setCantidad(producto.cantidad);
            setPrecio(producto.precio);
            setDescripcion(producto.descripcion);
            setSelectedTiendas(producto.tiendas);
            fetchTiendas();
        }
    }, [isOpen, producto]);

    const fetchTiendas = async () => {
        try {
            const response = await get('tiendas');
            setTiendas(response);
        } catch (error) {
            console.error('Error fetching tiendas:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const handleUpdate = async () => {
        if (selectedTiendas.length === 0) {
            Swal.fire({
                title: 'Error',
                text: 'El producto debe estar asociado a al menos una tienda.',
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
            return;
        }
        try {
            await put(`productos/${producto._id}`, {
                nombre,
                cantidad: Number(cantidad),
                precio: Number(precio),
                descripcion,
                tiendas: selectedTiendas,
            });
            Swal.fire({
                title: 'Producto Actualizado',
                text: 'El producto ha sido actualizado exitosamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
            onProductoUpdated();
            onClose();
        } catch (error) {
            console.error('Error updating producto:', error);
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const toggleTiendaSelection = (tiendaId) => {
        setSelectedTiendas((prevSelected) =>
            prevSelected.includes(tiendaId)
                ? prevSelected.filter((id) => id !== tiendaId)
                : [...prevSelected, tiendaId]
        );
    };

    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            {/* 
              Modal con w-full y un máximo ancho 
              (p. ej. max-w-md en móviles => 28rem, 
              o max-w-lg si quieres más ancho).
              También un pequeño padding x-4 para evitar que pegue en el borde.
            */}
            <div className="bg-white p-4 sm:p-6 rounded-lg w-full max-w-md mx-4">
                <h2 className="text-xl sm:text-2xl font-bold mb-4">Editar Producto</h2>

                <label className="block mb-2 font-semibold">Nombre</label>
                <input
                    type="text"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                />

                {/* 
                  Si quieres reactivar el campo 'Cantidad', 
                  quita los comentarios. 
                */}
                {/* 
                <label className="block mb-2 font-semibold">Cantidad</label>
                <input
                    type="number"
                    value={cantidad}
                    onChange={(e) => setCantidad(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                />
                */}

                <label className="block mb-2 font-semibold">Precio</label>
                <input
                    type="text"
                    value={precio}
                    onChange={(e) => {
                        const input = e.target.value.replace(',', '.');
                        if (/^\d*\.?\d*$/.test(input)) {
                            setPrecio(input);
                        }
                    }}
                    onBlur={() => {
                        if (precio) {
                            setPrecio(parseFloat(precio) || 0);
                        }
                    }}
                    className="w-full mb-4 p-2 border rounded"
                />

                <label className="block mb-2 font-semibold">Descripción</label>
                <textarea
                    value={descripcion}
                    onChange={(e) => setDescripcion(e.target.value)}
                    className="w-full mb-4 p-2 border rounded"
                ></textarea>

                <label className="block mb-2 font-semibold">Tiendas</label>
                <div className="flex flex-wrap gap-2 mb-4">
                    {tiendas.map((tienda) =>
                        tienda && tienda._id ? (
                            <button
                                key={tienda._id}
                                onClick={() => toggleTiendaSelection(tienda._id)}
                                className={`px-4 py-2 rounded ${selectedTiendas.includes(tienda._id)
                                        ? 'bg-blue-500 text-white'
                                        : 'bg-gray-200 text-gray-700'
                                    }`}
                            >
                                {tienda.nombre || 'Tienda sin nombre'}
                            </button>
                        ) : null
                    )}
                </div>

                <div className="flex justify-end space-x-4">
                    <button
                        onClick={onClose}
                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                    >
                        Cancelar
                    </button>
                    <button
                        onClick={handleUpdate}
                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
                    >
                        Actualizar
                    </button>
                </div>
            </div>
        </div>
    );
}

export default EditProductoModal;
