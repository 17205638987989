// src/components/statistic/ComparativeDifferenceChart.jsx
import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const ComparativeDifferenceChart = ({ clienteStats, promedioStats }) => {
    const calcDiffPercent = (clienteVal, promedioVal) => {
        if (!promedioVal || promedioVal === 0) return 0;
        return ((clienteVal - promedioVal) / promedioVal) * 100;
    };

    const labels = [
        'LTV',
        'Churn Rate (%)',
        'Retention Rate (%)',
        'Avg Basket Size',
    ];

    const diffData = [
        calcDiffPercent(clienteStats.ltv || 0, promedioStats.ltv || 1),
        calcDiffPercent(clienteStats.churn || 0, promedioStats.churn || 1),
        calcDiffPercent(clienteStats.retention || 0, promedioStats.retention || 1),
        calcDiffPercent(clienteStats.averageBasketSize || 0, promedioStats.averageBasketSize || 1),
    ];

    const data = {
        labels,
        datasets: [
            {
                label: 'Diferencia (%)',
                data: diffData,
                backgroundColor: 'rgba(153, 102, 255, 0.7)',
            },
        ],
    };

    const options = {
        indexAxis: 'y',
        responsive: true,
        plugins: {
            legend: { position: 'top', labels: { font: { size: 14 } } },
            title: { display: true, text: 'Diferencia Porcentual', font: { size: 18 } },
            tooltip: {
                callbacks: {
                    label: (context) => `Diferencia: ${context.parsed.x.toFixed(2)}%`,
                },
            },
        },
    };

    return <Bar data={data} options={options} />;
};

export default ComparativeDifferenceChart;
