// MetricsCards.jsx
import React from 'react';

const MetricsCards = ({ metrics }) => {
    return (
        <div>
            <div>Total Mensajes: {metrics.totalMessages}</div>
            <div>Engagement: {metrics.engagementRate}%</div>
        </div>
    );
};

export default MetricsCards;