// FilterMessages.jsx
import React from 'react';

const FilterMessages = ({ onFilter }) => {
    return (
        <div>
            <input type="text" placeholder="Buscar mensajes..." onChange={(e) => onFilter(e.target.value)} />
        </div>
    );
};

export default FilterMessages;