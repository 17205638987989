// src/components/statistic/DashboardKPISection.jsx
import React from 'react';
import StatCard from './StatCard';

const DashboardKPISection = ({ kpis }) => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            {kpis.map((kpi) => (
                <StatCard
                    key={kpi.title}
                    icon={kpi.icon}
                    title={kpi.title}
                    value={kpi.value}
                    tooltip={kpi.tooltip}
                />
            ))}
        </div>
    );
};

export default DashboardKPISection;
