import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { get, post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import CustomDatePicker from '../../components/general/CustomDatePicker';

const countryOptions = [
    { value: 'España', label: 'España' },
    { value: 'Chile', label: 'Chile' },
    { value: 'Perú', label: 'Perú' },
    { value: 'Otro', label: 'Otro' },
];

const telefonoPrefixOptions = [
    { value: '+34', label: '+34' },
    { value: '+56', label: '+56' },
    { value: '+51', label: '+51' },
];

const QRRegisterPage = () => {
    const { tiendaId } = useParams();
    const [tienda, setTienda] = useState(null);
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        nombre: '',
        apellido: '',
        email: '',
        telefonoPrefix: '+34',
        telefonoNumber: '',
        fechaCumpleanos: '',
        geographic_info: { country: '', state: '', city: '' },
    });
    const [codigoReferido, setCodigoReferido] = useState('');

    useEffect(() => {
        async function fetchTienda() {
            try {
                const data = await get(`tiendas/public/${tiendaId}`);
                setTienda(data);
            } catch (error) {
                console.error('Error fetching tienda:', error);
            }
        }
        fetchTienda();
    }, [tiendaId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.startsWith('geo_')) {
            const key = name.split('_')[1];
            setFormData((prev) => ({
                ...prev,
                geographic_info: { ...prev.geographic_info, [key]: value },
            }));
        } else {
            setFormData((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleCodigoReferidoChange = (e) => {
        setCodigoReferido(e.target.value);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!formData.nombre.trim()) newErrors.nombre = "El nombre es obligatorio.";
        if (!formData.apellido.trim()) newErrors.apellido = "El apellido es obligatorio.";
        if (!formData.email.trim()) newErrors.email = "El correo electrónico es obligatorio.";
        if (!formData.telefonoNumber.trim()) newErrors.telefonoNumber = "El número de teléfono es obligatorio.";
        if (!formData.fechaCumpleanos) newErrors.fechaCumpleanos = "La fecha de nacimiento es obligatoria.";

        const { country, state, city } = formData.geographic_info;
        if ((state || city) && !country) {
            newErrors.geographic_info = "Si ingresas estado o ciudad, debes seleccionar un país.";
        }
        if (city && !state) {
            newErrors.geographic_info = "Si ingresas ciudad, debes ingresar también el estado o región.";
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleRegister = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            Swal.fire('Error', 'Por favor, corrige los errores en el formulario.', 'error');
            return;
        }
        const telefonoCompleto = formData.telefonoPrefix + formData.telefonoNumber;
        const { telefonoPrefix, telefonoNumber, ...rest } = formData;
        const payload = {
            ...rest,
            telefono: telefonoCompleto,
            geographic_info: { ...rest.geographic_info, postal_code: '' },
            marca: tienda.marca,
            tienda: tienda._id,
        };

        if (codigoReferido.trim() !== '') {
            const cleanedCode = codigoReferido.trim().toLowerCase().replace(/\s+/g, '');
            try {
                const refResponse = await get(
                    `clientes/referral?code=${cleanedCode}&marcaId=${tienda.marca}`
                );
                if (!refResponse || !refResponse._id) {
                    Swal.fire({
                        title: 'Error',
                        text: 'El código de referido no es válido o no pertenece a un cliente de tu marca',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                    return;
                }
                payload.referidoPor = refResponse._id;
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al validar el código de referido',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                return;
            }
        }

        try {
            await post('clientes/qr-register', payload);
            Swal.fire('Registro Exitoso', '¡Te has registrado correctamente!', 'success');
            setFormData({
                nombre: '',
                apellido: '',
                email: '',
                telefonoPrefix: '+34',
                telefonoNumber: '',
                fechaCumpleanos: '',
                geographic_info: { country: '', state: '', city: '' },
            });
            setCodigoReferido('');
            setErrors({});
        } catch (error) {
            Swal.fire('Error', error.message, 'error');
        }
    };

    if (!tienda)
        return (
            <div className="min-h-screen flex items-center justify-center">
                Cargando información de la tienda...
            </div>
        );

    return (
        <div className="min-h-screen bg-gray-100 p-4 md:p-8">
            <div className="max-w-3xl mx-auto bg-white rounded-lg shadow p-6">
                <header className="mb-6 text-center">
                    <h1 className="text-3xl font-bold">{tienda.nombre}</h1>
                    <p className="text-gray-700 mt-2">{tienda.descripcion}</p>
                    <p className="text-sm text-gray-500 mt-1">
                        {tienda.via} {tienda.calle}, {tienda.numero}, {tienda.poblacion}, {tienda.pais}, {tienda.region}
                    </p>
                </header>
                <div className="mb-6 text-center">
                    <p className="text-xl font-semibold text-blue-600">
                        ¡Únete a nuestro programa de fidelización y disfruta de ofertas exclusivas!
                    </p>
                </div>
                <div>
                    <h2 className="text-2xl font-semibold mb-4 text-center">Regístrate en nuestra tienda</h2>
                    <form onSubmit={handleRegister} className="space-y-4">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <div>
                                <input
                                    type="text"
                                    name="nombre"
                                    placeholder="Nombre*"
                                    value={formData.nombre}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-3 py-2"
                                    required
                                />
                                {errors.nombre && <p className="text-red-500 text-sm">{errors.nombre}</p>}
                            </div>
                            <div>
                                <input
                                    type="text"
                                    name="apellido"
                                    placeholder="Apellido*"
                                    value={formData.apellido}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-3 py-2"
                                    required
                                />
                                {errors.apellido && <p className="text-red-500 text-sm">{errors.apellido}</p>}
                            </div>
                        </div>
                        <div>
                            <input
                                type="email"
                                name="email"
                                placeholder="Correo electrónico*"
                                value={formData.email}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2"
                                required
                            />
                            {errors.email && <p className="text-red-500 text-sm">{errors.email}</p>}
                        </div>
                        <div className="grid grid-cols-3 gap-2">
                            <select
                                name="telefonoPrefix"
                                value={formData.telefonoPrefix}
                                onChange={handleChange}
                                className="col-span-1 border rounded-lg px-3 py-2"
                                required
                            >
                                {telefonoPrefixOptions.map((opt) => (
                                    <option key={opt.value} value={opt.value}>
                                        {opt.label}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="telefonoNumber"
                                placeholder="Número de teléfono*"
                                value={formData.telefonoNumber}
                                onChange={handleChange}
                                className="col-span-2 border rounded-lg px-3 py-2"
                                required
                            />
                            {errors.telefonoNumber && (
                                <p className="col-span-3 text-red-500 text-sm">{errors.telefonoNumber}</p>
                            )}
                        </div>
                        <div>
                            <CustomDatePicker
                                name="fechaCumpleanos"
                                value={formData.fechaCumpleanos}
                                onChange={handleChange}
                                placeholderText="Fecha de Nacimiento*"
                                required
                            />
                            {errors.fechaCumpleanos && <p className="text-red-500 text-sm">{errors.fechaCumpleanos}</p>}
                        </div>
                        {/* Campo para código de referido */}
                        <div className="mb-4">
                            <input
                                type="text"
                                name="codigoReferido"
                                placeholder="Código de referido (opcional)"
                                value={codigoReferido}
                                onChange={handleCodigoReferidoChange}
                                className="w-full border rounded-lg px-3 py-2"
                            />
                        </div>
                        <h3 className="text-lg font-semibold">Información Geográfica (Opcional)</h3>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                            <select
                                name="geo_country"
                                value={formData.geographic_info.country}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2"
                            >
                                <option value="">Selecciona país</option>
                                {countryOptions.map((c) => (
                                    <option key={c.value} value={c.value}>
                                        {c.label}
                                    </option>
                                ))}
                            </select>
                            <input
                                type="text"
                                name="geo_state"
                                placeholder="Estado/Región (Opcional)"
                                value={formData.geographic_info.state}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2"
                                disabled={!formData.geographic_info.country}
                            />
                            <input
                                type="text"
                                name="geo_city"
                                placeholder="Ciudad (Opcional)"
                                value={formData.geographic_info.city}
                                onChange={handleChange}
                                className="w-full border rounded-lg px-3 py-2"
                                disabled={!formData.geographic_info.country || !formData.geographic_info.state}
                            />
                        </div>
                        {errors.geographic_info && <p className="text-red-500 text-sm">{errors.geographic_info}</p>}
                        <button
                            type="submit"
                            className="bg-blue-600 text-white w-full px-4 py-2 rounded-lg mt-4"
                        >
                            Registrarse
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default QRRegisterPage;
