// src/components/modals/StrategyReviewModal.jsx
import React from 'react';
import { FaSpinner } from 'react-icons/fa';

const StrategyReviewModal = ({ isOpen, strategy, loading, onAccept, onReject, onCancel, isPending }) => {
    if (!isOpen) return null;

    const content = isPending ? strategy.descripcion : strategy.finalStrategy;

    return (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-75 px-4 sm:px-0">
            <div className="bg-white rounded-lg shadow-xl w-full max-w-full sm:max-w-2xl p-4 sm:p-6">
                <h2 className="text-xl sm:text-2xl font-semibold text-gray-800 mb-4">
                    Revisar Estrategia Generada
                </h2>
                {loading ? (
                    <div className="flex flex-col items-center justify-center">
                        <FaSpinner className="animate-spin text-blue-500" size={40} />
                        <p className="mt-4 text-blue-500 font-medium text-sm sm:text-base">
                            Generando estrategia, por favor espera...
                        </p>
                    </div>
                ) : (
                    <>
                        <div className="mb-6 max-h-[60vh] overflow-y-auto">
                            <p className="text-gray-700 text-sm sm:text-base whitespace-pre-wrap">
                                {content}
                            </p>
                        </div>
                        <div className="flex flex-col sm:flex-row sm:justify-end sm:space-x-4">
                            <button
                                onClick={onCancel}
                                className="w-full sm:w-auto px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 mb-2 sm:mb-0"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={onReject}
                                className="w-full sm:w-auto px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400 mb-2 sm:mb-0"
                            >
                                Rechazar
                            </button>
                            <button
                                onClick={onAccept}
                                className="w-full sm:w-auto px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                            >
                                Aceptar Estrategia
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default StrategyReviewModal;
