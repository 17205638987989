// src/components/dashboards/SegmentationWidget.jsx
import React, { useState, useEffect } from 'react';
import { get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const SegmentationWidget = () => {
    const [segments, setSegments] = useState([]);

    useEffect(() => {
        const fetchSegments = async () => {
            try {
                const data = await get('clientes/segments');
                setSegments(data);
            } catch (error) {
                console.error('Error fetching segments:', error);
                Swal.fire('Error', error.message, 'error');
            }
        };
        fetchSegments();
    }, []);

    return (
        <div className="p-4">
            <h3 className="text-lg font-bold mb-2">Segmentación de Clientes</h3>
            {segments.length > 0 ? (
                <ul className="list-disc ml-4">
                    {segments.map(segment => (
                        <li key={segment.id} className="mb-1">
                            <span className="font-semibold">{segment.name}</span> – {segment.count} clientes
                            <br />
                            <small className="text-gray-500">Criterios: {JSON.stringify(segment.criteria)}</small>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>No hay segmentos disponibles.</p>
            )}
        </div>
    );
};

export default SegmentationWidget;
