// src/components/modals/PredefinedCampaignModal.jsx
import React, { useEffect, useState } from 'react';
import useAuthStore from '../../store/authStore';
import { get, post } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';

const PredefinedCampaignModal = ({ onClose, onCampaignCreated, userMarcaId, userTiendaId }) => {
    const { user } = useAuthStore();
    const navigate = useNavigate();
    const [campaigns, setCampaigns] = useState([]);
    const [userMarcas, setUserMarcas] = useState([]);
    const [selectedMarca, setSelectedMarca] = useState("");

    // Obtener las campañas predefinidas
    useEffect(() => {
        const fetchCampaigns = async () => {
            try {
                const data = await get('game-loyalty/predefined-campaigns');
                const campaignsArray = Object.keys(data).map(key => ({ id: key, ...data[key] }));
                setCampaigns(campaignsArray);
            } catch (error) {
                console.error('Error fetching predefined campaigns:', error);
                Swal.fire('Error', 'No se pudieron cargar las campañas predefinidas', 'error');
            }
        };
        fetchCampaigns();
    }, []);

    // Obtener las marcas del usuario (se usa el ID real del usuario)
    useEffect(() => {
        const fetchUserMarcas = async () => {
            try {
                const response = await get(`marcas/user/${user.userId}`);
                setUserMarcas(response);
                if (userMarcaId) {
                    const marcaEncontrada = response.find(marca => String(marca._id) === String(userMarcaId));
                    if (marcaEncontrada) {
                        setSelectedMarca({ value: marcaEncontrada._id, label: marcaEncontrada.nombre });
                    }
                } else if (response.length === 1) {
                    setSelectedMarca({ value: response[0]._id, label: response[0].nombre });
                }
            } catch (error) {
                console.error('Error fetching user marcas:', error);
                Swal.fire('Error', 'No se pudieron cargar las marcas del usuario', 'error');
            }
        };
        if (user) {
            fetchUserMarcas();
        }
    }, [user, userMarcaId]);


    const handleMarcaChange = (selectedOption) => {
        setSelectedMarca(selectedOption.value);
    };

    // Llama al endpoint para crear la campaña predefinida usando la marca seleccionada
    const handleCreateCampaign = async (campaignId) => {
        if (!selectedMarca) {
            Swal.fire('Error', 'Por favor, selecciona una marca', 'error');
            return;
        }
        try {
            const queryParams = new URLSearchParams({
                predefinidoId: campaignId,
                scope: 'marca',
                refId: selectedMarca,
            });
            await post(`game-loyalty/predefined?${queryParams.toString()}`);
            Swal.fire('Éxito', 'Campaña creada correctamente', 'success');
            onCampaignCreated();
            onClose();
        } catch (error) {
            Swal.fire('Error', error.message, 'error');
        }
    };

    return (
        <div className="fixed inset-0 flex items-center justify-center z-50 px-4">
            {/* Fondo semitransparente */}
            <div className="absolute inset-0 bg-black opacity-50" onClick={onClose}></div>
            {/* Contenedor del modal */}
            <div className="relative bg-white rounded-lg shadow-xl transform transition-all w-full max-w-3xl p-6 max-h-[90vh] overflow-y-auto">
                <div className="flex justify-between items-center mb-6">
                    <h2 className="text-2xl font-bold text-gray-800">Campañas Predefinidas</h2>
                    <button
                        onClick={onClose}
                        className="text-gray-600 hover:text-gray-800 text-3xl focus:outline-none"
                        aria-label="Cerrar modal"
                    >
                        &times;
                    </button>
                </div>
                {/* Si hay más de una marca, mostrar dropdown para seleccionar */}
                <div className="mb-4">
                    {userMarcas.length > 1 ? (
                        <>
                            <label className="block text-gray-700 mb-2">Selecciona la Marca:</label>
                            <Select
                                options={userMarcas.map(marca => ({
                                    value: marca._id,
                                    label: marca.nombre,
                                }))}
                                value={selectedMarca}
                                onChange={(option) => setSelectedMarca(option)}
                                placeholder="Selecciona una marca"
                                styles={{
                                    control: (base) => ({
                                        ...base,
                                        color: '#000',
                                        borderColor: '#ccc',
                                    }),
                                    singleValue: (base) => ({
                                        ...base,
                                        color: '#000',
                                    }),
                                    input: (base) => ({
                                        ...base,
                                        color: '#000',
                                    }),
                                    option: (base, state) => ({
                                        ...base,
                                        color: state.isSelected ? '#fff' : '#000',
                                        backgroundColor: state.isSelected ? '#ccc' : '#fff',
                                    }),
                                    placeholder: (base) => ({
                                        ...base,
                                        color: '#000',
                                    }),
                                }}
                                theme={(theme) => ({
                                    ...theme,
                                    colors: {
                                        ...theme.colors,
                                        primary: '#000',
                                        neutral80: '#000',
                                    },
                                })}
                            />
                        </>
                    ) : userMarcas.length === 1 ? (
                        <div>
                            <label className="block text-gray-700 mb-2">Marca:</label>
                            <div className="p-2 border rounded bg-gray-100 text-black">
                                {userMarcas[0].nombre}
                            </div>
                        </div>
                    ) : null}
                </div>

                {/* Si sólo hay una marca, no se muestra el dropdown */}
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {campaigns.map((campaign) => (
                        <div key={campaign.id} className="border rounded-lg p-4 shadow hover:shadow-2xl transition duration-200">
                            <h3 className="text-xl font-semibold text-gray-700 mb-2">{campaign.nombre}</h3>
                            <p className="text-gray-600 text-base mb-4">{campaign.descripcion}</p>
                            <div className="flex justify-between">
                                <button
                                    onClick={() => handleCreateCampaign(campaign.id)}
                                    className="bg-blue-500 text-white py-3 px-4 rounded-lg flex-1 mr-2 hover:bg-blue-600 transition-colors text-center"
                                >
                                    Crear Campaña
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="mt-8">
                    <button
                        onClick={() => {
                            onClose();
                            navigate('/game-loyalty/manage');
                        }}
                        className="w-full bg-gray-500 text-white py-3 px-4 rounded-lg hover:bg-gray-600 transition-colors text-center"
                    >
                        Crear Campaña Personalizada
                    </button>
                </div>
            </div>
        </div>
    );
};

export default PredefinedCampaignModal;
