import React, { useState } from 'react';
import Swal from 'sweetalert2';

const ManageRewardModal = ({ reward, marcas, onClose, onSave }) => {
    const [formData, setFormData] = useState({
        nombre: reward?.nombre || '',
        descripcion: reward?.descripcion || '',
        tipo: reward?.tipo || 'PREMIO',
        puntos: reward?.puntos || '',
        cantidadMonetaria: reward?.cantidadMonetaria || '',
        porcentaje: reward?.porcentaje || '',
        montoMinimo: reward?.montoMinimo || '',
        fechaCaducidad: reward?.fechaCaducidad
            ? new Date(reward.fechaCaducidad).toISOString().split('T')[0]
            : '',
        activo: reward?.activo ?? true,
        marca: reward?.marca._id || marcas[0]?._id || '',
        currency: reward?.currency || 'USD',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;

        let updatedFormData = { ...formData };

        if (type === "checkbox") {
            updatedFormData[name] = checked;
        } else if (["puntos"].includes(name)) {
            // Asegurar que solo sean números enteros
            if (/^\d*$/.test(value)) {
                updatedFormData[name] = value;
            }
        } else if (["cantidadMonetaria", "porcentaje", "montoMinimo"].includes(name)) {
            // Permitir solo números y punto decimal
            if (/^\d*\.?\d*$/.test(value)) {
                updatedFormData[name] = value;
            }
        } else {
            updatedFormData[name] = value;
        }

        // Si el usuario ingresa un monto de descuento, se borra el porcentaje
        if (name === "cantidadMonetaria" && value !== "") {
            updatedFormData.porcentaje = "";
        }

        // Si el usuario ingresa un porcentaje de descuento, se borra el monto
        if (name === "porcentaje" && value !== "") {
            updatedFormData.cantidadMonetaria = "";
        }

        setFormData(updatedFormData);
    };


    const handleSubmit = (e) => {
        e.preventDefault();

        // Verificar que no se ingresen ambos valores a la vez
        if (formData.tipo === 'DESCUENTO' && formData.cantidadMonetaria && formData.porcentaje) {
            Swal.fire('Error', 'No puedes ingresar tanto un monto de descuento como un porcentaje. Escoge solo uno.', 'error');
            return;
        }

        const parsedFormData = {
            ...formData,
            puntos: formData.puntos ? parseInt(formData.puntos, 10) : undefined,
            cantidadMonetaria: formData.cantidadMonetaria
                ? parseFloat(formData.cantidadMonetaria)
                : undefined,
            porcentaje: formData.porcentaje ? parseFloat(formData.porcentaje) : undefined,
            montoMinimo: formData.montoMinimo ? parseFloat(formData.montoMinimo) : undefined,
            fechaCaducidad: formData.fechaCaducidad
                ? new Date(formData.fechaCaducidad)
                : undefined,
        };

        if (!parsedFormData.marca) {
            Swal.fire('Error', 'Debe seleccionar una marca.', 'error');
            return;
        }

        onSave(parsedFormData);
    };


    return (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-3xl mx-4 sm:mx-8 lg:mx-16 p-4 sm:p-6 md:p-8 max-h-[90vh] overflow-y-auto">
                <h2 className="text-2xl font-bold mb-6 text-center">
                    {reward ? 'Editar Recompensa' : 'Crear Recompensa'}
                </h2>
                <form onSubmit={handleSubmit}>
                    {/* Nombre */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Nombre</label>
                        <input
                            type="text"
                            name="nombre"
                            value={formData.nombre}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        />
                    </div>
                    {/* Descripción */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Descripción</label>
                        <textarea
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        />
                    </div>
                    {/* Marca */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Marca</label>
                        <select
                            name="marca"
                            value={formData.marca}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        >
                            <option value="">Seleccionar Marca</option>
                            {marcas.map((marca) => (
                                <option key={marca._id} value={marca._id}>
                                    {marca.nombre}
                                </option>
                            ))}
                        </select>
                    </div>
                    {/* Tipo */}
                    <div className="mb-4">
                        <label className="block text-gray-700 font-semibold mb-2">Tipo</label>
                        <select
                            name="tipo"
                            value={formData.tipo}
                            onChange={handleChange}
                            className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            required
                        >
                            <option value="PREMIO">Premio</option>
                            <option value="DESCUENTO">Descuento</option>
                        </select>
                    </div>
                    {/* Campos adicionales para DESCUENTO */}
                    {formData.tipo === 'DESCUENTO' && (
                        <>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Cantidad Monetaria</label>
                                <input
                                    type="text"
                                    name="cantidadMonetaria"
                                    value={formData.cantidadMonetaria}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                    inputMode="decimal"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Porcentaje</label>
                                <input
                                    type="text"
                                    name="porcentaje"
                                    value={formData.porcentaje}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                    inputMode="decimal"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Monto Mínimo</label>
                                <input
                                    type="text"
                                    name="montoMinimo"
                                    value={formData.montoMinimo}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                    inputMode="decimal"
                                />
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Moneda</label>
                                <select
                                    name="currency"
                                    value={formData.currency}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                >
                                    <option value="USD">Dólares ($)</option>
                                    <option value="EUR">Euros (€)</option>
                                    <option value="PEN">Soles (S/)</option>
                                    <option value="CLP">Pesos Chilenos ($)</option>
                                </select>
                            </div>
                            <div className="mb-4">
                                <label className="block text-gray-700 font-semibold mb-2">Fecha de Caducidad</label>
                                <input
                                    type="date"
                                    name="fechaCaducidad"
                                    value={formData.fechaCaducidad}
                                    onChange={handleChange}
                                    className="w-full border rounded-lg px-4 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                                />
                            </div>
                        </>
                    )}
                    {/* Activo */}
                    <div className="mb-6">
                        <label className="inline-flex items-center">
                            <input
                                type="checkbox"
                                name="activo"
                                checked={formData.activo}
                                onChange={handleChange}
                                className="form-checkbox text-blue-500 focus:ring focus:ring-blue-300"
                            />
                            <span className="ml-2 text-gray-700 font-semibold">Activo</span>
                        </label>
                    </div>
                    {/* Botones */}
                    <div className="flex justify-end">
                        <button
                            type="button"
                            onClick={onClose}
                            className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600 transition"
                        >
                            Cancelar
                        </button>
                        <button
                            type="submit"
                            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                        >
                            Guardar
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ManageRewardModal;
