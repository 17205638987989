// src/App.jsx
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContent from './AppContent';
import { Buffer } from 'buffer';

// Polyfill para Buffer
if (!window.Buffer) {
  window.Buffer = Buffer;
}
function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
