import React, { useState } from "react";
import BotonAccion from "./BotonAccion";
import { post } from "../../helpers/apiHelper";
import ModificarEstrategia from "./ModificarEstrategia";

// Función auxiliar para mapear el objetivo a un texto legible
const mapearObjetivo = (objetivo) => {
    const mapping = {
        MAS_VENTAS: "aumentar las ventas",
        NUEVOS_CLIENTES: "captar nuevos clientes",
        FIDELIZAR_CLIENTES: "fidelizar clientes",
    };
    return mapping[objetivo] || objetivo;
};

// Función que construye el texto de estrategia según la configuración devuelta
const getEstrategiaTexto = (estrategia) => {
    const {
        tipoNegocio,
        pais,
        pointscondition,
        puntosTicket,
        incentivo,
        metodoAcumulacion,
        referidos,
        rewardReferidor,
        rewardReferido,
        objetivo,
        premios,
    } = estrategia;
    const objetivoTexto = mapearObjetivo(objetivo);

    // Construcción de texto para la lista de premios (solo para ACUMULACION)
    const premiosText =
        premios && premios.length > 0
            ? premios
                .map(
                    (premio) =>
                        `${premio.nombre} (canjea con ${premio.puntosNecesarios} puntos: ${premio.descripcion})`
                )
                .join(", ")
            : "los premios serán definidos próximamente";

    if (metodoAcumulacion === "PUNTOS") {
        if (referidos === "SI") {
            return `Te recomendamos implementar una Tarjeta de Puntos en tu ${tipoNegocio} en ${pais}. Cada ${pointscondition} suma ${puntosTicket} puntos que se canjean por un ${incentivo}. Además, con nuestro programa de referidos, recompensarás a tus clientes con ${rewardReferidor} al recomendar, y el referido obtendrá ${rewardReferido}. Esta estrategia está diseñada para alcanzar tu objetivo de ${objetivoTexto}.`;
        } else {
            return `Te recomendamos implementar una Tarjeta de Puntos en tu ${tipoNegocio} en ${pais}. Cada ${pointscondition} suma ${puntosTicket} puntos que se canjean por un ${incentivo}. Con esta estrategia enfocada en ${objetivoTexto}, potenciarás la fidelización y atraerás nuevos clientes.`;
        }
    } else if (metodoAcumulacion === "ACUMULACION") {
        if (referidos === "SI") {
            return `Te recomendamos adoptar nuestro Sistema de Acumulación de Puntos en tu ${tipoNegocio} en ${pais}. Por cada ${pointscondition}, tus clientes acumularán puntos que podrán canjear por premios. Además, al activar el programa de referidos, premiarás a quienes recomienden con ${rewardReferidor} y al nuevo cliente con ${rewardReferido}. Esta estrategia está pensada para lograr ${objetivoTexto}. Los premios disponibles son: ${premiosText}.`;
        } else {
            return `Te recomendamos adoptar nuestro Sistema de Acumulación de Puntos en tu ${tipoNegocio} en ${pais}. Por cada ${pointscondition}, tus clientes acumularán puntos que podrán canjear por premios. Esta estrategia, enfocada en ${objetivoTexto}, te ayudará a fidelizar a tus clientes y atraer nuevos compradores. Los premios disponibles son: ${premiosText}.`;
        }
    }
    return estrategia.descripcion;
};

const ConfiguracionFinal = ({ estrategia, respuestas, onGuardar }) => {
    const [editar, setEditar] = useState(false);
    const [estrategiaModificada, setEstrategiaModificada] = useState(estrategia);

    // Cuando confirmamos la estrategia, en lugar de llamar al backend directamente,
    // simplemente devolvemos la estrategiaModificada al componente padre
    const handleConfirmarEstrategia = () => {
        onGuardar(estrategiaModificada);
    };

    const estrategiaTexto = getEstrategiaTexto(estrategiaModificada);

    return (
        <div className="w-full">
            {!editar ? (
                <>
                    <h3 className="text-2xl font-bold text-gray-800 mb-4 text-center">
                        📋 Tu Plan de Fidelización
                    </h3>
                    <p className="text-gray-600 mb-6 text-center">
                        Basado en tus respuestas, hemos preparado esta estrategia para tu negocio.
                    </p>
                    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                        <div className="bg-blue-100 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg font-semibold text-blue-800">📌 Negocio</h4>
                            <p className="text-gray-700">{respuestas.tipoNegocio}</p>
                        </div>
                        <div className="bg-green-100 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg font-semibold text-green-800">🎯 Objetivo</h4>
                            <p className="text-gray-700">{respuestas.objetivo}</p>
                        </div>
                        <div className="bg-yellow-100 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg font-semibold text-yellow-800">🎁 Incentivo</h4>
                            <p className="text-gray-700">{respuestas.incentivo}</p>
                        </div>
                        <div className="bg-purple-100 p-4 rounded-lg shadow-md">
                            <h4 className="text-lg font-semibold text-purple-800">
                                📊 Método de Acumulación
                            </h4>
                            <p className="text-gray-700">{respuestas.metodoAcumulacion}</p>
                        </div>
                    </div>

                    <div className="mt-6 bg-gray-100 p-4 rounded-lg shadow-md">
                        <h4 className="text-lg font-semibold text-gray-800">🚀 Estrategia Sugerida</h4>
                        <p className="text-gray-700">{estrategiaTexto}</p>
                    </div>

                    <div className="mt-6 flex flex-col sm:flex-row gap-3">
                        <BotonAccion
                            onClick={() => setEditar(true)}
                            color="bg-blue-500"
                            text="✏️ Modificar Estrategia"
                        />
                        <BotonAccion
                            onClick={handleConfirmarEstrategia}
                            color="bg-green-500"
                            text="✅ Confirmar Estrategia"
                        />
                    </div>
                </>
            ) : (
                // Pantalla de modificación de estrategia
                <ModificarEstrategia
                    estrategia={estrategiaModificada}
                    setEstrategia={setEstrategiaModificada}
                    onCancel={() => setEditar(false)}
                    onSave={() => setEditar(false)}
                />
            )}
        </div>
    );
};

export default ConfiguracionFinal;
