import React, { useState } from 'react';
import { post, get } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';

const CrearClienteModal = ({ isOpen, onClose, onClienteCreated }) => {
    const [cliente, setCliente] = useState({
        nombre: '',
        apellido: '',
        email: '',
        // Se completará concatenando el prefijo con el número
        telefono: '',
        fechaCumpleanos: '',
        geographic_info: {
            country: '',
            state: '',
            city: '',
            postal_code: ''
        }
        // Si en algún caso se requiere "marca", se puede incluir aquí
    });
    const [codigoReferido, setCodigoReferido] = useState('');
    // Estados para manejo del prefijo de teléfono
    const [phonePrefix, setPhonePrefix] = useState('+34'); // Por defecto: España
    const [customPrefix, setCustomPrefix] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name.includes('geographic_info')) {
            const [group, key] = name.split('.');
            setCliente((prev) => ({
                ...prev,
                [group]: { ...prev[group], [key]: value }
            }));
        } else {
            setCliente((prev) => ({ ...prev, [name]: value }));
        }
    };

    const handleCodigoReferidoChange = (e) => {
        setCodigoReferido(e.target.value);
    };

    const handlePrefixChange = (e) => {
        setPhonePrefix(e.target.value);
        if (e.target.value !== 'otros') {
            setCustomPrefix('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        // Validar que se proporcione email y teléfono (obligatorios)
        if (!cliente.email || !cliente.telefono) {
            Swal.fire({
                title: 'Error',
                text: 'Debe proporcionar al menos un email y un teléfono',
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
            return;
        }
        // Construir el teléfono completo
        const finalPrefix = phonePrefix === 'otros' ? customPrefix : phonePrefix;
        const telefonoCompleto = `${finalPrefix}${cliente.telefono}`;
        const clienteToSend = { ...cliente, telefono: telefonoCompleto };

        // Si se ingresó código de referido, validarlo
        if (codigoReferido.trim() !== '') {
            const cleanedCode = codigoReferido.trim().toLowerCase().replace(/\s+/g, '');
            try {
                const refResponse = await get(
                    `clientes/referral?code=${cleanedCode}&marcaId=${clienteToSend.marca}`
                );
                if (!refResponse || !refResponse._id) {
                    Swal.fire({
                        title: 'Error',
                        text: 'El código de referido no es válido o no pertenece a un cliente de tu marca',
                        icon: 'error',
                        confirmButtonText: 'Aceptar'
                    });
                    return;
                }
                clienteToSend.referidoPor = refResponse._id;
            } catch (error) {
                Swal.fire({
                    title: 'Error',
                    text: 'Error al validar el código de referido',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
                return;
            }
        }

        try {
            await post('clientes', clienteToSend);
            Swal.fire({
                title: 'Éxito',
                text: 'Cliente creado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar'
            });
            onClienteCreated();
            onClose();
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar'
            });
        }
    };

    if (!isOpen) return null;

    return (
        <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen p-4 text-center sm:p-0">
                {/* Fondo oscuro */}
                <div
                    className="fixed inset-0 transition-opacity"
                    aria-hidden="true"
                    onClick={onClose}
                >
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>
                {/* Contenedor del modal */}
                <div
                    className="bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all w-full max-w-md sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-title"
                >
                    <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div className="w-full">
                            <h3
                                className="text-lg leading-6 font-medium text-gray-900 mb-4"
                                id="modal-title"
                            >
                                Crear Cliente
                            </h3>
                            <form onSubmit={handleSubmit}>
                                <div className="grid grid-cols-1 gap-4">
                                    {/* Campos obligatorios */}
                                    <input
                                        type="text"
                                        name="nombre"
                                        placeholder="Nombre *"
                                        value={cliente.nombre}
                                        onChange={handleChange}
                                        required
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    <input
                                        type="text"
                                        name="apellido"
                                        placeholder="Apellido *"
                                        value={cliente.apellido}
                                        onChange={handleChange}
                                        required
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    <input
                                        type="email"
                                        name="email"
                                        placeholder="Correo electrónico *"
                                        value={cliente.email}
                                        onChange={handleChange}
                                        required
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    {/* Teléfono con prefijo */}
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Teléfono *
                                        </label>
                                        <div className="flex">
                                            <select
                                                name="phonePrefix"
                                                value={phonePrefix}
                                                onChange={handlePrefixChange}
                                                className="shadow border rounded-l w-32 py-2 px-3 text-sm"
                                            >
                                                <option value="+34">España (+34)</option>
                                                <option value="+51">Perú (+51)</option>
                                                <option value="+56">Chile (+56)</option>
                                                <option value="otros">Otros</option>
                                            </select>
                                            {phonePrefix === 'otros' && (
                                                <input
                                                    type="text"
                                                    name="customPrefix"
                                                    value={customPrefix}
                                                    onChange={(e) => setCustomPrefix(e.target.value)}
                                                    placeholder="Prefijo"
                                                    className="shadow border rounded-l w-24 py-2 px-3 text-sm"
                                                />
                                            )}
                                            <input
                                                type="text"
                                                name="telefono"
                                                value={cliente.telefono}
                                                onChange={handleChange}
                                                required
                                                placeholder="Número"
                                                className="shadow border rounded-r w-full py-2 px-3"
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="block text-sm font-medium text-gray-700">
                                            Fecha de Cumpleaños *
                                        </label>
                                        <input
                                            type="date"
                                            name="fechaCumpleanos"
                                            placeholder="Fecha de Cumpleaños *"
                                            value={cliente.fechaCumpleanos}
                                            onChange={handleChange}
                                            className="w-full border rounded-lg px-3 py-2"
                                        />
                                    </div>
                                    {/* Código de referido (opcional) */}
                                    <input
                                        type="text"
                                        name="codigoReferido"
                                        placeholder="Código de referido (opcional)"
                                        value={codigoReferido}
                                        onChange={handleCodigoReferidoChange}
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    {/* Datos geográficos (opcional) */}
                                    <input
                                        type="text"
                                        name="geographic_info.country"
                                        placeholder="País (opcional)"
                                        value={cliente.geographic_info.country}
                                        onChange={handleChange}
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    <input
                                        type="text"
                                        name="geographic_info.state"
                                        placeholder="Estado/Región (opcional)"
                                        value={cliente.geographic_info.state}
                                        onChange={handleChange}
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    <input
                                        type="text"
                                        name="geographic_info.city"
                                        placeholder="Ciudad (opcional)"
                                        value={cliente.geographic_info.city}
                                        onChange={handleChange}
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                    <input
                                        type="text"
                                        name="geographic_info.postal_code"
                                        placeholder="Código Postal (opcional)"
                                        value={cliente.geographic_info.postal_code}
                                        onChange={handleChange}
                                        className="w-full border rounded-lg px-3 py-2"
                                    />
                                </div>
                                <div className="flex justify-end mt-4">
                                    <button
                                        type="button"
                                        onClick={onClose}
                                        className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-700 mr-2"
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        type="submit"
                                        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700"
                                    >
                                        Crear
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CrearClienteModal;
