// src/components/tables/ClientesTable.jsx
import React, { useState } from 'react';
import { FaTrash, FaEdit, FaEnvelope, FaChartBar } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import EditClienteModal from '../modals/EditClienteModal';
import { patch } from '../../helpers/apiHelper';
import Swal from 'sweetalert2';
import RedeemRewardButton from '../rewards/RedeemRewardButton';
import SendRewardButton from '../rewards/SendRewardButton';

const ClientesTable = ({ clientes, onDelete, fetchClientes }) => {
    const [editingCliente, setEditingCliente] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const navigate = useNavigate();

    const handleEditCliente = (cliente) => {
        setEditingCliente(cliente);
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setEditingCliente(null);
        setShowModal(false);
    };

    const handleSaveCliente = async (updatedCliente) => {
        try {
            const { _id, tickets, fechaCreacion, __v, codigoReferido, geographic_info, ...rest } = updatedCliente;
            const payload = {
                ...rest,
                geographic_info,
            };
            await patch(`clientes/${_id}`, payload);
            fetchClientes();
            handleCloseModal();
            Swal.fire({
                title: 'Éxito',
                text: 'Cliente actualizado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const handleResendTicket = async (clienteId) => {
        try {
            const response = await patch(`tickets/cliente/${clienteId}/resend`);
            Swal.fire({
                title: 'Éxito',
                text: response.message || 'Ticket reenviado correctamente',
                icon: 'success',
                confirmButtonText: 'Aceptar',
            });
        } catch (error) {
            Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Aceptar',
            });
        }
    };

    const handleViewStatistics = (clienteId) => {
        navigate(`/statistics/cliente/${clienteId}`);
    };

    return (
        <div className="w-full">
            {/* 
              ─────────────────────────────────────────────────
              VERSIÓN DE ESCRITORIO (MD+): TABLA CLÁSICA
              ─────────────────────────────────────────────────
            */}
            <div className="hidden md:block overflow-x-auto">
                <table className="min-w-full w-full table-auto">
                    <thead>
                        <tr className="bg-gray-100">
                            <th className="px-4 py-2 text-left">Nombre</th>
                            <th className="px-4 py-2 text-left">Apellido</th>
                            <th className="px-4 py-2 text-left">Email</th>
                            <th className="px-4 py-2 text-left">Teléfono</th>
                            <th className="px-4 py-2 text-left">Cumpleaños</th>
                            <th className="px-4 py-2 text-left">Etiquetas</th>
                            <th className="px-4 py-2 text-left">Acciones</th>
                        </tr>
                    </thead>
                    <tbody>
                        {clientes.map((cliente) => (
                            <tr key={cliente._id} className="border-t border-gray-300">
                                <td className="px-4 py-2">{cliente.nombre}</td>
                                <td className="px-4 py-2">{cliente.apellido}</td>
                                <td className="px-4 py-2">{cliente.email}</td>
                                <td className="px-4 py-2">{cliente.telefono || 'N/A'}</td>
                                <td className="px-4 py-2">
                                    {cliente.fechaCumpleanos
                                        ? new Date(cliente.fechaCumpleanos).toLocaleDateString()
                                        : 'N/A'}
                                </td>
                                <td className="px-4 py-2">
                                    {cliente.segments && cliente.segments.length > 0 ? (
                                        cliente.segments.map((seg, index) => (
                                            <span
                                                key={index}
                                                style={{ backgroundColor: seg.color || '#777' }}
                                                className="inline-block text-white text-xs px-2 py-1 rounded mr-1 mb-1"
                                            >
                                                {seg.label}
                                            </span>
                                        ))
                                    ) : (
                                        <span className="text-gray-500 text-xs">Sin etiquetas</span>
                                    )}
                                </td>
                                <td className="px-4 py-2 flex space-x-2">
                                    <button
                                        onClick={() => handleEditCliente(cliente)}
                                        className="bg-blue-500 text-white px-3 py-1 rounded hover:bg-blue-700"
                                    >
                                        <FaEdit />
                                    </button>
                                    <button
                                        onClick={() => handleResendTicket(cliente._id)}
                                        className="bg-green-500 text-white px-3 py-1 rounded hover:bg-green-700"
                                    >
                                        <FaEnvelope />
                                    </button>
                                    <button
                                        onClick={() => handleViewStatistics(cliente._id)}
                                        className="bg-indigo-500 text-white px-3 py-1 rounded hover:bg-indigo-700"
                                    >
                                        <FaChartBar />
                                    </button>
                                    <SendRewardButton clienteId={cliente._id} />
                                    <RedeemRewardButton
                                        clienteId={cliente._id}
                                        recompensaId={cliente.recompensaId}
                                    />
                                    <button
                                        onClick={() => onDelete(cliente._id)}
                                        className="bg-red-500 text-white px-3 py-1 rounded hover:bg-red-700"
                                    >
                                        <FaTrash />
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {/* 
              ─────────────────────────────────────────────────
              VERSIÓN MÓVIL (POR DEBAJO DE MD): TARJETAS SIMPLIFICADAS
              ─────────────────────────────────────────────────
            */}
            <div className="md:hidden space-y-4 px-4 py-2">
                {clientes.map((cliente) => (
                    <div
                        key={cliente._id}
                        className="bg-white border border-gray-300 rounded-lg shadow-sm p-4"
                    >
                        {/* Datos principales del cliente */}
                        <div className="mb-3">
                            <h2 className="text-lg font-bold truncate text-gray-800 mb-1">
                                {cliente.nombre} {cliente.apellido}
                            </h2>
                            <p className="text-sm text-gray-700">
                                <span className="font-semibold">Email:</span> {cliente.email}
                            </p>
                            <p className="text-sm text-gray-700">
                                <span className="font-semibold">Teléfono:</span>{' '}
                                {cliente.telefono || 'N/A'}
                            </p>
                            <p className="text-sm text-gray-700">
                                <span className="font-semibold">Cumpleaños:</span>{' '}
                                {cliente.fechaCumpleanos
                                    ? new Date(cliente.fechaCumpleanos).toLocaleDateString()
                                    : 'N/A'}
                            </p>
                            {/* Etiquetas (segments) */}
                            <div className="mt-2 flex flex-wrap gap-2">
                                {cliente.segments && cliente.segments.length > 0 ? (
                                    cliente.segments.map((seg, index) => (
                                        <span
                                            key={index}
                                            style={{ backgroundColor: seg.color || '#777' }}
                                            className="text-white text-xs px-2 py-1 rounded"
                                        >
                                            {seg.label}
                                        </span>
                                    ))
                                ) : (
                                    <span className="text-gray-500 text-xs">Sin etiquetas</span>
                                )}
                            </div>
                        </div>

                        {/* 
                          BOTONES MÓVIL:
                          En este ejemplo se reducen las opciones para no abrumar.
                          Dejamos sólo Editar y Eliminar. 
                          Si quieres más, quítales la clase hidden o agrégalos aquí directamente.
                        */}
                        <div className="grid grid-cols-2 gap-2">
                            {/* Botón Editar */}
                            <button
                                onClick={() => handleEditCliente(cliente)}
                                className="flex items-center justify-center bg-blue-600 text-white py-2 rounded hover:bg-blue-700 transition-colors"
                            >
                                <FaEdit className="mr-1" />
                                <span>Editar</span>
                            </button>

                            {/* Botón Eliminar */}
                            <button
                                onClick={() => onDelete(cliente._id)}
                                className="flex items-center justify-center bg-red-600 text-white py-2 rounded hover:bg-red-700 transition-colors"
                            >
                                <FaTrash className="mr-1" />
                                <span>Eliminar</span>
                            </button>
                        </div>
                        <div className="mt-2 flex flex-col gap-2">
                            <button
                                onClick={() => handleResendTicket(cliente._id)}
                                className="w-full flex items-center justify-center bg-green-600 text-white py-2 rounded hover:bg-green-700 transition-colors"
                            >
                                <FaEnvelope className="mr-1" />
                                <span>Reenviar</span>
                            </button>
                            <button
                                onClick={() => handleViewStatistics(cliente._id)}
                                className="w-full flex items-center justify-center bg-indigo-500 text-white py-2 rounded hover:bg-indigo-700 transition-colors"
                            >
                                <FaChartBar className="mr-1" />
                                <span>Estadísticas</span>
                            </button>
                        </div>
                        <div className="mt-2">
                            <SendRewardButton clienteId={cliente._id} />
                        </div>
                        <div className="mt-2">
                            <RedeemRewardButton
                                clienteId={cliente._id}
                                recompensaId={cliente.recompensaId}
                            />
                        </div>

                    </div>
                ))}
            </div>

            {/* Modal para edición de cliente */}
            {showModal && editingCliente && (
                <EditClienteModal
                    cliente={editingCliente}
                    onClose={handleCloseModal}
                    onSave={handleSaveCliente}
                />
            )}
        </div>
    );
};

export default ClientesTable;
