import React, { useState, useEffect } from "react";
import { get, post } from "../../helpers/apiHelper";
import Swal from "sweetalert2";
import useAuthStore from "../../store/authStore";

const SendRewardButton = ({ clienteId }) => {
    const { user } = useAuthStore();
    const userId = user?.userId; // ID del usuario autenticado

    const [showModal, setShowModal] = useState(false);
    const [rewards, setRewards] = useState([]);
    const [selectedReward, setSelectedReward] = useState("");
    const [marcaId, setMarcaId] = useState(null);

    useEffect(() => {
        if (userId) {
            fetchMarcaYRewards();
        }
    }, [userId]);

    // Buscar la marca del usuario y luego los premios de la marca
    const fetchMarcaYRewards = async () => {
        try {
            if (!userId) return;

            // Obtener la marca del usuario
            const marcas = await get(`marcas/user/${userId}`);
            if (marcas.length === 0) {
                Swal.fire("Error", "No tienes marcas registradas.", "error");
                return;
            }

            const marca = marcas[0]; // Suponemos que hay una sola marca
            setMarcaId(marca._id);

            // Obtener los premios de la marca
            const rewardsResponse = await get(`rewards/marca/${marca._id}`);
            setRewards(rewardsResponse);
        } catch (error) {
            console.error("Error obteniendo datos:", error);
            Swal.fire("Error", "No se pudieron cargar los premios.", "error");
        }
    };

    const handleSendReward = async () => {
        if (!selectedReward || selectedReward === "") {
            Swal.fire("Error", "Debes seleccionar un premio.", "error");
            return;
        }

        try {
            let existingReward = null;

            // Verificar si el cliente ya tiene el premio sin canjear
            try {
                existingReward = await get(`rewards/check-client/${clienteId}/${selectedReward}`);
            } catch (error) {
                if (error.response && error.response.status === 404) {
                    existingReward = null; // Si no existe, ignoramos y continuamos
                } else {
                    console.error("Error verificando premio existente:", error);
                    Swal.fire("Error", "No se pudo verificar el estado del premio.", "error");
                    return;
                }
            }

            if (existingReward && existingReward._id && !existingReward.usado) {
                // Si el cliente ya tiene un premio sin canjear, lo anulamos
                await post(`rewards/invalidate/${existingReward._id}`);
            }

            // Enviar un nuevo premio
            await post("rewards/send", { clienteId, recompensaId: selectedReward });

            Swal.fire("Éxito", "El premio fue enviado correctamente.", "success");
            setShowModal(false);
        } catch (error) {
            console.error("Error enviando el premio:", error);
            Swal.fire("Error", "No se pudo enviar el premio.", "error");
        }
    };

    return (
        <>
            <button
                onClick={() => setShowModal(true)}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
            >
                Enviar Premio
            </button>

            {showModal && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
                    <div className="bg-white rounded-lg shadow-lg w-full max-w-md p-6">
                        <h2 className="text-2xl font-bold mb-4 text-center">Enviar Premio</h2>

                        <div className="mb-4">
                            <label className="block text-gray-700 font-semibold mb-2">Premio</label>
                            <select
                                value={selectedReward}
                                onChange={(e) => setSelectedReward(e.target.value)}
                                className="w-full border rounded-lg px-3 py-2 focus:outline-none focus:ring focus:ring-blue-300"
                            >
                                <option value="">Seleccionar Premio</option>
                                {rewards.length > 0 ? (
                                    rewards.map((reward) => (
                                        <option key={reward._id} value={reward._id}>
                                            {reward.nombre} - {reward.tipo === "DESCUENTO" ? "Descuento" : "Premio"}
                                        </option>
                                    ))
                                ) : (
                                    <option value="" disabled>No hay premios disponibles</option>
                                )}
                            </select>
                        </div>

                        <div className="flex justify-end">
                            <button
                                onClick={() => setShowModal(false)}
                                className="bg-gray-500 text-white px-4 py-2 rounded-lg mr-2 hover:bg-gray-600 transition"
                            >
                                Cancelar
                            </button>
                            <button
                                onClick={handleSendReward}
                                className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition"
                            >
                                Enviar
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default SendRewardButton;
